import $ from 'jquery';
import Utils from '../utils/Util';

const agendaService = {

    consultarAgendamento(callback) {
      $.ajax({
        url: "/EsocialAPI/api/agendamento/principal/",
        dataType: 'json',
        error: Utils.handleAjaxError,
        success: function(resposta) {
          callback(resposta);
        },
        beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
      });
    }, 

    cadastrarAgendamento(obj, successCallback, errorCallback) {
      $.ajax({
          url: "/EsocialAPI/api/agendamento/novo/",
          type: 'post',
          data: JSON.stringify(obj),
          dataType: 'json',
          contentType: 'application/json',
          error: function (erro) {
              console.log(erro);
              if (errorCallback) errorCallback(erro);
          },
          success: function (resposta) {
              if (successCallback) successCallback(resposta);
          },
          beforeSend: function (xhr, settings) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token'));
          }
      });
    },

    obterCompromissosAgenda(callback) {
      $.ajax({
          url: "/EsocialAPI/admin/agenda",
          dataType: 'json',
          success: function (resposta) {
              if (callback) callback(resposta);
          },
          beforeSend: function (xhr, settings) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token'));
          }
      });
    },

    enviarNotificacao(obj, callback) {
      $.ajax({
          url: "/EsocialAPI/admin/agenda/enviar-notificacao",
          type: 'post',
          data: JSON.stringify(obj),
          dataType: 'json',
          contentType: "application/json; charset=utf-8",
          error: Utils.handleAjaxError,
          success: function (resposta) {
              if (callback) callback(resposta);
          },
          beforeSend: function (xhr, settings) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token'));
          }
      });
    },
}

export default agendaService;