import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import usuarioService from '../services/usuarioService';
import {Messages} from 'primereact/messages';
import{ MenuBar } from '../componentes/MenuBar';
import{ Exclusao } from './ExclusaoRecuperacao/Exclusao';
import{ Recuperacao } from './ExclusaoRecuperacao/Recuperacao';
import {Panel} from 'primereact/panel';
import {PermissionCheck} from '../componentes/PermissionCheck';
import { TabView, TabPanel } from 'primereact/tabview';

export class ExclusaoRecuperacao extends Component{
  constructor(){
    super();
    this.state = {
      usuario:{}
    };
  }
  
  componentDidMount(){
    usuarioService.consultaUsuario((usuario) => {
      this.setState({ usuario });
    });
  }
  
  render() {
    return(
      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content">
          <Panel header="Exclusão/Recuperação de Eventos">
            <div className="content align-center">
                <Messages ref={(el) => this.messages = el}></Messages>
                <div style={{marginBottom:'13px'}}>
                  <h3>Exclusão/Recuperação de Eventos</h3>
                </div>
                <div className="pure-md-u-1-5"></div>
                <div className="pure-md-u-3-5">
                  <TabView className="tabview-custom">
                    <TabPanel header="Exclusão" leftIcon="pi pi-trash">
                      <Exclusao/>
                    </TabPanel>
                    <TabPanel header="Recuperação" leftIcon="pi pi-replay">
                      <Recuperacao/>
                    </TabPanel>
                  </TabView>
                </div>
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}
