import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';

export default class FiltroTarefasAutomatizadas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            grupoEvento: props.grupoEvento,
            evento: props.evento,
            dropdownEventos: [],
            dropdownGrupoEventos: [],
            dropdownTipoServidor: [],
            idEventoFila: props.idEventoFila,
            idLote: props.idLote,
            status: props.status,
            tarefa: props.tarefa,
            acao: props.acao,
            mes: props.mes,
            ano: props.ano,
            indApuracao: props.indApuracao,
            objDetalhe: props.objDetalhe,
            tipoServidor: props.tipoServidor,
            isCollapsed:true,
            filtrosPersonalizados: [],
            showFiltros: false,
            showFiltrosFolha: false,
            showFiltrosEventos: false,
            showFiltroDetalhe: false,
            apiExterna: '',
            dropdownIndApuracao: [
                {label: "Todos", value:''},
                {label: 'Mensal', value:1},
                {label: 'Anual', value:2}],
            dropdownMes: [
                { label: "Todos", value: '' },
                { label: "Janeiro", value: 1 },
                { label: "Fevereiro", value: 2 },
                { label: "Março", value: 3 },
                { label: "Abril", value: 4 },
                { label: "Maio", value: 5 },
                { label: "Junho", value: 6 },
                { label: "Julho", value: 7 },
                { label: "Agosto", value: 8 },
                { label: "Setembro", value: 9 },
                { label: "Outubro", value: 10 },
                { label: "Novembro", value: 11 },
                { label: "Dezembro", value: 12 },
            ]
        };
    }

    componentDidMount() {

        this.carregarDropdownGrupoEvento();
        this.carregarDropdownEvento(null);
        this.consultaFiltroPersonalizado();
        this.carregarDropdownTipoServidor();

        $.ajax({
            url: "/EsocialAPI/config/",
            dataType: 'json',
            success: function (resposta) {
              console.log(resposta);
              this.setState({ apiExterna: resposta.configuracaoMensageria.apiExterna });
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
          });
    }

    carregarDropdownGrupoEvento() {
        const url = "/EsocialAPI/grupo/combo/";
        $.ajax({
            url: url,
            dataType: 'json',
            error: function (e) {
                console.log(e);
            },
            success: function (resposta) {
                const filteredData = resposta.filter(item => ['TABELA', 'NÃO_PERIÓDICO', 'PERIÓDICO'].includes(item.label));
                this.setState({ dropdownGrupoEventos: filteredData });
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    carregarDropdownEvento(grupoEvento) {
        const url = grupoEvento !== null ?
                    "/EsocialAPI/evento/lista/tipo/" + grupoEvento
                    :
                    "/EsocialAPI/evento/lista/atual";
        $.ajax({
            url: url,
            dataType: 'json',
            error: function (e) {
                console.log(e);
            },
            success: function (resposta) {
                const opcoes = resposta.map((evento) => {
                    return { label: evento.codigo + ' - ' + evento.nome, value: evento.idEventoEsocial }
                })
                this.setState({ dropdownEventos: opcoes });
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    carregarDropdownTipoServidor(){
        $.ajax({
            type: "GET",
            url: "/EsocialAPI/tiposervidor/lista",
            contentType: "application/json; charset=utf-8",
            error: function (e) {
                console.log(e);
            },
            success: function (resposta) {
                const opcoes = resposta.map((reg) => {
                    return { descricao: reg.descricao, value: reg.idTipoServidor }
                })
                this.setState({dropdownTipoServidor: opcoes})
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    consultaFiltroPersonalizado(){
        $.ajax({
            type: "GET",
            url: "/EsocialAPI/filtropersonalizado/lista",
            contentType: "application/json; charset=utf-8",
            success: function (resposta) {
                this.setState({filtrosPersonalizados: resposta})
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    renderFiltrosPersonalizados(){
        const style = {
            label: {
                fontSize: 16,
                fontWeight: 'bold',
                display: 'block',
            },
            dropDown: {
                width: '80%'
            },
            inputText: {
                width: '80%'
            },
            inputContainer: {
                paddingTop: '10px',
                paddingBottom: '10px'
            }
        }

        return this.state.filtrosPersonalizados.map(obj =>{
            if(obj.tipoFiltro.tipoFiltro == 'TipoServidor'){
                return(
                    <div key={obj.idFiltroPersonalizado} className="pure-u-1-3">
                        <label style={style.label}>Tipo Servidor: </label>
                        <Dropdown
                            value={this.props.tipoServidor}
                            optionLabel="descricao"
                            options={this.state.dropdownTipoServidor}
                            onChange={(e) => { this.handleTipoServidorChange(e.value) }}
                            placeholder="Escolha o Tipo Servidor"
                            style={style.dropDown}
                        />
                    </div>
                )
            }
        })
    }

    handleTipoServidorChange(tiposervidor){
        let tipoServidorDescricao = this.state.dropdownTipoServidor.find(item => item.value === tiposervidor)?.descricao;
        this.props.handleTipoServidorChange(tiposervidor)
        this.props.handleTipoServidorDescricaoChange(tipoServidorDescricao)
    }

    handleGrupoEventoChange(grupoEvento) {
        let grupoDescricao = this.state.dropdownGrupoEventos.find(item => item.value === grupoEvento)?.label;
        this.setState({ grupoEvento });
        this.carregarDropdownEvento(grupoEvento);
        this.setState({ showFiltrosFolha: grupoDescricao === 'PERIÓDICO' })
        this.props.handleGrupoEventoChange(grupoEvento);
        this.props.handleGrupoEventoDescricaoChange(grupoDescricao);
    }

    handleEventoChange(evento){
        let eventoDescricao = this.state.dropdownEventos.find(item => item.value === evento)?.label;
        this.props.handleEventoChange(evento)
        this.props.handleEventoDescricaoChange(eventoDescricao)
    }

    handleTarefaChange(tarefa) {
        this.setState({ tarefa });
        //Regras para exibir filtro de cada tarefa
        switch(tarefa) {
            case 'valida': {
                this.setState({ showFiltros: true })
                this.setState({ showFiltrosEventos: true})
                this.setState({ showFiltroDetalhe: true})
                break;
            }
            case 'envio': {
                this.setState({ showFiltros: true })
                this.setState({ showFiltrosEventos: true})
                this.setState({ showFiltroDetalhe: true})
                break;
            }
            case 'cargacpf': {
                this.setState({ showFiltros: true })
                this.setState({ showFiltrosEventos: false})
                this.setState({ showFiltroDetalhe: false})
                break;
            }
            case 'importar-eventos': {
                this.setState({ showFiltros: true })
                this.setState({ showFiltrosEventos: false})
                this.setState({ showFiltroDetalhe: true})
                break;
            }
            default: {
                this.setState({ showFiltros: false })
                this.setState({ showFiltrosEventos: false})
                this.setState({ showFiltroDetalhe: false})
                break;
            }
        }
        this.props.handleTarefaChange(tarefa);
    }

    handleIndApuracaoChange(indicativo){
        let indicativoDescricao = this.state.dropdownIndApuracao.find(item => item.value === indicativo)?.label;
        this.props.handleIndApuracaoChange(indicativo)
        this.props.handleIndApuracaoDescricaoChange(indicativoDescricao)
    }

    handleMesChange(mes) {
        let mesDescricao = this.state.dropdownMes.find(item => item.value === mes)?.label;
        this.props.handleMesChange(mes)
        this.props.handleMesDescricaoChange(mesDescricao)
      }

    render() {
        const style = {
            label: {
                fontSize: 16,
                fontWeight: 'bold',
                display: 'block',
            },
            dropDown: {
                width: '80%'
            },
            inputText: {
                width: '80%'
            },
            inputContainer: {
                paddingTop: '10px',
                paddingBottom: '10px'
            },
        }

        const tarefas = [
            { label: 'Validar Eventos', value: 'valida' },
            { label: 'Enviar Eventos', value: 'envio' },
            { label: 'Consultar Lotes', value: 'consulta' },
            //{ label: 'Carga CPF', value: 'cargacpf' },
        ];
    
        if (this.state.apiExterna === true) {
            tarefas.push({ label: 'Importar Eventos', value: 'importar-eventos' });
        }

        const status = [{ label: 'Selecione', value: '' }, { label: 'Pendente', value: 'PENDENTE' }, { label: 'Rejeitado', value: 'REJEITADO' }, { label: 'Duplicado', value: 'DUPLICADO' } ];

        const acao = [{ label: 'Inclusão', value: 'INCLUSAO' }, { label: 'Alteração', value: 'ALTERACAO' }, { label: 'Exclusão', value: 'EXCLUSAO' }, { label: 'Todas', value: ' ' },];

        const anoAtual = new Date().getFullYear();

        const ano = [];
        for (let year = anoAtual; year >= 2020; year--) {
            ano.push(year);
        }

        return (
            <div>
                <div className="pure-u-1-3">
                    <label style={style.label}>Tarefas: </label>
                    <Dropdown
                        value={this.props.tarefa}
                        options={tarefas}
                        onChange={(e) => this.handleTarefaChange(e.value)}
                        placeholder="Escolha a tarefa"
                        style={style.dropDown}
                    />
                </div>
                {this.state.showFiltros && (
                    <div>
                        <div style={style.inputContainer} className="pure-g">
                            <div className="pure-u-1-3">
                                <label style={style.label}>Grupo Evento: </label>
                                <Dropdown
                                    value={this.state.grupoEvento}
                                    options={this.state.dropdownGrupoEventos}
                                    onChange={(e) => this.handleGrupoEventoChange(e.value)}
                                    placeholder="Escolha o grupo"
                                    style={style.dropDown}
                                />
                            </div>
                            <div className="pure-u-1-3">
                                <label style={style.label}>Evento: </label>
                                <Dropdown
                                    value={this.props.evento}
                                    options={this.state.dropdownEventos}
                                    onChange={(e) => this.handleEventoChange(e.value)}
                                    placeholder="Escolha o evento"
                                    style={style.dropDown}
                                />
                            </div>
                        </div>
                        <div style={style.inputContainer} className="pure-g">
                            {this.state.showFiltrosEventos && (
                                <>
                                    <div className="pure-u-1-3">
                                        <label style={style.label}>Status: </label>
                                        <Dropdown
                                            value={this.props.status}
                                            options={status}
                                            onChange={(e) => this.props.handleStatusChange(e.value)}
                                            placeholder="Escolha o status"
                                            style={style.dropDown}
                                        />
                                    </div>
                                    <div className="pure-u-1-3">
                                        <label style={style.label}>Ação: </label>
                                        <Dropdown
                                            value={this.props.acao}
                                            options={acao}
                                            onChange={(e) => this.props.handleAcaoChange(e.value)}
                                            placeholder="Escolha a ação"
                                            style={style.dropDown}
                                        />
                                    </div>
                                </>
                            )}
                            {this.state.showFiltroDetalhe && (
                                <div className="pure-u-1-3">
                                    <label style={style.label}>Detalhes: </label>
                                    <InputText
                                        id="objDetalhe"
                                        value={this.props.objDetalhe}
                                        onChange={(e) => this.props.handleObjDetalheChange(e.target.value)}
                                        style={style.inputText}
                                    />
                                </div>
                            )}
                        </div>
                        {this.state.showFiltrosEventos && (
                            <div style={style.inputContainer} className="pure-g">
                                <div className="pure-u-1-3">
                                    <label style={style.label}>ID Evento: </label>
                                    <InputNumber
                                        id="idEventoFila"
                                        value={this.props.idEventoFila}
                                        onChange={(e) => this.props.handleIdEventoFilaChange(e.value)}
                                        style={style.inputText}
                                    />
                                </div>
                                {this.renderFiltrosPersonalizados()}
                            </div>
                        )}
                        {this.state.showFiltrosFolha && (
                            <div style={style.inputContainer} className="pure-g">
                                <div className="pure-u-1-4">
                                    <label style={style.label}>Mês: </label>
                                    <Dropdown
                                        value={this.props.mes}
                                        options={this.state.dropdownMes}
                                        onChange={(e) => this.handleMesChange(e.value)}
                                        placeholder="Escolha o mês"
                                        style={style.dropDown}
                                    />
                                </div>
                                <div className="pure-u-1-4">
                                    <label style={style.label}>Ano: </label>
                                    <Dropdown
                                        value={this.props.ano}
                                        options={ano}
                                        onChange={(e) => this.props.handleAnoChange(e.value)}
                                        placeholder="Escolha o ano"
                                        style={style.dropDown}
                                    />
                                </div>
                                <div className="pure-u-1-4">
                                    <label style={style.label}>Indicação de Apuração: </label>
                                    <Dropdown
                                        value={this.props.indApuracao}
                                        options={this.state.dropdownIndApuracao}
                                        onChange={(e) => this.handleIndApuracaoChange(e.value)}
                                        placeholder="Escolha a indicação de apuração"
                                        style={style.dropDown}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
export { FiltroTarefasAutomatizadas };