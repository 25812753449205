import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';

export class TblAfastamento extends Component{
  constructor(){
    super();
    this.state={menu:[],usuario:{},dataPeriodo:null,codAfastamento:null };
  }
  render() {
    var footer = <div style={{textAlign:'left'}}>
        <Button type="button" icon="pi pi-file-excel" iconPos="left" label="CSV" onClick={this.export}></Button>&nbsp;
        <Button type="button" icon="pi pi-file-pdf" iconPos="left" label="PDF" onClick={this.export}></Button>
    </div>;
    return(
      <div className="pure-u-5-5">
        <DataTable footer={footer} value={this.props.eventos} rowClassName={this.rowClassName} emptyMessage="Nenhum evento de afastamento encontrado.">
            <Column field="vin" header="id" />
            <Column field="year" header="Código do afastamento" />
            <Column field="brand" header="Descricao do afastamento" />
            <Column field="color" header="Trabalhador" />
            <Column field="color" header="Início Afastamento" />
            <Column field="color" header="Fim Afastamento" />
            <Column field="color" header="Exceção" />
        </DataTable>
      </div>
    );
  }
}