import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FrmInfoComplementares } from '../FolhaPagamento/form/FrmInfoComplementares';
import { FechamentoDeFolha } from '../FolhaPagamento/Componentes/FechamentoDeFolha';
import { ImprimirTotalizador } from '../FolhaPagamento/Componentes/ImprimirTotalizador';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import $ from 'jquery';
import pt from '../../pt.json';
import { locale, addLocale} from 'primereact/api';
import { Toast } from 'primereact/toast';


export class BtnFolhaPagamento extends Component {
    constructor() {
        super();
        this.state = {
            home: { icon: 'pi pi-home' }
            , agendamentos: []
            , tipoEvento: "1"
            , wzrdInfoComplementar: false
            , wzrdFechamentoTotal: false
            , wzrdImprimirTotalizador: false
            , wzrdFechamentoParcial: false
            , visualizaPagamentoContingencia: 'none'
            , desabilitaPagamentoContingencia: false
            , visualizaInfoComplementar: 'none'
            , desabilitaInfoComplementar: false
            , visualizaReabertura: 'none'
            , desabilitaReabertura: true
            , visualizaFechamento: 'none'
            , desabilitaFechamento: true
            , visualizaImpressao: 'none'
            , desabilitaImpressao: true
        };
        this.confirmGeracao = this.confirmGeracao.bind(this);
        this.changeLocale = this.changeLocale.bind(this);
        this.confirmaGeracaoFechamento = this.confirmaGeracaoFechamento.bind(this);
        this.showToast = this.showToast.bind(this);
    }
    direcionar(id) {
        var url = window.location.origin + "/folha/" + id;
        console.log(url);
        window.location.replace(url);
    }
    componentDidMount() {
        let status = this.props.status;
        console.log(status);
        if (status === 'ABERTO') {
            this.setState({
                visualizaPagamentoContingencia: 'inline'
                , desabilitaPagamentoContingencia: false
                , visualizaInfoComplementar: 'inline'
                , desabilitaInfoComplementar: false
                , visualizaReabertura: 'none'
                , desabilitaReabertura: true
                , visualizaFechamento: 'none'
                , desabilitaFechamento: false
            });
        }
        if (status === 'REABERTO') {
            this.setState({
                visualizaPagamentoContingencia: 'none'
                , desabilitaPagamentoContingencia: false
                , visualizaInfoComplementar: 'inline'
                , desabilitaInfoComplementar: false
                , visualizaReabertura: 'none'
                , desabilitaReabertura: true
                , visualizaFechamento: 'inline'
                , desabilitaFechamento: false
            });
        }
        if (status === 'INCOMPLETO') {
            this.setState({
                visualizaPagamentoContingencia: 'inline'
                , desabilitaPagamentoContingencia: false
                , visualizaInfoComplementar: 'inline'
                , desabilitaInfoComplementar: false
                , visualizaReabertura: 'none'
                , desabilitaReabertura: true
                , visualizaFechamento: 'none'
                , desabilitaFechamento: true
            });
        }
        if (status === 'FECHADO') {
            this.setState({
                visualizaPagamentoContingencia: 'none'
                , desabilitaPagamentoContingencia: false
                , visualizaInfoComplementar: 'none'
                , desabilitaInfoComplementar: false
                , visualizaReabertura: 'inline'
                , desabilitaReabertura: false
                , visualizaFechamento: 'none'
                , desabilitaFechamento: true
                , visualizaImpressao: 'inline'
                , desabilitaImpressao: false
            });
        }
        if (status === 'PARCIAL') {
            this.setState({
                visualizaPagamentoContingencia: 'inline'
                , desabilitaPagamentoContingencia: false
                , visualizaInfoComplementar: 'inline'
                , desabilitaInfoComplementar: false
                , visualizaReabertura: 'none'
                , desabilitaReabertura: true
                , visualizaFechamento: 'inline'
                , desabilitaFechamento: false
            });
        }
        if (status === 'REJEITADO') {
            this.setState({
                visualizaPagamentoContingencia: 'none'
                , desabilitaPagamentoContingencia: false
                , visualizaInfoComplementar: 'none'
                , desabilitaInfoComplementar: false
                , visualizaReabertura: 'none'
                , desabilitaReabertura: true
                , visualizaFechamento: 'inline'
                , desabilitaFechamento: false
            });
        }
    }

    confirmGeracao() {
        confirmDialog({
            message: 'Deseja gerar evento de Reabertura de folha?',
            header: 'Geração s1298',
            icon: 'pi pi-exclamation-triangle',
            accept: this.confirmaGeracaoFechamento,
            reject: this.reject
        });
    }

    changeLocale() {
        addLocale('pt', pt.pt);
        locale('pt');
    }

    confirmaGeracaoFechamento(){
        this.gerarEventoReabertura(this.props.idFolhaPagamento);
    }
    gerarEventoReabertura(idFolha){
        $.ajax({
            url:"/EsocialAPI/folha/gerar/reabertura/"+idFolha,
            method:'GET',
            contentType: "application/json",
            dataType: 'json',
            success:function(resposta){
                this.showToast(resposta);
            }.bind(this),
            beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
          });
    }

    showToast(msgObj) {
        this.toast.show({...msgObj, life: 5000});
    }
    render() {
        this.changeLocale();

        const footer = (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => this.setState({ wzrdFechamentoTotal: false })} />
            </div>
        );
        const footerFechamentoParcial = (
            <div>
                <Button label="Sair" icon="pi pi-times" onClick={() => this.setState({ wzrdFechamentoParcial: false })} />
            </div>
        );
        const footerInfoComplementar = (
            <div>
                <Button label="Sair" icon="pi pi-times" onClick={() => this.setState({ wzrdInfoComplementar: false })} />
            </div>
        );
        return (
            <div>
                <Toast ref={(el) => this.toast = el} />

                <Dialog header="Info. Complementar" visible={this.state.wzrdInfoComplementar} style={{ width: '50vw' }} footer={footerInfoComplementar} onHide={() => this.setState({ wzrdInfoComplementar: false })}>
                    <FrmInfoComplementares anoApur={this.props.anoApur} mesApur={this.props.mesApur} />
                </Dialog>
                {/* <Button icon="pi pi-plus" className="p-button-help" tooltip="Informações Complementares aos Eventos Periódicos(1280)" tooltipOptions={{ position: 'top' }} disabled={this.state.desabilitaInfoComplementar} style={{ display: this.state.visualizaInfoComplementar, marginLeft: '2px' }} onClick={() => this.setState({ wzrdInfoComplementar: true })} /> */}
                {/* <Button icon="pi pi-lock" tooltip="Fechamento de folha(1299)" tooltipOptions={{ position: 'top' }} onClick={this.confirmGeracao}/> */}
                {/* <Button icon="pi pi-lock-open" tooltip="Reabertura de folha(1298)" tooltipOptions={{ position: 'top' }} onClick={this.confirmGeracao}/> */}

                
                

                <Button icon="pi pi-pencil" className="p-button-warning" tooltip="Detalhes Folha de pagamento" tooltipOptions={{ position: 'top' }} onClick={(e) => this.direcionar(this.props.idFolhaPagamento)} />
        
                <Dialog header="Solicitação de Totalização para Pagamento em Contingência(1295)" visible={this.state.wzrdFechamentoParcial} style={{ width: '50vw', textAlign: 'left' }} footer={footerFechamentoParcial} onHide={() => this.setState({ wzrdFechamentoParcial: false })}>
                    <FechamentoDeFolha responsavelTecnico={this.props.responsavelTecnico} />
                </Dialog>
                
                <Dialog header="Fechamento dos Eventos Periódicos" visible={this.state.wzrdFechamentoTotal} style={{ width: '50vw', textAlign: 'left' }} footer={footer} onHide={() => this.setState({ wzrdFechamentoTotal: false })}>
                    <FechamentoDeFolha responsavelTecnico={this.props.responsavelTecnico} />
                </Dialog>
                <Button icon="pi pi-lock" tooltip="Fechamento de folha(1299)" tooltipOptions={{ position: 'top' }} disabled={this.state.desabilitaFechamento} style={{ display: this.state.visualizaFechamento, marginLeft: '2px' }} onClick={() => this.setState({ wzrdFechamentoTotal: true })} className="p-button-success" />
                <Dialog header="Visualizar totalizador" visible={this.state.wzrdImprimirTotalizador} style={{ width: '50vw', textAlign: 'left' }} footer={footer} onHide={() => this.setState({ wzrdImprimirTotalizador: false })}>
                    <ImprimirTotalizador />
                </Dialog>
                {/* <Button icon="pi pi-print" className="p-button-secondary" tooltip="Imprimir Relatório de fechamento" tooltipOptions={{ position: 'top' }} disabled={this.state.desabilitaImpressao} style={{ display: this.state.visualizaImpressao, marginLeft: '2px' }} onClick={() => this.setState({ wzrdImprimirTotalizador: true })} /> */}
            </div>
        );
    }
}