import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faHandshake, faHandHoldingUsd, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Ajuda } from '../../sessoes/Processamento/preview/Ajuda';
import { Menubar } from 'primereact/menubar';
import XMLViewer from 'react-xml-viewer'
import { ProgressBar } from 'primereact/progressbar';
import { ListaErros } from '../Processamento/preview/listaErros';
import $ from 'jquery';
import EnvioComponent from '../Processamento/preview/EnvioComponent';


export class DialogPgto extends Component {
  constructor() {
    super();
    library.add(fab, faHandshake, faHandHoldingUsd, faMoneyCheckAlt);
    this.state = {
      idEventoFila: 0,
      xml:null,
      listaErros:null,
      bolAtivo: false,
      btnEnviar:false,
      activeIndex: 0,
      evtValido: true,
      resumo: 'block',
      divEventoEnviado: 'none',
      xmlEvento: 'none',
      divListaErro:'none',
      xmlEnvio: '',
      ajuda: 'none',
      loadBar: 'block',
      respostaEnvio:[],
      evtPgtos: {
        ideBenef:{                
          cpfTrab: '',
          nisTrab: ''
          }
      }
    };
    this.renderBeneficiario=this.renderBeneficiario.bind(this);
    this.loadInfoEvtRemun=this.loadInfoEvtRemun.bind(this);
    this.renderInfoPGTO=this.renderInfoPGTO.bind(this);
  }
  componentDidMount() {
    //console.log(this.props.eventoFila.id);
    //console.log(this.props.idEventoFila);
    
  }
  componentWillReceiveProps(props) {
    //console.log(props.eventos);
    this.setState({ idEventoFila: props.eventos });
  }
  validarEvento() {
    this.loadInfoEvtRemun();
    this.setState({ bolAtivo: true });
    $("#divValidado").html();
  }
  validarEvento() {
    this.loadInfoEvtRemun();
    this.setState({ bolAtivo: true });
    $("#divValidado").html();
  }

  enviarEvento() {
    //this.setState({activeIndex:1,tabEnviandoDisable: false});
    console.log(this.props.idEventoFila);
    $.ajax({
      type: "POST",
      data: { 'idEventoFila[]': this.props.eventoFila.id },
      url: "/EsocialAPI/api/processamento/enviar/",
      dataType: 'json',
      success: function (resposta) {
        console.log(resposta);
        console.log('Retorno da resposta do envio');
        this.setState({ selecionados: [] });
        this.setState({xmlEvento: 'none'});
        this.setState({resumo:'none'});
        this.setState({divListaErro: 'none'});
        this.setState({ divEventoEnviado: 'block' });
        this.setState({ isEventosEnviados: true });
        this.setState({ respostaEnvio: resposta })
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  formataCPF(numCPF) {
    try {
      const cpf = require('node-cpf');
      return cpf.mask(numCPF);
    } catch (erro) {
      console.log(erro);
    }
  }
  renderAjuda() {
    try {
      this.setState({ loadBar: 'none', resumo: 'none', xmlEvento: 'none', ajuda: 'block' });
    } catch (e) {
      console.log(e);
    }
    //xmlEnvio
  }
  renderXmlEditor() {
    try {
      this.setState({ loadBar: 'block' });
      $.ajax({
        url: "/EsocialAPI/folha/preview/XML/" + this.props.eventoFila.id,
        dataType: 'json',
        success: function (resposta) {
          this.setState({ xmlEnvio: resposta.xml, loadBar: 'none', resumo: 'none', xmlEvento: 'block',divListaErro:'none', ajuda: 'none' });
        }.bind(this),
        error: function (resposta) {
          this.setState({ wzdGeracaoEvento: true, tblLoading: 'none' });
        }.bind(this),
        beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
      });
    } catch (e) {
      console.log(e);
    }
    //xmlEnvio
  }
  loadInfoEvtRemun() {
    this.setState({ loadBar: 'block' });
    $.ajax({
      url: "/EsocialAPI/folha/preview/Json/" + this.props.eventoFila.id,
      dataType: 'json',
      success: function (resposta) {        
        if (resposta.json !== "null") {
          let objetoRemun = JSON.parse(resposta.json);
          this.setState({ evtPgtos: objetoRemun.evtPgtos, loadBar: 'none', resumo: 'block', xmlEvento: 'none',divListaErro:'none', ajuda: 'none' });
        } else {
          this.setState({ loadBar: 'none', xmlEvento: 'none', ajuda: 'none' });
        }
      }.bind(this),
      error: function (resposta) {
        console.log(resposta);
        this.setState({ wzdGeracaoEvento: true, tblLoading: 'none' });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  carregaListaErro(ideEventoFila) {   
    this.setState({loadBar: 'block'}); 
    $.ajax({
      url: "/EsocialAPI/api/processamento/valida/" + ideEventoFila,
      dataType: 'json',
      contentType: "application/json; charset=utf-8",
      success: function (resposta) {
        console.log(resposta);
        this.setState({ listaErros: resposta.erro ,xml: resposta.xml ,resumo: 'none', xmlEvento: 'none',divListaErro:'block',loadBar: 'none' });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  openDialog(){
    try{
      
      this.loadInfoEvtRemun(); 
      this.setState({ bolAtivo: true });
    }catch(error){
      console.log(error);
    }
  }
  renderValor(valor){
    if(valor===null){
      return "Nenhum valor informado.";
    }else{
      return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }
  }
  renderTpPgto(tpPgto){
    console.log(tpPgto);
    try{
      if(tpPgto==1){
        return "Pagamento de remuneração, conforme apurado em ideDmDev do S-1200";
      }
      if(tpPgto==2){
        return "Pagamento de verbas rescisórias conforme apurado em ideDmDev do S-2299";
      }
      if(tpPgto==3){
        return "Pagamento de verbas rescisórias conforme apurado em ideDmDev do S-2399";
      }
      if(tpPgto==4){
        return "Pagamento de remuneração conforme apurado em ideDmDev do S-1202";
      }
      if(tpPgto==5){
        return "Pagamento de benefícios previdenciários, conforme apurado em ideDmDev do S-1207";
      }
    return "Nenhum valor informado.";
  }catch(error){
    console.log(error);
  }
  }
  renderInfoPerRef(perRef){

  }
  renderInfoPGTO(infoPgto){
    console.log(infoPgto);
    try{
      return (
        <ul>
          <li style={{display:'table-row',verticalAlign:'middle'}}><label>Data de pagamento:</label><strong> {new Date(infoPgto.dtPgto).toLocaleDateString()}</strong></li>
          <li style={{display:'table-row',verticalAlign:'middle'}}><label>Evento de origem do pagamento:</label><strong> {this.renderTpPgto(infoPgto.tpPgto)}</strong></li>
          <li style={{display:'table-row',verticalAlign:'middle'}}><label>Competência declarada no campo perApur do evento remuneratório:</label><strong> {infoPgto.perRef}</strong></li>
          <li style={{display:'table-row',verticalAlign:'middle'}}><label>Identificador atribuído pela fonte pagadora de valores devidos ao trabalhador:</label><strong> {infoPgto.ideDmDev}</strong></li>
          <li style={{display:'table-row',verticalAlign:'middle'}}><label>Valor líquido recebido pelo trabalhador:</label><em> {this.renderValor(infoPgto.vrLiq)}</em></li>
        </ul>
      );
    }catch(error){
      console.log(error);
    }
  }
  renderInfoEvento(){
    try{
      return(
        <div className="pure-control-group" style={{marginTop: '12px', display: this.state.resumo,paddingLeft:'18px', border:'1px solid #ccc', borderRadius:'8px', padding:'8px 8px 8px 8px'}}>
          <h4>Informações de identificação do evento.</h4>
          <ul>
            <li style={{display:'table-row',verticalAlign:'middle'}}><label>Tipo de arquivo:</label><strong>{this.state.evtPgtos.ideEvento.indRetif}</strong></li>
            <li style={{display:'table-row',verticalAlign:'middle'}}><label>Recibo:</label><strong>{this.state.evtPgtos.ideEvento.nrRecibo}</strong></li>
            <li style={{display:'table-row',verticalAlign:'middle'}}><label>Período de apuração:</label><strong>{this.state.evtPgtos.ideEvento.perApur}</strong></li>
            <li style={{display:'table-row',verticalAlign:'middle'}}><label>Indicativo do tipo de guia:</label><strong>{this.state.evtPgtos.ideEvento.indGuia}</strong></li>
            <li style={{display:'table-row',verticalAlign:'middle'}}><label>Identificação do ambiente:</label><strong>{this.state.evtPgtos.ideEvento.tpAmb}</strong></li>
            <li style={{display:'table-row',verticalAlign:'middle'}}><label>Processo de emissão do evento:</label><strong>{this.state.evtPgtos.ideEvento.procEmi}</strong></li>
            <li style={{display:'table-row',verticalAlign:'middle'}}><label>Versão do processo de emissão do evento:</label><strong>{this.state.evtPgtos.ideEvento.verProc}</strong></li>
          </ul>
        </div>
      );
    }catch(error){
      console.log(error);
    }
  }
  renderideEmpregador(){
    try{
      return(
        <div className="pure-control-group" style={{marginTop: '12px', display: this.state.resumo,paddingLeft:'18px', border:'1px solid #ccc', borderRadius:'8px', padding:'8px 8px 8px 8px'}}>
          <h4>Informações de identificação do empregador.</h4>
          <ul>
            <li style={{display:'table-row',verticalAlign:'middle'}}><label>Código correspondente ao tipo de inscrição:</label><strong>{this.state.evtPgtos.ideEmpregador.tpInsc}</strong></li>
            <li style={{display:'table-row',verticalAlign:'middle'}}><label>Número de inscrição do contribuinte:</label><strong>{this.state.evtPgtos.ideEmpregador.nrInsc}</strong></li>
          </ul>
        </div>
      );
    }catch(error){
      console.log(error);
    }
  }  
  renderBeneficiario(){
    try{
      return(
        <div className="pure-control-group" style={{marginTop: '12px', display: this.state.resumo,paddingLeft:'18px', border:'1px solid #ccc', borderRadius:'8px', padding:'8px 8px 8px 8px'}} >
          <h4>Identificação do beneficiário do pagamento</h4>
          <label>Nome:</label><strong>{this.props.nome}</strong><br />
          <label>CPF:</label><strong>{this.formataCPF(this.state.evtPgtos.ideBenef.cpfBenef)}</strong><br />
          <div>
            <h5>Informações dos pagamentos efetuados</h5>
            {this.state.evtPgtos.ideBenef.infoPgto.map(this.renderInfoPGTO)}

          </div>
        </div>
      );
    }catch(error){
      console.log(error);
    }
  }
  render() {
    const footer = (
      <div>
        <Button label="Enviar" icon="pi pi-check" onClick={(e) => this.enviarEvento()} disabled={this.state.isEnvioDisabled} />
        <Button label="Cancelar" icon="pi pi-times" onClick={(e) => this.setState({ bolAtivo: false })} />
      </div>
    );
    const baseurl = window.location.protocol + "//" + window.location.host;
    const items = [
      {
        label: 'Resumo',
        icon: 'pi pi-id-card',
        command: (e) => {
          this.loadInfoEvtRemun();
        }
      },
      {
        label: 'Lista Erro',
        icon: 'pi pi-id-card',
        command: (e)=> {
          this.carregaListaErro(this.props.eventoFila.id);
        }
      },
      {
        label: 'Informações adicionais',
        icon: 'pi pi-fw pi-calendar',
        items: [
          {
            label: 'Visualizar',
            icon: 'pi pi-id-card',
            command: (e) => {
              window.location.href = baseurl + "/EventoFila/" + this.props.eventoFila.id;
              
            }
          },
          {
            label: 'XML',
            icon: 'pi pi-fw pi-file-o',
            command: (e) => {
              this.renderXmlEditor();
            }
          }
          // ,
          // {
          //   label: 'Ajuda',
          //   icon: 'pi pi-fw pi-question-circle',
          //   command: (e) => {
          //     this.renderAjuda();
          //   }
          // }
        ]
      }
    ];

    return (
      <div style={{ display: 'inline' }}>
        <Dialog header="Pagamentos de Rendimentos do Trabalho" footer={footer} visible={this.state.bolAtivo} style={{ width: '80vw' }} modal={true} onHide={() => this.setState({ bolAtivo: false })}>
          <div style={{ padding: '12px 12px 12px 12px', border: '1px solid #ccc' }}>
            <Menubar model={items} style={{ marginTop: '12px' }} />
            <ProgressBar mode="indeterminate" id="loadBarDiv" style={{ display: this.state.loadBar }} />
            <div style={{ marginTop: '12px', display: this.state.resumo }}>
              <div>{this.renderInfoEvento()}</div>
              <div>{this.renderideEmpregador()}</div>
              <div>{this.renderBeneficiario()}</div>
            </div>
            <div style={{display: this.state.divListaErro }}>
              <ListaErros listaErros={this.state.listaErros}></ListaErros>
            </div>
            <div className="pure-control-group" style={{ marginTop: '12px', display: this.state.xmlEvento }}>
              <XMLViewer xml={this.state.xmlEnvio} />
            </div>
            {/* <div className="pure-control-group" style={{ marginTop: '12px', display: this.state.ajuda }}>
              <Ajuda idEventoEsocial={this.props.eventoFila.idEvento} />
            </div> */}
            <div className="pure-control-group" style={{ marginTop: '12px', display: this.state.divEventoEnviado }}>
              <EnvioComponent isEventosEnviados={this.state.isEventosEnviados} respostaEnvio={this.state.respostaEnvio[0]}></EnvioComponent>
            </div>
          </div>
        </Dialog>
        <Button onClick={(e) => this.openDialog()} tooltip="S1210">
          <FontAwesomeIcon icon={faHandHoldingUsd} style={{ margin: '3px' }} />
        </Button>&nbsp;
      </div>
    );
  }
}
