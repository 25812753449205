import React, {Component} from 'react';
import $ from 'jquery';

export class PermissionCheck extends Component{  
    constructor(){
    super();
    this.state={
      lista : []
      ,version:''
      ,items:[]
      ,eventos:[]
      ,usuario:{}
      ,numMensagens:''
      ,bolPermissao:false
      ,urlAtual:''
      ,permissao:3
    }
    this.temPermissao=this.temPermissao.bind(this);
    this.retornaUrlSessao=this.retornaUrlSessao.bind(this);
    this.validaPermissao=this.validaPermissao.bind(this);
  }

  getGitInfo(){
    fetch('/EsocialAPI/api/git/version', {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
      },
    })
    .then(response => response.text())
    .then(data => {
      this.setState({version: data});
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  componentDidMount(){
    this.getGitInfo();
    let uri = this.limpaUrl(window.location.href);
    this.setState({urlAtual:uri});
    this.validaPermissao();
  }

  limpaUrl(url){
      try{
        url=url.replace(window.location.host , '');
        url = url.replace(window.location.protocol , '');
        url = url.replace('//','');
      }catch(Exception){

      }
    return url;
  }

  retornaUrlSessao(sessao){
    return sessao.uri;
  }

  validaPermissao(){
    let baseurl = encodeURI(this.props.url);
    if(this.props.url==undefined){
      baseurl = this.limpaUrl(window.location.href);
      console.log(baseurl);
    }
    console.log(baseurl);
    try{
        $.ajax({
          url:"/EsocialAPI/api/permissao/",
          type: "POST",
          contentType: "application/json; charset=utf-8",
          dataType: 'json',
          data: baseurl,
          error: function(erro){
            console.log(erro);
          },
          success:function(resposta){
            var url = window.location.protocol + "//" + window.location.host;
            console.log(resposta);
            if(resposta!==3){
              window.location.href= url+"/erro/"+resposta;
            }
            this.setState({erro:resposta});
          }.bind(this),
          beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
        });
    }catch(erro){
      console.log(erro);
    }
  }
  temPermissao(){
    try{
      let sessoes = this.props.usuario.grupo.sessoes;
      let urls = sessoes.map(this.retornaUrlSessao);
      console.log(this.props.usuario.grupo.sessoes);
      return(urls.includes(this.state.urlAtual));
    }catch(error){
      //console.log(error);
      return false;
    }
  }
  renderMensagem(){
    if(this.state.permissao===3){
      return <>
        <div className="p-grid">
          <div className="p-col">Copyright © Todos os direitos reservados</div>
          <div className="p-col p-text-right p-text-bold" style={{fontSize: 'small'}}>Versão:  {this.state.version}</div>
        </div>
      </>
    }
  }
  render(){
    return(
      <div>
          {this.renderMensagem()}
      </div>
    );
  }
}