import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import apiAjax from '../../services/apiAjax';
import { Messages } from 'primereact/messages';
import { Tree } from 'primereact/tree';
export class SessoesGrupo extends Component{
  constructor(){
    super();
    this.state={lista : [],visible:false,btnSalvar:true,displayBasic:false
      ,sessoes:[
            {
                "key": "1",
                "label": "Administração",
                "data": "Administração do sistema",
                "icon": "pi pi-fw pi-inbox",
                "children": [{
                    "key": "1.1",
                    "label": "Editor de consultas",
                    "data": "Editar sql",
                    "icon": "pi pi-fw pi-cog"
                },
                {
                    "key": "1.2",
                    "label": "Importação",
                    "data": "Importação/Geração de eventos",
                    "icon": "pi pi-fw pi-home"
                },
                {
                    "key": "1.3",
                    "label": "Ajuda",
                    "data": "Ajuda no uso do sistema.",
                    "icon": "pi pi-fw pi-home",
                    "children":[{
                      "key": "1.3.1",
                      "label": "Ajuda Evento",
                      "data": "Ajuda Referente aos TAGS do sistema",
                      "icon": "pi pi-fw pi-home"
                    },{
                      "key": "1.3.2",
                      "label": "Ajuda sistema",
                      "data": "Ajuda no uso do sistema",
                      "icon": "pi pi-fw pi-home"
                    },
                  ]

                }]
            },
            {
                "key": "2",
                "label": "Events",
                "data": "Events Folder",
                "icon": "pi pi-fw pi-calendar",
                "children": [
                    { "key": "2.1", "label": "Meeting", "icon": "pi pi-fw pi-calendar-plus", "data": "Meeting" },
                    { "key": "2.2", "label": "Product Launch", "icon": "pi pi-fw pi-calendar-plus", "data": "Product Launch" },
                    { "key": "2.3", "label": "Report Review", "icon": "pi pi-fw pi-calendar-plus", "data": "Report Review" }]
            },
            {
                "key": "3",
                "label": "Movies",
                "data": "Movies Folder",
                "icon": "pi pi-fw pi-star",
                "children": [{
                    "key": "3.1",
                    "icon": "pi pi-fw pi-star",
                    "label": "Al Pacino",
                    "data": "Pacino Movies",
                    "children": [{ "key": "3.1.1", "label": "Scarface", "icon": "pi pi-fw pi-video", "data": "Scarface Movie" }, { "key": "3.1.2", "label": "Serpico", "icon": "pi pi-fw pi-video", "data": "Serpico Movie" }]
                },
                {
                    "key": "4",
                    "label": "Robert De Niro",
                    "icon": "pi pi-fw pi-star",
                    "data": "De Niro Movies",
                    "children": [{ "key": "4.1", "label": "Goodfellas", "icon": "pi pi-fw pi-video", "data": "Goodfellas Movie" }, { "key": "4.2", "label": "Untouchables", "icon": "pi pi-fw pi-video", "data": "Untouchables Movie" }]
                }]
            }
        ]
      ,sessoesSelecionadas:{"0":{"1":{"checked":true,"partialChecked":false}},"17":{"checked":true,"partialChecked":false},"17.18":{"checked":true,"partialChecked":false},"17.28":{"checked":true,"partialChecked":false}}};
    this.onHide=this.onHide.bind(this);
    this.mostraSessoes=this.mostraSessoes.bind(this);
    this.onSessaoChange=this.onSessaoChange.bind(this);
    this.isSelected=this.isSelected.bind(this);
    this.showMessage=this.showMessage.bind(this);
  }
  componentWillMount(){
    this.listaSessoes();
    //this.consultaSelecionados();
  }
  listaSessoes(){
    $.ajax({
      url:"/EsocialAPI/api/grupo/sessoes/",
      dataType: 'json',
      success:function(resposta){
        console.log(resposta);
        this.setState({sessoes:resposta});
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  onSessaoChange(e) {
    let sessoesSlt = this.props.sessoesSlt;
    if(sessoesSlt===null){
      sessoesSlt=[];
    }
    if (e.checked){
      sessoesSlt.push(e.value);
    }else{
      sessoesSlt.splice(sessoesSlt.indexOf(e.value), 1);
    }
    this.setState({ sessoesSelecionadas: sessoesSlt, btnSalvar:false });
    console.log(this.state.sessoesSelecionadas);
  }
  onHide(name) {
    this.setState({
        [`${name}`]: false
    });
}
showMessage(msg){
  this.messages.show(msg);
}
async handleSalvarSessao(sessoesSelecionadas){
  const data = {idGrupo:this.props.idGrupo,dados:sessoesSelecionadas}
  //const data = this.state.sessoesSelecionadas;
  const postCall =  apiAjax.postRequestNew;
  try{
    const response = await postCall('/EsocialAPI/api/grupo/salvar/sessao', data);
   this.showMessage(response);
    console.log(response);
  }catch(e){
    console.log(e);
  }
  
}
isSelected(valor){
  var selecionados = this.props.sessoesSlt;
  if(selecionados!=null){
    var existe =selecionados.includes(valor);
    return existe;
  }
  return false;
}
mostraSessoes(){
  var sessoes = this.props.sessoes;
  var listItems = null;
  if((sessoes!==null)){
  listItems = sessoes.map((sessao,index) =>
      <div key={index} className="p-field-checkbox" style={{display:'inline-Block', width:'25%'}}>        
        <Checkbox inputId={'cb'+sessao.idSessao} value={sessao.idSessao} onChange={this.onSessaoChange} checked={this.isSelected(sessao.idSessao)}></Checkbox>
        <label htmlFor={'cb'+sessao.idSessao}  className="p-checkbox-label">{sessao.nome}</label>
      </div>
  );
  }

return (<div>{listItems}</div>);
}
  listaSelecionados(e){
    this.setState({ sessoesSelecionadas: e.value });
  }
  mostraSelecionados(e){
    this.setState({btnSalvar:false});
    console.log(this.state.sessoesSelecionadas);
  }
  render() {
    const footer = (
        <div>
            <Button label="Salvar" icon="pi pi-check" onClick={()=>this.handleSalvarSessao(this.state.sessoesSelecionadas)} disabled={this.state.btnSalvar} />
            <Button label="Sair" icon="pi pi-times" onClick={() => this.onHide('displayBasic')} />
        </div>
    );
    
    const myIcon = (
        <button className="p-dialog-titlebar-icon p-link">
            <span className="pi pi-search"></span>
        </button>
    )

    
    return(
      <div>
            <Button icon="pi pi-list" onClick={() => this.setState({displayBasic:true})} />

            <Dialog header="Sessões" visible={this.state.displayBasic} icon={myIcon} style={{ width: '50vw' }} footer={footer} onHide={() => this.onHide('displayBasic')}>
                 <Messages ref={(el) => this.messages = el}></Messages>
                 <Tree value={this.state.sessoes} selectionMode="checkbox" selectionKeys={this.state.sessoesSelecionadas} onSelectionChange={e => this.listaSelecionados(e)} onSelect={(e) => this.mostraSelecionados()} onUnselect={(e) => this.mostraSelecionados()} />
            </Dialog>
      </div>
    );
  }
}
