
import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { ProgressSpinner } from 'primereact/progressspinner';
import { InputMask } from 'primereact/inputmask';
import $ from 'jquery';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { TabView,TabPanel } from 'primereact/tabview';
import {EvtInfoEmpregador} from '../Componentes/EvtInfoEmpregador'
import {EvtFGTS} from '../Componentes/EvtFGTS'
import {EvtIrrf} from '../Componentes/EvtIrrf'

export class ImprimirTotalizador extends Component{
  constructor(){
    super();
    this.state={
        home :{icon: 'pi pi-home'}
        ,agendamentos:[]
        ,tipoEvento:"1"
        ,usuario:{}
        ,btnEnviar:'none'
        ,btnSalvar:'block'
        ,blocoForm:'block'
        ,blocoEnviarEvento:'none'
        ,ldngEnviando:'none'
        ,desabilitaSalvar:false
        ,activeIndex:0
        ,items: [
            {label: '5011', icon: 'pi pi-fw pi-home'},
            {label: '5012', icon: 'pi pi-fw pi-calendar'},
            {label: '5013', icon: 'pi pi-fw pi-pencil'}
        ]
    };
  }
  render() {
    return(
        <div>
          <em>As informações aqui contidas, correspondem aos totalizadores entregues pelo Esocial ao fechar a folha de pagamento.</em>
            <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex:e.index})}>
                <TabPanel header="5011">
                    <EvtInfoEmpregador />
                </TabPanel>
                <TabPanel header="5012">
                  <EvtIrrf />
                </TabPanel>
                <TabPanel header="5013">
                  <EvtFGTS />
                </TabPanel>
            </TabView>
        

        </div>
    );
  }
}
