import { Badge } from 'primereact/badge';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { TabPanel, TabView } from 'primereact/tabview';
import { Tooltip } from 'primereact/tooltip';
import React, { useState, useEffect, useRef } from 'react';
import BtnExportCsv from '../../../../componentes/BtnExportCsv';
import { DataTable } from 'primereact/datatable';
import Utils from '../../../../utils/Util';

const TblEvtBasesFGTS = (props) => {

    const [basePerApur, setBasePerApur] = useState([])
    const [first2, setFirst2] = useState(0);

    useEffect(() => {
        setBasePerApur(props.relatorios.basePerApurDTOs);
    }, [props])


    const [headerCsvBasePerApur, setHeaderCsvBasePerApur] = useState([
        { label: 'Matrícula', key: 'matricula' },
        { label: 'CPF', key: 'cpf' },
        { label: 'Nome', key: 'nome' },
        { label: 'indIncid', key: 'indIncid' },
        { label: 'tpValor', key: 'tpValorDesc' },
        { label: 'remFGTS', key: 'remFGTS' },
        { label: 'dpsFGTS', key: 'dpsFGTS' }
    ]);


    function geraNomeRelatorio(nome) {
        const timeElapsed = Date.now();
        const today = new Date(timeElapsed);
        const nomeRelatorio = nome + '_' + today.toISOString() + '.csv';
        return nomeRelatorio;
    }

    function onPage(event, values, setter) {
        console.log(event)
        setFirst2(event.first)
        const endIndex = event.first + event.rows;
        const newArray = [];
        for (let i = 0; i < values.length; i++) {
            if (i >= event.first && i < endIndex) {
                newArray.push(values[i])
            }
        }
        setter(newArray);
    }

    function onChangePaginator(event, options, values, setter) {
        console.log(event);
        console.log(options.props);
        options.currentPage = event.value
        const startIndex = event.value * options.rows;
        const endIndex = startIndex + options.rows
        setFirst2(startIndex);

        const newArray = [];
        for (let i = 0; i < values.length; i++) {
            if (i >= startIndex && i < endIndex) {
                newArray.push(values[i])
            }
        }
        setter(newArray);
    }
    const templatePaginatorBasePerApur = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink',
        'CurrentPageReport': (options) => {
            const totalReg = options.totalRecords === null ? 0 : options.totalRecords
            const dropdownOptions = [];
            const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
            for (let i = 0; i <= totalPages; i++) {
                const item = {
                    label: i + 1,
                    value: i
                };

                dropdownOptions.push(item);
            }

            return (
                <>
                    <div className="p-grid">
                        <div className="p-col">
                            <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square ' />
                            <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => onChangePaginator(e, options, props.relatorios.basePerApurDTOs, setBasePerApur)} />
                        </div>
                    </div>
                </>
            )
        }
    };

    return (

        <div>
            <TabView>
                <TabPanel header="basePerApur">
                    <h3>Bases de cálculo e valores do FGTS</h3>
                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                        {'Informações sobre bases de cálculo e valores do FGTS referentes à remuneração do período de apuração e de períodos anteriores, exceto se {tpAcConv} = [E, H, I]. Evento de origem: S-1200, S-2299 ou S-2399.'}
                    </p>
                    <div className="content-section implementation">
                        {props.relatorios.basePerApurDTOs.length > 0 ? <BtnExportCsv data={props.relatorios.basePerApurDTOs} header={headerCsvBasePerApur} fileName={geraNomeRelatorio('5003_basePerApur_' + props.mesPerApur + '_' + props.anoPerApur + '_' + props.indApuracao)} /> : ''}
                        <Tooltip target=".indIncid" />
                        <Tooltip target=".tpValor" />
                        <Tooltip target=".remFGTS" />
                        <Tooltip target=".dpsFGTS" />
                        <DataTable
                            value={basePerApur}
                            emptyMessage="Nenhum registro encontrado."
                            lazy={true}
                            loading={false}
                            totalRecords={(props.relatorios.basePerApurDTOs.length)}
                            className="p-datatable-sm"
                            paginator={true}
                            paginatorTemplate={templatePaginatorBasePerApur}
                            first={first2}
                            rows={100}
                            onPage={(e) => onPage(e, props.relatorios.basePerApurDTO, setBasePerApur)}
                            footer={
                                <>
                                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div style={{ textAlign: 'right', marginLeft: 'auto', paddingRight: '10px' }}>
                                      Total remFGTS
                                      <br />
                                      {Utils.formatValue(Utils.calculateSum(basePerApur, 'remFGTS'))}
                                    </div>
                                    <div style={{ textAlign: 'right' }}>
                                      Total dpsFGTS
                                      <br />
                                      {Utils.formatValue(Utils.calculateSum(basePerApur, 'dpsFGTS'))}
                                    </div>
                                  </div>
                                </>
                              }
                        >
                            <Column field="matricula" header="Matrícula" style={{ width: '3em' }} />
                            <Column field="cpf" header="CPF" style={{ width: '3em' }} />
                            <Column field='nome' header="Nome" style={{ width: '3em' }} />
                            <Column field='indIncid' style={{ width: '3em' }} header={<>indIncid <i className="indIncid pi pi-info-circle" data-pr-tooltip="Indicativo de incidência de FGTS." /> </>} />
                            <Column field='tpValorDesc' style={{ width: '3em' }} header={<>tpValor <i className="tpValor pi pi-info-circle" data-pr-tooltip="Tipo de valor que influi na apuração do FGTS." /> </>} />
                            <Column field='remFGTS' style={{ width: '3em' }} body={(rowData) => Utils.formatValue(rowData.remFGTS)} header={<>remFGTS <i className="remFGTS pi pi-info-circle" data-pr-tooltip="Remuneração (valor da base de cálculo) do FGTS, conforme definido nos campos tpValor e indIncid." /> </>} />
                            <Column field='dpsFGTS' style={{ width: '3em' }} body={(rowData) => Utils.formatValue(rowData.dpsFGTS)} header={<>dpsFGTS <i className="dpsFGTS pi pi-info-circle" data-pr-tooltip="Valor histórico do FGTS a ser depositado na conta vinculada do trabalhador. Validação: Deve ser maior que 0 (zero) e informado somente se indIncid = [1]." /> </>} />
                        </DataTable>
                    </div>
                </TabPanel>
            </TabView>

        </div>
    )
}

export default TblEvtBasesFGTS;