import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faHandshake, faHandHoldingUsd, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Ajuda} from '../../sessoes/Processamento/preview/Ajuda';
import { Menubar } from 'primereact/menubar';
import XMLViewer from 'react-xml-viewer'
import { ProgressBar } from 'primereact/progressbar';
import $ from 'jquery';

export class DialogRemun extends Component {
    constructor() {
        super();
        library.add(fab, faHandshake, faHandHoldingUsd, faMoneyCheckAlt);
        
        this.state = {
            idEventoFila: 0,
            bolAtivo: false,
            activeIndex: 0,
            evtValido: false,
            resumo:'none',
            xmlEvento:'none',
            xmlEnvio:'',
            ajuda:'none',
            loadBar:'block',
            evtRemun: {
                ideTrabalhador:{                
                cpfTrab: '03873528134',
                nisTrab: '10039317495'
                },

                dmDev: [
                    {
                        ideDmDev: 555,
                        codCategoria: '101',
                        descCategoria: '',
                        infoPerApur:{
                        ideEstabLot: [
                            {
                                tpInsc: 1,
                                nrInsc: '00399857000126',
                                codLotacao: 'CODEVASF',
                                remunPerApur: [
                                    {
                                        matricula: '310',
                                        itensRemun: [
                                            {
                                                codRubr: 3,
                                                ideTabRubr: 'TABELA1',
                                                vrRubr : 7797.03
                                            },
                                            {
                                                codRubr: 19,
                                                ideTabRubr: 'TABELA1',
                                                vrRubr : 5547.71
                                            },
                                            {
                                                codRubr: 21,
                                                ideTabRubr: 'TABELA1',
                                                vrRubr : 187.71
                                            },
                                            {
                                                codRubr: 113,
                                                ideTabRubr: 'TABELA1',
                                                vrRubr : 2728.96
                                            },
                                            {
                                                codRubr: 7065,
                                                ideTabRubr: 'TABELA2',
                                                vrRubr : 484.96
                                            },
                                            {
                                                codRubr: 3,
                                                ideTabRubr: 'TABELA1',
                                                vrRubr : 5547.71
                                            },
                                            {
                                                codRubr: 7117,
                                                ideTabRubr: 'TABELA2',
                                                vrRubr : 77.97
                                            },
                                            {
                                                codRubr: 9119,
                                                ideTabRubr: 'TABELA2',
                                                vrRubr : 5547.71
                                            },
                                            {
                                                codRubr: 9770,
                                                ideTabRubr: 'TABELA2',
                                                vrRubr : 5547.71
                                            },
                                            {
                                                codRubr: 9801,
                                                ideTabRubr: 'TABELA2',
                                                vrRubr : 671.11
                                            },
                                        ],
                                        infoAgNocivo: {
                                            grauExp: 1
                                        }
                                    }
                                ]
                            }
                        ]
                        }
                    }
                ]
            }
        };
        this.renderDmDev=this.renderDmDev.bind(this);
        this.renderIdeEstabLot=this.renderIdeEstabLot.bind(this);
        this.renderItensRemun=this.renderItensRemun.bind(this);
        this.renderRemunPerApur=this.renderRemunPerApur.bind(this);
        this.renderAgNocivo=this.renderAgNocivo.bind(this);
    }
    componentDidMount() {

    }
    teste(rowData) {
        console.log(rowData);
    }
    enviar() {
        $('#dspEnvio').fadeOut();
        /*$('#dspEnviando').html(<Submit idEventoFila={this.props.idEventoFila} />);*/
        $('#dspEnviando').fadeIn();
    }
    validarEvento() {
        this.loadInfoEvtRemun();
        this.setState({ bolAtivo: true });
        $("#divValidado").html();
    }
    formataCPF(numCPF){
        const cpf = require('node-cpf');
        return cpf.mask(numCPF);
    }
    renderDmDev(dmDev){
        try{
        let retorno= dmDev.map(function(dm,pos){
            let ideEstabLot = dm.infoPerApur.ideEstabLot[pos];
            let estabLot = this.renderIdeEstabLot(ideEstabLot);
            return(
                <div style={{ marginLeft: '8px', fontSize: '12px',padding:'8px 8px 8px 8px' }}>
                    <label>Id Folha:</label><strong>{dm.ideDmDev}</strong><br />
                    <label>CodCategoria:</label><strong>{dm.codCategoria}</strong><br />
                    <label>Descrição:</label><strong>Empregado - Geral, inclusive o empregado público da administração direta ou indireta contratado pela CLT</strong>
                    {estabLot}
                </div>
            );
        }.bind(this));
          
            return retorno;
        }catch(error){
            console.log(error);
        }
    }
    renderIdeEstabLot(ideEstabLot){
        try{
            let remunPerApurs= ideEstabLot.remunPerApur;
            let retorno = '';
            if(remunPerApurs.length>0){
                retorno = remunPerApurs.map(this.renderRemunPerApur);
            }
            return retorno;
        }catch(erro){
            console.log(erro);
        }
    }
    renderRemunPerApur(remunPerApur){
        let estiloRemun = { marginLeft: '8px', fontSize: '12px',border:'1px #ccc solid',padding:'5px 5px 5px 5px' };
        let remunPerApurs= remunPerApur.itensRemun;
        let rmnPrApur = remunPerApurs.map(this.renderItensRemun);
        let agNoc = this.renderAgNocivo(remunPerApur.infoAgNocivo);
        
        return (
            <div style={{ marginLeft: '8px', fontSize: '12px' }}>
                <label>Matricula: </label><strong>310</strong>
                {rmnPrApur}
                {agNoc}
            </div>
        );
    }
    renderAgNocivo(InfoAgNocivo){
        try{
            return (
                <div>
                    <label>InfoAgNocivo: </label>{InfoAgNocivo.grauExp}
                </div>
    
            );
        }catch(error){
            console.log(error);
        }

    }
    loadInfoEvtRemun(){
        this.setState({loadBar:'block'});
        $.ajax({
          url:"/EsocialAPI/folha/preview/Json/"+this.props.eventoFila.id,
          dataType: 'json',
          success:function(resposta){   
              if(resposta.json!=="null"){
                  let objetoRemun = JSON.parse(resposta.json);
                  this.setState({evtRemun:objetoRemun.evtRemun, loadBar:'none',resumo:'block',xmlEvento:'none',ajuda:'none'});
              }else{
                this.setState({loadBar:'none',xmlEvento:'none',ajuda:'none'});
              }
          }.bind(this),
          error:function(resposta){        
            this.setState({wzdGeracaoEvento:true,tblLoading:'none'});
          }.bind(this),
          beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
        });
      }
    renderXmlEditor(){
        try{
            this.setState({loadBar:'block'});
            $.ajax({
              url:"/EsocialAPI/folha/preview/XML/"+this.props.eventoFila.id,
              dataType: 'json',
              success:function(resposta){   
                  this.setState({xmlEnvio:resposta.xml, loadBar:'none',resumo:'none',xmlEvento:'block',ajuda:'none'});     
              }.bind(this),
              error:function(resposta){        
                this.setState({wzdGeracaoEvento:true,tblLoading:'none'});
              }.bind(this),
              beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
            });
        }catch(e){
            console.log(e);
        }
        //xmlEnvio
    }
    renderAjuda(){
        try{
            this.setState({loadBar:'none',resumo:'none',xmlEvento:'none',ajuda:'block'});     
        }catch(e){
            console.log(e);
        }
        //xmlEnvio
    }
    renderItensRemun(itensRemun){
        let estilo='';
        let valor = itensRemun.vrRubr.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
        if(itensRemun.ideTabRubr==='TABELA1'){
            estilo={color:'#126dff'};
        }else{
            estilo={color:'#c9430a'};
        }
        return(
            <div style={{ marginLeft: '8px', fontSize: '12px',border:'1px #f7f5f5 solid',paddingLeft:'12px', marginTop:'8px' }}>
                <label>Cód. Rubrica:  </label><strong>{itensRemun.codRubr}</strong><br />
                <label>ideTabRubr: </label><strong>{itensRemun.ideTabRubr}</strong><br />
                <label>Valor da Rubrica: : </label><strong style={estilo}>{valor}</strong>
            </div>
        );
    }
    render
    render() {
        const footer = (
            <div>
                <Button label="Enviar" icon="pi pi-check" onClick={(e) => this.enviar()} disabled={this.state.evtValido} />
                <Button label="Cancelar" icon="pi pi-times" onClick={(e) => this.setState({ bolAtivo: false })} />
            </div>
        );
        const baseurl = window.location.protocol + "//" + window.location.host ;
        const items = [
            {
               label:'Visualizar evento',
               icon:'pi pi-fw pi-pencil',
               command:(e) => {
                window.location.href = baseurl+ "/EventoFila/"+this.props.eventoFila.id;
               }
            },
            {
               label:'Validar Evento',
               icon:'pi pi-fw pi-check'
            },
            {
               label:'Informações adicionais',
               icon:'pi pi-fw pi-calendar',
               items:[
                  {
                     label:'Resumo',
                     icon:'pi pi-id-card',
                     command: (e) =>{
                        this.loadInfoEvtRemun();
                     }
                  },
                  {
                     label:'XML',
                     icon:'pi pi-fw pi-file-o',
                     command:(e) => {
                        this.renderXmlEditor();
                     } 
                  },
                  {
                     label:'Ajuda',
                     icon:'pi pi-fw pi-question-circle',
                     command:(e) => {
                        this.renderAjuda();
                     } 
                  }
               ]
            }
         ];

        return (
            <div style={{ display: 'inline' }}>
                <Dialog header="Evento de Remuneração" footer={footer} visible={this.state.bolAtivo} modal={true} onHide={() => this.setState({ bolAtivo: false, loadBar:'block',resumo:'none' })}>
                    <div style={{padding:'12px 12px 12px 12px',border:'1px solid #ccc'}}>
                        <Menubar model={items} style={{marginTop:'12px'}}/>
                        <ProgressBar mode="indeterminate" id="loadBarDiv" style={{display:this.state.loadBar}}/>
                        <div className="pure-control-group" style={{marginTop:'12px',display:this.state.resumo}}>
                            
                            <label>Nome:</label><strong>{this.props.nome}</strong><br />
                            <label>CPF:</label><strong>{this.formataCPF(this.state.evtRemun.ideTrabalhador.cpfTrab)}</strong><br />
                            <label>NIS:</label><strong>{this.state.evtRemun.ideTrabalhador.nisTrab}</strong>
                            <hr />
                            {this.renderDmDev(this.state.evtRemun.dmDev)}
                        </div>
                        <div className="pure-control-group" style={{marginTop:'12px',display:this.state.xmlEvento}}>
                            <XMLViewer xml={this.state.xmlEnvio} />
                        </div>
                        <div className="pure-control-group" style={{marginTop:'12px',display:this.state.ajuda}}>
                            <Ajuda idEventoEsocial={this.props.eventoFila.idEvento} />
                        </div>
                    </div>
                </Dialog>
                <Button onClick={(e) => this.validarEvento()} tooltip="S1200">
                    <FontAwesomeIcon icon={faMoneyCheckAlt} style={{ margin: '3px' }} />
                </Button>&nbsp;
            </div>
        );
    }
}
