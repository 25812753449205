import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Messages} from 'primereact/messages';
import {TblFolhaPagamento} from '../sessoes/Folpag/TblFolhaPagamento.js';
import{ MenuBar } from '../componentes/MenuBar';
import usuarioService from '../services/usuarioService.js';

export class Folha extends Component{
  constructor(){
    super();
    this.state={home :{icon: 'pi pi-home'},agendamentos:[],tipoEvento:"1",usuario:{}};
  }
  componentDidMount(){
    this.consultaUsuario();
  }
  consultaUsuario(){
    usuarioService.consultaUsuario((usuario) => {
      this.setState({ usuario });
    });
  }
  render() {
    return(
      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content">
          <div className="content align-center">
              <Messages ref={(el) => this.messages = el}></Messages>
              <TblFolhaPagamento idFolha={this.props.match.params.id} />
          </div>

            </div>
            <div className="footer l-box is-center">
              Copyright © Todos os direitos reservados.
            </div>

      </div>
    );
  }
}
