import React, { useState, useEffect, useRef } from 'react';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import $ from 'jquery';
import { ProgressSpinner } from 'primereact/progressspinner';

const EventoRecebidoPreview = (props) => {

    const [principalAtivo, setPrincipalAtivo] = useState(true);
    const [mensagemRetificacaoAtivo, setMensagemRetificacaoAtivo] = useState(false);
    const [mensagemExclusaoAtivo, setMensagemExclusaoAtivo] = useState(false);
    const [progressSpinnerAtivo, setProgressSpinnerAtivo] = useState(false);
    const [mensagemRetorno, setMensagemRetorno] = useState({});

    function direcionar(id) {
        var url = window.location.origin + "/EventoFila/" + id;
        console.log(url);
        window.location.replace(url);
    }
    function handleRetificacaoClick(idEventoFila) {
        setMensagemRetificacaoAtivo(false);
        setProgressSpinnerAtivo(true);
        $.ajax({
            type: "POST",
            url: "/EsocialAPI/retificacao/"+idEventoFila,
            dataType: 'json',
            success: function (resposta) {
                console.log(resposta);
                setProgressSpinnerAtivo(false);
                setMensagemRetorno(resposta);
                
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
          });
    }

    //TODO
    function handleExclusaoClick(idEventoFila) {
        setMensagemExclusaoAtivo(false);
        setProgressSpinnerAtivo(true);
        $.ajax({
            type: "POST",
            url: "/EsocialAPI/gerar/exclusao/"+idEventoFila,
            dataType: 'json',
            success: function (resposta) {
                console.log(resposta);
                setProgressSpinnerAtivo(false);
                setMensagemRetorno(resposta);
                
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
          });
    }

    function renderMensagemPrincipal(ativo) {
        if (ativo) {
            return (
                <div style={{ backgroundcolor: '#b5ffbf', textAlign: 'center', color: '#00b064', border: 'solid 1px #00b064', padding: '15px 5px 5px 5px', margin: '5px 15px 5px 5px' }}>
                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#00b064' }} />
                    <label style={{ fontSize: '18px', }}>Evento Recebido!</label>
                    <p>Selecione uma das opções abaixo para mais informações:</p>
                    <Button label="Info" className="p-button-info" icon="pi pi-eye" style={{ margin: '10px' }} onClick={() => direcionar(props.idEvento)} />
                    <Button label="Retificação" className="p-button-warning" icon="pi pi-clone" style={{ margin: '10px' }} onClick={() => {setPrincipalAtivo(false); setMensagemRetificacaoAtivo(true)}} />
                    <Button label="Exclusão (S3000)" className="p-button-danger" icon="pi pi-trash" style={{ margin: '10px' }} onClick={() => {setPrincipalAtivo(false); setMensagemExclusaoAtivo(true)}} />
                </div>
            )
        }
    }
    function renderMensagemRetificacao(ativo) {
        if(ativo){
            return (
                <div style={{ backgroundcolor: '#b5ffbf', textAlign: 'center', color: '#00b064', border: 'solid 1px #00b064', padding: '15px 5px 5px 5px', margin: '5px 15px 5px 5px' }}>
                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#00b064' }} />
                    <label style={{ fontSize: '18px', }}>Retificação do Evento Recebido</label>
                    <p>Deseja criar um evento de Retificação para correção de informações enviadas a partir deste evento?</p>
                    <Button label="Sim"  icon="pi pi-check" style={{ margin: '10px' }} onClick={() => handleRetificacaoClick(props.idEvento)} />
                    <Button label="Não" className="p-button-danger" icon="pi pi-times" style={{ margin: '10px' }} onClick={() => {setPrincipalAtivo(true); setMensagemRetificacaoAtivo(false)}} />
                </div>
            )
        } 
    }
    function renderMensagemExclusao(ativo) {
        if(ativo){
            return (
                <div style={{ backgroundcolor: '#b5ffbf', textAlign: 'center', border: 'solid 1px #d32f2f', padding: '15px 5px 5px 5px', margin: '5px 15px 5px 5px' }}>
                    <FontAwesomeIcon icon={faTrash} style={{ color: '#d32f2f' }} />
                    <label style={{ fontSize: '18px', }}>Exclusão de Evento</label>
                    <p>Deseja criar um evento de Exclusão {'(S3000)'} para remoção deste evento do eSocial?</p>
                    <Button label="Sim"  icon="pi pi-check" style={{ margin: '10px' }} onClick={() => handleExclusaoClick(props.idEvento)} />
                    <Button label="Não" className="p-button-danger" icon="pi pi-times" style={{ margin: '10px' }} onClick={() => {setPrincipalAtivo(true); setMensagemExclusaoAtivo(false)}} />
                </div>
            )
        } 
    }
    function renderProgressSpinner(ativo){
        if(ativo){
            return(
                <div style={{ backgroundcolor: '#b5ffbf', textAlign: 'center', color: '#00b064', border: 'solid 1px #00b064', padding: '15px 5px 5px 5px', margin: '5px 15px 5px 5px' }}>
                    <p>Processando, por favor aguarde.</p>
                    <ProgressSpinner/>
                </div>
            ) 
        }
    }

    function renderMensagemProcessamento(mensagem){
        if(mensagem.summary!=undefined && mensagem.summary!=null){
            return (
                <div style={{ backgroundcolor: '#b5ffbf', textAlign: 'center', color: '#00b064', border: 'solid 1px #00b064', padding: '15px 5px 5px 5px', margin: '5px 15px 5px 5px' }}>
                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#00b064' }} />
                    <label style={{ fontSize: '18px', }}>{mensagem.summary}</label>
                    <p>{mensagem.detail}</p>
                    
                </div>
            )
        }
    }
    

    return (
        <div>
            {renderMensagemPrincipal(principalAtivo)}
            {renderMensagemRetificacao(mensagemRetificacaoAtivo)}
            {renderMensagemExclusao(mensagemExclusaoAtivo)}
            {renderProgressSpinner(progressSpinnerAtivo)}
            {renderMensagemProcessamento(mensagemRetorno)}
        </div>
    );
}

export default EventoRecebidoPreview;