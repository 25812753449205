import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import api from '../../../services/api'
import $ from 'jquery';
import apiAjax from '../../../services/apiAjax';
import { Messages } from 'primereact/messages';
import { Message } from 'primereact/message';

const WizardImportacao = (props) => {

    const [displayImportacaoWizard, setDisplayImportacaoWizard] = useState('block');
    const [displayPopupPreview, setDisplayPopupPreview] = useState(false);
    const [displayImportacaoResum, setDisplayImportacaoResum] = useState('none');
    const [eventosPorStatus, setEventosPorStatus] = useState([]);
    const [eventos, setEventos] = useState([]);
    const [eventoResumoAtual, setEventoResumoAtual] = useState();
    const [importacoesConcluidas, setImportacoesConcluidas] = useState([]);
    const [importacaoConcluida, setImportacaoConcluida] = useState(false);

    const mensagem = useRef(null);


    useEffect(() => {
        if (displayPopupPreview == true) {
            previewNPer();
            console.log('Chamada api preview')

            //TODO fazer requisição utilizando axios async await
            /* const fetchUrl = 'api/importacao/preview/2'
            async function fetchAPI(){
               try{
                  const response = await api.get(fetchUrl);
                  console.log(response);
                  return response;
               }catch(error){
                  console.log(error);
                  return error;
               }
               
            }
            fetchAPI(); */

        }
    }, [displayPopupPreview]);

    function previewNPer() {
        $.ajax({
            url: "/EsocialAPI/api/importacao/preview/" + props.idGrupo,
            dataType: 'json',
            method: 'GET',
            error: function (erro) {
                console.log('Erro preview nper')
            }.bind(this),
            success: function (resposta) {
                console.log(resposta)
                //const eventosFila = resposta.filter((evt, index, self)=>self.indexOf(evt)===index);
                const idEventos = resposta.map((evt) => evt.idEvento).filter((evt, index, self) => self.indexOf(evt) === index);;
                const eventos = idEventos.map((id) => {
                    return {
                        idEvento: id,
                        codigo: resposta.find(e => e.idEvento === id).codigo,
                        nome: resposta.find(e => e.idEvento === id).nome,
                        grupo: resposta.find(e => e.idEvento === id).grupo
                    }
                })

                console.log(eventos);
                setEventos(eventos);
                setEventosPorStatus(resposta);
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }
    async function iniciarImportacao(eventos) {
        console.log(eventos);
        const codigosEvento = eventos.map(evt => evt.codigo);
        const data = eventos.map(evt => { return { idEvento: evt.idEvento, isImplementado: true, isImportAtivo: true, grupo: evt.grupo, evento: evt.nome, codigoEvento: evt.codigo } });
        console.log(data);
        //const onlyUniqueCodigo = codigosEvento.filter((codigo, index, self)=>self.indexOf(codigo)===index);
        console.log(codigosEvento);
        console.log(eventos[0])
        setEventoResumoAtual(eventos[0]);
        setDisplayPopupPreview(false);
        setDisplayImportacaoResum('block');
        setDisplayImportacaoWizard('none');
        const postRequest = apiAjax.postRequestNew;

        let eventosImportados = [];
        let lastIndex = 0; 
        for (const [index, codigoEvt] of codigosEvento.entries()) {
            console.log('Importção atual: ' + codigoEvt);
            console.log(eventosImportados);
            mensagem.current.clear();
            try {
                setEventoResumoAtual(eventos[index]);
                //const response = await postRequest('/EsocialAPI/api/importacao/start', data[index]);
                //const response = await api.post('/api/importacao/start', data[index]);
                console.log(data[index]);
                const response = await postRequest('/EsocialAPI/api/importacao/start', data[index]);
                console.log(response);
                eventosImportados.push(response);

                setImportacoesConcluidas(eventosImportados);
                mensagem.current.show(response);
            } catch (e) {
                console.log(e);
                console.log('Erro importação' + codigoEvt);
                const eventoErro = {
                    codigoEvento: codigoEvt,
                    status: 'ERRO',
                    msgRetorno: { severity: "error", summary: "Erro interno, consulte o suporte técnico. ", detail: "Eventos " + codigoEvt + " importados : 0 eventos.", sticky: true }
                }
                eventosImportados.push(eventoErro);
                setImportacoesConcluidas(eventosImportados);
                mensagem.current.show(eventoErro.msgRetorno);
            }
            lastIndex++;
        }
        console.log('Import concluido');
        console.log(lastIndex);
        console.log(eventos[lastIndex - 1]);
        setImportacaoConcluida(true);
        setEventoResumoAtual(eventos[lastIndex - 1]);
        
        //const resposta = getRequest('/EsocialAPI/api/importacao/start/' + 2 + "/" + idEvento)


    }

    function changeStyleButton(importacaoAtual, importacoesConcluidas, key) {

        console.log(importacoesConcluidas);
        const eventoConcluido = importacoesConcluidas.find((evtsConcluidos) => evtsConcluidos.codigoEvento == key);
        if (eventoConcluido != undefined) {
            if (eventoConcluido.status == 'CONCLUIDO') {
                return 'p-button-outlined p-button-success';
            } else if (eventoConcluido.status == 'ERRO') {
                return 'p-button-outlined p-button-danger';
            }
        }
        if (importacaoAtual === key) {
            return 'p-button-outlined p-button-warning';
        } else {
            return 'p-button-outlined p-button-info';
        }
    }
    function renderProgressBar(importacaoConcluida){
        if(!importacaoConcluida){
            return <ProgressBar mode="indeterminate" />
        }
    }
    function renderDetalheEventoImportado(eventoSelecionado) {

        const eventoImportado = importacoesConcluidas.find((e) => e.codigoEvento == ((eventoSelecionado != undefined) ? eventoSelecionado.codigo : ''));
        return (
            <div>
                <Messages ref={mensagem}></Messages>
                <div style={{ display: 'inline' }}>
                    {renderProgressSpinner(eventoImportado)}
                    <label>Evento: </label>
                </div>
                <div style={{ display: 'inline' }}>
                    <strong>{eventoSelecionado != undefined ? eventoSelecionado.codigo + ' - ' + eventoSelecionado.nome : ''} </strong>
                </div><br />
                <div style={{ display: 'inline' }}>
                    {renderProgressSpinner(eventoImportado)}
                    <label>Status: </label>
                </div>
                <div style={{ display: 'inline' }}>
                    <strong> {(eventoImportado == undefined) ? 'IMPORTANDO' : eventoImportado.status}</strong>
                </div><br />
                <div style={{ display: 'inline' }}>
                    {renderProgressSpinner(eventoImportado)}
                    <label>Eventos importados:</label>
                </div>
                <div style={{ display: 'inline' }}>
                    <strong> {eventoImportado != undefined ? eventoImportado.registros : ''}</strong>
                </div><br />
                {/* <div style={{ display: 'inline' }}>
                    <Button icon="pi pi-search" className="p-button-rounded p-button-success p-button-outlined" label="Visualizar eventos importados" />
                </div> */}
            </div>
        );
    }
    function renderProgressSpinner(statusImportacao) {
        if (statusImportacao == undefined || statusImportacao == null) {
            return <ProgressSpinner style={{ width: '18px', height: '18px', display: 'inline-block' }} />
        } else {
            return <i className="pi pi-check" ></i>
        }
    }

    function handleEventoButtonClick(evento) {
        setEventoResumoAtual(evento);
        mensagem.current.clear();
        const statusAtual = importacoesConcluidas.find((evt) => evt.codigoEvento == evento.codigo)
        console.log(statusAtual);
        mensagem.current.show(statusAtual.msgRetorno);
    }
    const renderEventoButton = (evento) => {

        return (
            <div key={evento.codigo}>
                <Button
                    label={"S" + evento.codigo}
                    className={changeStyleButton(eventoResumoAtual, importacoesConcluidas, evento.codigo)}
                    onClick={() => { handleEventoButtonClick(evento) }}
                />
                {/* <label style={estilo} key={evento.codigo}>S{evento.codigo}</label> */}
            </div>

        );
    }

    const renderEventosButtons = (eventos) => {
        if (eventos === null) {
            eventos = [];
        }
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 20px' }} >
                {eventos.map((evento) => renderEventoButton(evento))}
            </div>
        );
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={(e) => setDisplayPopupPreview(false)} className="p-button-text" />
                <Button label="Importar" icon="pi pi-check" onClick={(e) => iniciarImportacao(eventos)} autoFocus />
            </div>
        );
    }
    return (
        <div className="content align-center">
            <div>
                <div>
                    <h4>Eventos Não Periódicos:</h4>
                    <div style={{ display: displayImportacaoWizard }}>
                        <p>A seguir serão importados os eventos não periódicos do esocial.</p>
                        <p>Favor clique no botão importar para iniciar o processo.</p>
                        <div>
                            <Button label="Importar" icon="pi pi-check" style={{ display: displayImportacaoWizard }} iconPos="left" className="p-button-sm" onClick={() => setDisplayPopupPreview(true)} />
                            <Dialog
                                header="Importação eventos de tabela"
                                visible={displayPopupPreview} style={{ width: '50vw' }}
                                onHide={(e) => setDisplayPopupPreview(false)}
                                footer={renderFooter()}
                            >
                                <div>
                                    <strong>Os seguintes ítens serão importados: </strong>
                                    <em>
                                        <ol>
                                            <li>Históricos de envio.</li>
                                            <li>Última interação de lote.</li>
                                            <li>Id do evento e número do recibo.</li>
                                        </ol>
                                    </em>
                                </div>
                                <div>
                                    <DataTable value={eventosPorStatus} scrollable scrollHeight="200px">
                                        <Column field="codigo" header="Código"></Column>
                                        <Column field="status" header="status"></Column>
                                        <Column field="quantidade" header="Quantidade"></Column>
                                    </DataTable>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ display: displayImportacaoResum }}>
                
                {renderProgressBar(importacaoConcluida)}
                <div>{renderEventosButtons(eventos)}</div>
                <div>{renderDetalheEventoImportado(eventoResumoAtual)}</div>
            </div>
        </div>
    );
}

export default WizardImportacao;