import $ from 'jquery';
import Utils from '../utils/Util';

const ajudaService = {

    cargaAjuda(obj, callback) {
      $.ajax({
          url:"/EsocialAPI/ajuda/carga",
          type: 'post',
          data: JSON.stringify(obj),
          dataType: 'json',
          contentType: "application/json",
          error: Utils.handleAjaxError,
          success: function (resposta) {
              if (callback) callback(resposta);
          },
          beforeSend: function (xhr, settings) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token'));
          }
      });
    },

    uploadArquivo(formData, callback) {
      $.ajax({
          method: 'POST',
          url: "/EsocialAPI/ajuda/leiaute/upload/",
          data: formData,
          processData: false,
          contentType: false,
          success: function (resposta) {
              if (callback) callback(resposta);
          },
          beforeSend: function (xhr, settings) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token'));
          },
          error: Utils.handleAjaxError
      });
    },

    consultaImportacao(obj, callback) {
      $.ajax({
          url: "/EsocialAPI/ajuda/importacao/consulta/",
          method: 'POST',
          data: JSON.stringify({ obj }),
          contentType: "application/json",
          dataType: 'json',
          success: function (resposta) {
              if (callback) callback(resposta);
          },
          beforeSend: function (xhr, settings) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token'));
          },
          error: Utils.handleAjaxError
      });
    },

    consultaAjudaSessao(sessao, callback) {
        $.ajax({
            url: "/EsocialAPI/ajuda/sessao/" + sessao,
            dataType: 'json',
            success: function(retorno) {
                if (callback) callback(retorno);
            },
            beforeSend: function(xhr, settings) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token'));
            }
        });
    },

    construirMenu(login, callback) {
        $.ajax({
            url: "/EsocialAPI/sessao/arvore/" + login,
            dataType: 'json',
            success: function(retorno) {
                if (callback) callback(retorno);
            },
            beforeSend: function(xhr, settings) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token'));
            }
        });
    },
}

export default ajudaService;