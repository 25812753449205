import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { Panel } from 'primereact/panel';
import { Toolbar } from 'primereact/toolbar';
import {Password} from 'primereact/password';
import {Dropdown} from 'primereact/dropdown';
import $ from 'jquery';


export class EmailConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      host: ''
      , porta: ''
      , login: ''
      , dominio: ''
      , auth: ''
      , startTls: ''
      , senha: ''
      , protocolo: ''
    };

  }

  montaConfiguracaoEmail() {
    var configuracaoEmail = {
      hostEmail: this.state.host,
      hostPorta: this.state.porta,
      hostProtocolo: this.state.protocolo,
      hostLogin: this.state.login,
      hostSenha: this.state.senha,
      hostDominio: this.state.dominio,
      autenticacao: this.state.auth,
      startTls: this.state.startTls
    }
    return configuracaoEmail;
  }

  enviaConfiguracao(e) {
    e.preventDefault();
    var configuracaoEmail = this.montaConfiguracaoEmail();
    console.log(configuracaoEmail);
    $.ajax({
      url: "/EsocialAPI/config/salva/confEmail",
      type: 'post',
      data: JSON.stringify(configuracaoEmail),
      dataType: 'json',
      success: function (resposta) {
        this.showSuccess(resposta);
      }.bind(this),
      error: function (erro) {
        this.showSuccess(erro);
      }.bind(this),
      contentType: 'application/json',
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  testaServicoEmail() {
    var configuracaoEmail = this.montaConfiguracaoEmail();
    $.ajax({
      url: "/EsocialAPI/config/testa/confEmail",
      type: 'post',
      data: JSON.stringify(configuracaoEmail),
      dataType: 'json',
      success: function (resposta) {
        this.showSuccess(resposta);
      }.bind(this),
      error: function (erro) {
        console.log(erro);
        this.showSuccess(erro);
      }.bind(this),
      contentType: 'application/json',
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  showSuccess(msg) {
    this.messages.show(msg);
  }
  componentDidMount() {
    $.ajax({
      url: "/EsocialAPI/config/",
      dataType: 'json',
      success: function (resposta) {
        this.setState({ host: resposta.configuracaoEmail.hostEmail });
        this.setState({ porta: resposta.configuracaoEmail.hostPorta });
        this.setState({ login: resposta.configuracaoEmail.hostLogin });
        this.setState({ dominio: resposta.configuracaoEmail.hostDominio });
        this.setState({ auth: resposta.configuracaoEmail.autenticacao });
        this.setState({ startTls: resposta.configuracaoEmail.startTls });
        this.setState({ senha: resposta.configuracaoEmail.hostSenha });
        this.setState({ protocolo: resposta.configuracaoEmail.hostProtocolo });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  render() {
    const style = {
      label: {
          fontSize: 16,
          fontWeight: 'bold',
          display: 'block',
      },
      dropDown: {
          width: '95%'
      },
      inputText: {
          width: '95%'
      },
      inputContainer: {
          paddingTop: '10px',
          paddingBottom: '10px'
      },
      toolbar: {
          margin: 0,
          padding: 0,
      },
    }

    const protocolos = [
      {label: 'SMTP', value: 'smtp'},
    ];

    return (
      <>
        <div className="p-grid">
          <Messages ref={(el) => this.messages = el}></Messages>
        </div>
        <div className="p-grid">
          <div className="p-col-6">
            <Panel header="SMTP">
              <div>
                  <div style={style.inputContainer} className="pure-g">
                  <div className="pure-u-1-3">
                      <label style={style.label}>Protocolo: </label>
                      <Dropdown 
                        style={style.dropDown} 
                        value={this.state.protocolo}
                        options={protocolos} 
                        onChange={(e) => {this.setState({protocolo: e.value})}} 
                        placeholder="Selecione o protocolo"
                      />
                    </div>
                    <div className="pure-u-1-3">
                      <label style={style.label}>Host: </label>
                      <InputText 
                        id="txtHost" 
                        value={this.state.host} 
                        onChange={(e) => this.setState({ host: e.target.value })} 
                        tooltip="Ex: smtp.osm.com.br" 
                      />
                    </div>
                    <div className="pure-u-1-3">
                        <label style={style.label}>Porta: </label>
                        <InputText 
                          id="txtPorta" 
                          value={this.state.porta} 
                          onChange={(e) => this.setState({ porta: e.target.value })} 
                          tooltip="Ex: 528" 
                        />
                    </div>
                  </div>
                  <div style={style.inputContainer} className="pure-g">
                    <div className="pure-u-1-3">
                        <label style={style.label}>Domínio: </label>
                        <InputText 
                          id="txtDominio" 
                          value={this.state.dominio} 
                          onChange={(e) => this.setState({ dominio: e.target.value })} 
                          tooltip="Ex: osm.com.br" 
                        />
                    </div>
                    <div className="pure-u-1-3">
                        <label style={style.label}>Login: </label>
                        <InputText 
                          id="txtLogin" 
                          value={this.state.login} 
                          onChange={(e) => this.setState({ login: e.target.value })} 
                          tooltip="Ex: email" 
                        />
                    </div>
                    <div className="pure-u-1-3">
                        <label style={style.label}>Senha: </label>
                        <Password 
                          id="txtsenha" 
                          value={this.state.senha} 
                          onChange={(e) => this.setState({ senha: e.target.value })} 
                          tooltip="Ex: senha" 
                        />
                    </div>
                  </div>
                  <div style={style.inputContainer} className="pure-g">
                    <div className="pure-u-1-3">
                        <label style={style.label}>Autenticação: </label>
                        <Checkbox 
                          inputId="cb1" 
                          onChange={e => this.setState({ auth: e.checked ? 1 : 0 })} 
                          checked={this.state.auth}
                        ></Checkbox>
                    </div>
                    <div className="pure-u-1-3">
                        <label style={style.label}>TLS: </label>
                        <Checkbox 
                          inputId="cb2" 
                          onChange={e => this.setState({ startTls: e.checked ? 1 : 0 })} 
                          checked={this.state.startTls}
                        ></Checkbox>
                    </div>
                  </div>
              </div>
            </Panel>
            <Toolbar 
              style={style.toolbar}
              right={
                <React.Fragment>
                  <Button label="Salvar" style={{'margin': '5px'}} icon="pi pi-save" iconPos="left" onClick={(e)=>this.enviaConfiguracao(e)} />
                  <Button className="p-button-success" style={{'margin': '5px'}} label="Testar Conexão" onClick={(e)=>this.testaServicoEmail(e)} />
                </React.Fragment>
              } 
            />
          </div>
        </div>
      </>
    );
  }
}
