import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { InputMask } from 'primereact/inputmask';
import { Messages } from 'primereact/messages';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import $ from 'jquery';

export class AmbienteConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lista: []
      , home: { icon: 'pi pi-home' }
      , configuracoes: props.ambienteConfigData
      , ambiente: 2
      , configuracoesAmbiente: {}
      , processoEmissao: 1
      , cliente: ''
      , versaoEvento: ''
      , versaoProcessamento: ''
      , cnpjRaiz: ''
      , cnpj: ''
      , numeroInsc: ''
      , resetEsocial: false
      , tipoInscricao: ''
      , versaoServico: ''
      , blkAmbiente: 'none'
      , caminhoLog: ''
      , porta: 8080
      , url: 'localhost'
      , urlWebservice: 'localhost'
      , grupo: 1
      , docker: 0
    };
    this.componentDidMount = this.componentDidMount.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.mudaAmbiente = this.mudaAmbiente.bind(this);
    this.resetaProducaoRestrita = this.resetaProducaoRestrita.bind(this);
  }
  montaConfiguracaoAmbiente() {
    var configuracaoAmbiente = {
      url: this.state.url,
      urlWebservice: this.state.urlWebservice,
      caminhoLog: this.state.caminhoLog,
      porta: this.state.porta,
      cliente: this.state.cliente,
      tipoAmbiente: this.state.ambiente,
      processoEmissao: this.state.processoEmissao,
      versaoProcessamento: this.state.versaoProcessamento,
      versaoServico: this.state.versaoServico,
      grupo: this.state.grupo,
      docker: this.state.docker
    }
    return configuracaoAmbiente;
  }
  montaConfiguracaoInstitucional() {
    var configuracaoInstitucional = {
      nomeRazao: "",
      cnpjRaiz: this.state.cnpjRaiz,
      numInscRaiz: this.state.numeroInsc,
      cpfCnpj: this.state.cnpj,
      cpfEmissor: ""
    }
    return configuracaoInstitucional;
  }
  mudaAmbiente(event) {
    try {
      if (event.value === 2) {
        this.setState({ blkAmbiente: 'block' });
      } else {
        this.setState({ blkAmbiente: 'none' });
      }
      this.setState({ ambiente: event.value });
    } catch (erro) {
      console.log(erro);
    }
  }

  sendRequest(configuracao, nomeConf) {
    console.log(configuracao);
    $.ajax({
      url: "/EsocialAPI/config/salva/conf" + nomeConf,
      type: 'post',
      data: JSON.stringify(configuracao),
      dataType: 'json',
      success: function (resposta) {
        this.showSuccess(resposta);
      }.bind(this),
      error: function (erro) {
        console.log(erro);
        this.showSuccess(erro);
      }.bind(this),
      contentType: 'application/json',
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
    this.render();
  }
  enviaConfiguracao(e, nomeConf) {
    e.preventDefault();
    var configuracao = null;
    if (nomeConf === 'Ambiente') {
      configuracao = this.montaConfiguracaoAmbiente();
      this.sendRequest(configuracao, nomeConf);
    } else if (nomeConf === 'Institucional') {
      configuracao = this.montaConfiguracaoInstitucional();
      this.sendRequest(configuracao, nomeConf);
    }
  }
  showSuccess(msg) {
    this.messages.show(msg);
  }
  componentDidMount() {
    $.ajax({
      url: "/EsocialAPI/config/",
      dataType: 'json',
      success: function (resposta) {
        this.setState({ cliente: resposta.configuracaoAmbiente.cliente });
        this.setState({ versaoEvento: resposta.configuracaoAmbiente.tipoAmbiente });
        this.setState({ versaoProcessamento: resposta.configuracaoAmbiente.versaoProcessamento });
        this.setState({ versaoServico: resposta.configuracaoAmbiente.versaoServico });
        this.setState({ ambiente: resposta.configuracaoAmbiente.tipoAmbiente });
        this.setState({ nomeRazaoSocial: resposta.configuracaoInstitucional.nomeRazaoSocial });
        this.setState({ numeroInsc: resposta.configuracaoInstitucional.numeroInsc });
        this.setState({ tipoInscricao: resposta.configuracaoInstitucional.tipoInscricao });
        this.setState({ cnpjRaiz: resposta.configuracaoInstitucional.cnpjRaiz });
        this.setState({ cnpj: resposta.configuracaoInstitucional.cpfCnpj });
        this.setState({ caminhoLog: resposta.configuracaoAmbiente.caminhoLog });
        this.setState({ grupo: resposta.configuracaoAmbiente.grupo });
        this.setState({ docker: resposta.configuracaoAmbiente.docker });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  resetaProducaoRestrita(e) {
    e.preventDefault();
    $.ajax({
      url:"/EsocialAPI/exclusao/reset",
      success:function(resposta){
        this.showSuccess(resposta);
      }.bind(this),
      error:function(erro){
        console.log(erro);
        this.showSuccess(erro);
      }.bind(this),
      contentType:'application/json',
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  handleChange(event) {
    this.setState({ cliente: event.target.value });
  }
  accept() {
    console.log('Rejeitado');
  }

  reject() {
    console.log('Rejeitado');
  }

  render() {
    const style = {
      label: {
          fontSize: 16,
          fontWeight: 'bold',
          display: 'block',
      },
      dropDown: {
          width: '95%'
      },
      inputText: {
          width: '95%'
      },
      inputContainer: {
          paddingTop: '10px',
          paddingBottom: '10px'
      },
      toolbar: {
          margin: 0,
          padding: 0,
      },
    }
    const cbTipoInscricao = [
      { label: 'CNPJ', value: 1 },
      { label: 'CPF', value: 2 }
    ];
    const cbCnpjRaiz = [
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ];
    const ambientes = [
      { label: 'Produção', value: 1 },
      { label: 'Pré Produção - Dados Reais', value: 2 },
      { label: 'Pré Produção - Dados Ficticios', value: 3 },
      { label: 'Homologação', value: 6 },
      { label: 'Validação', value: 7 },
      { label: 'Testes', value: 8 },
      { label: 'Desenvolvimento', value: 9 }
    ];
    const processosEmissao = [
      { label: 'Aplicativo do Empregador', value: 1 },
      { label: 'Aplicativo Governamental', value: 2 }
    ];
    const grupos = [
      { label: 'GRUPO 1 - Empresas com faturamento anual superior a R$ 78 milhões', value: 1 },
      { label: 'GRUPO 2 - Entidades empresariais com faturamento no ano de 2016 de até R$ 78.000.000,00 (setenta e oito milhões) e que não sejam optantes pelo Simples Nacional', value: 2 },
      { label: 'GRUPO 3 - Pessoas Jurídicas - empregadores optantes pelo Simples Nacional e entidades sem fins lucrativos', value: 3 },
      { label: 'GRUPO 4 - Órgãos públicos e organizações internacionais', value: 4 },
    ];
    const dockerOptions = [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
    ];
    return (
      <>
        <div className="p-grid">
          <Messages ref={(el) => this.messages = el}></Messages>
        </div>
        <div className="p-grid">
          <div className="p-col">
            <Panel header="Ambiente do cliente">
              <div>
                  <div style={style.inputContainer} className="pure-g">
                      <div className="pure-u-1-2">
                          <label style={style.label}>Cliente: </label>
                          <InputText
                            id="txtCliente"
                            value={this.state.cliente}
                            style={style.inputText}
                            onChange={(e) => this.setState({ cliente: e.target.value })}
                          />
                      </div>
                      <div className="pure-u-1-2">
                          <label style={style.label}>Tipo Ambiente: </label>
                          <Dropdown
                            value={this.state.ambiente}
                            options={ambientes}
                            style={style.dropDown}
                            onChange={(e) => this.mudaAmbiente(e)}
                          />
                      </div>
                      {/*
                      <div style={{ display: this.state.blkAmbiente, marginTop: '15px', marginBottom: '15px' }}>
                        <ConfirmDialog
                          visible={this.state.resetEsocial}
                          onHide={() => this.setState({ resetEsocial: false })}
                          message="Tem certeza que deseja apagar as informações enviadas ao ambiente do eSocial?"
                          header="Confirmação"
                          icon="pi pi-exclamation-triangle"
                          accept={this.accept}
                          reject={this.reject}
                        />
                        <Button
                          onClick={(e) => this.resetaProducaoRestrita(e)}
                          icon="pi pi-check"
                          label="Resetar Produção Restrita"
                        />
                      </div>
                      */}
                  </div>
                  <div style={style.inputContainer} className="pure-g">
                      <div className="pure-u-1-2">
                          <label style={style.label}>Processo de Emissão: </label>
                          <Dropdown
                            value={this.state.processoEmissao}
                            options={processosEmissao}
                            style={style.dropDown}
                            onChange={(e) => this.setState({ processoEmissao: e.value })}
                          />
                      </div>
                      <div className="pure-u-1-2">
                          <label style={style.label}>Versão de Processamento: </label>
                          <InputText
                            id="txtVersao"
                            value={this.state.versaoProcessamento}
                            style={style.inputText}
                            onChange={(e) => this.setState({ versaoProcessamento: e.target.value })}
                            tooltip="Ex: 2_4_02"
                          />
                      </div>
                    </div>
                    <div style={style.inputContainer} className="pure-g">
                      <div className="pure-u-1-2">
                          <label style={style.label}>Versão Evento: </label>
                          <InputText
                            id="txtVersaoEvento"
                            value={this.state.versaoProcessamento}
                            style={style.inputText}
                            onChange={(e) => this.setState({ versaoEvento: e.target.value })}
                            tooltip="Ex: 2.4.2"
                          />
                      </div>
                      <div className="pure-u-1-2">
                          <label style={style.label}>Versão Serviço: </label>
                          <InputText
                            id="txtVersaoServico"
                            value={this.state.versaoServico}
                            style={style.inputText}
                            onChange={(e) => this.setState({ versaoServico: e.target.value })}
                            tooltip="1.1.1"
                          />
                      </div>
                    </div>
                    <div style={style.inputContainer} className="pure-g">
                      <div className="pure-u-1-2">
                          <label style={style.label}>Docker: </label>
                          <Dropdown
                            value={this.state.docker}
                            options={dockerOptions}
                            style={style.dropDown}
                            onChange={(e) => this.setState({ docker: e.value })}
                          />
                      </div>
                      <div className="pure-u-1-2">
                          <label style={style.label}>Log da Aplicação: </label>
                          <InputText
                            id="txtCaminhoLog"
                            value={this.state.caminhoLog}
                            style={style.inputText}
                            onChange={(e) => this.setState({ caminhoLog: e.target.value })}
                            tooltip="Ex: /opt/apache-tomcat-9.0.52/logs/catalina.out"
                          />
                      </div>
                  </div>
                  <div style={style.inputContainer} className="pure-g">
                      <div className="pure-u-1-2">
                          <label style={style.label}>Grupo: </label>
                          <Dropdown
                            value={this.state.grupo}
                            options={grupos}
                            style={style.dropDown}
                            onChange={(e) => this.setState({ grupo: e.value })}
                          />
                      </div>
                  </div>
              </div>
            </Panel>
            <Toolbar 
              style={style.toolbar}
              right={
                <React.Fragment>
                  <Button
                    label="Salvar"
                    id="enviaAmbiente"
                    icon="pi pi-save"
                    iconPos="left"
                    style={{'margin': '5px'}}
                    onClick={(e) => this.enviaConfiguracao(e, 'Ambiente')}
                  />
                </React.Fragment>
              } 
            />
          </div>
          <div className="p-col">
            <Panel header="Informações do cliente">
              <div>
                <div style={style.inputContainer} className="pure-g">
                    <div className="pure-u-1-2">
                        <label style={style.label}>Tipo Inscrição: </label>
                        <SelectButton
                          value={this.state.tipoInscricao}
                          options={cbTipoInscricao}
                          onChange={(e) => this.setState({ tipoInscricao: e.value })}
                        ></SelectButton>
                    </div>
                    <div className="pure-u-1-2">
                        <label style={style.label}>CNPJ Raíz: </label>
                        <SelectButton
                          value={this.state.cnpjRaiz}
                          options={cbCnpjRaiz}
                          onChange={(e) => this.setState({ cnpjRaiz: e.value })}
                        ></SelectButton>
                    </div>
                  </div>
                  <div style={style.inputContainer} className="pure-g">
                    <div className="pure-u-1-2">
                        <label style={style.label}>CNPJ: </label>
                        <InputMask
                          mask="99.999.999/9999-99"
                          id="txtCnpj"
                          value={this.state.cnpj}
                          style={style.inputText}
                          onChange={(e) => this.setState({ cnpj: e.target.value })}
                          tooltip="Ex: 00.399.857/0001-26"
                        />
                    </div>
                    <div className="pure-u-1-2">
                        <label style={style.label}>Número Inscrição: </label>
                        <InputMask
                          mask="99999999"
                          id="txtNumeroInsc"
                          value={this.state.cnpj}
                          style={style.inputText}
                          onChange={(e) => this.setState({ numeroInsc: e.target.value })}
                          tooltip="Ex: 00.399.857/0001-26"
                        />
                    </div>
                </div>
              </div>
            </Panel>
            <Toolbar 
              style={style.toolbar}
              right={
                <React.Fragment>
                  <Button
                    label="Salvar"
                    id="enviaInstitucional"
                    icon="pi pi-save"
                    iconPos="left"
                    style={{'margin': '5px'}}
                    onClick={(e) => this.enviaConfiguracao(e, 'Institucional')}
                  />
                </React.Fragment>
              } 
            />
          </div>
        </div>
      </>
    );    
  }
}
