import $ from 'jquery';

const usuarioService = {

    consultaUsuario(callback) {
        var baseurl = window.location.protocol + "//" + window.location.host;
        $.ajax({
          url: "/EsocialAPI/usuario/logado/",
          dataType: 'json',
          error: function() {
            window.location.href = baseurl;
          },
          success: function(resposta) {
            callback(resposta);
          },
          beforeSend: function(xhr, settings) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token'));
          }
        });
    },

    listarUsuarios(callback) {
      $.ajax({
          url: "/EsocialAPI/usuario/listar",
          dataType: 'json',
          success: function(resposta) {
              if (callback) callback(resposta);
          },
          beforeSend: function(xhr, settings) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token'));
          }
      });
    },
}

export default usuarioService;