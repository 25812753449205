import React, {Component} from 'react';
import $ from 'jquery';
import {Tree} from 'primereact/tree';

export class Ajuda extends Component{
  constructor(){
    super();

    this.state={
      nodes: null,
      grupo:null,
      obrigatorio:null,
      pai:'',
      idEventoEsocial:0,
      loading: true,
      descricao:null,
      titulo:null
    }
  }

  componentWillReceiveProps(props) {
    this.setState({idEventoEsocial: props.idEventoEsocial});
  }
  componentDidMount(){
    this.atualizaAjuda();
  }

  atualizaAjuda(){
    console.log(this.props.idEventoEsocial);
    $.ajax({
      url:"/EsocialAPI/ajuda/evento/"+this.props.idEventoEsocial+"/",
      type:'GET',
      error:function(error){
      },
      success:function(retorno){
        console.log(retorno);
        //this.state.evt.push(retorno);
        this.setState({nodes: retorno})
        this.setState({loading:false});
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  onSelect(event) {
    this.setState({
      descricao: event.node.data,
      titulo:event.node.label,
      grupo: event.node.isGrupo,
      pai: event.node.ajudaPai,
      obrigatorio: event.node.obrigatorio,
      localizacaoSistema: event.node.localizacaoSistema,
      campoSistema: event.node.campoSistema
    });
  }
  render(){

   return(

        <div>
          <div style={{display:'inline-block', textAlign:'left',verticalAlign:'top',width:'40%' }} >
            <h5>Índice</h5>
              <Tree
                filter={true}
                value={this.state.nodes}
                selectionMode="single"
                loading={this.state.loading}
                style={{ textAlign:'left', border:'0px'}}
                selectionKeys={this.state.selected}
                onSelectionChange={e => this.setState({selected: e.value})}
                onSelect={this.onSelect.bind(this)}
              />
          </div>
          <div style={{display:'inline-block',verticalAlign:'top' ,textAlign:'left',width:'60%',borderLeft:'3px solid #ccc'}}>
              <h5 style={{paddingLeft:'7px'}}>Informações sobre {this.state.titulo}</h5>
              <div style={{padding:'2px',paddingLeft:'10px'}}>
                <p><span style={{fontWeight:'bold'}}>Tag:</span> {this.state.titulo} </p>
                <p><span style={{fontWeight:'bold'}}>Tipo:</span> {this.state.grupo===true?'Grupo':'Campo'} </p>
                <p><span style={{fontWeight:'bold'}}>Campo Obrigatorio: </span> {this.state.obrigatorio===true?'Sim':'Não'} </p>
                <p><span style={{fontWeight:'bold'}}>Grupo Pertencente:</span> {this.state.pai}</p>
                <p><span style={{fontWeight:'bold'}}>Descrição:</span> {this.state.descricao}</p>
                <p><span style={{fontWeight:'bold'}}>Localização MENTORH:</span> {this.state.localizacaoSistema}</p>
                <p><span style={{fontWeight:'bold'}}>Campo MENTORH:</span> {this.state.campoSistema}</p>
              </div>
          </div>

        </div>


    );
  }
}