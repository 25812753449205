import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';

import React, { useState, useEffect } from 'react';
import Utils from '../../utils/Util';
import { Paginator } from 'primereact/paginator';

const DialogMensagem = (props) => {

    const [basicFirst, setBasicFirst] = useState(0);
    const [basicRows, setBasicRows] = useState(10);
    const [visible, setVisible] = useState(false);
    const [contentFirst, setContentFirst] = useState(0);
    const [selectedProduct1, setSelectedProduct1] = useState(null);
    const [checked, setChecked] = useState(false);
    const [mensagemUsuario, setMensagemUsuario] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)



    useEffect(() => {
        console.log(props.mensagensUsuario)
        if (props.mensagensUsuario != null) {
            setMensagemUsuario(props.mensagensUsuario[contentFirst])
            const lida = props.mensagensUsuario[contentFirst].dataLida != null;
            console.log(lida)
            setChecked(lida)
            setIsLoaded(true)
        }

    }, [contentFirst, props.mensagensUsuario]);


    const renderFooter = (usuario) => {

        return (
            <div>
                <div className="p-field-checkbox">
                    <Checkbox inputId="binary" checked={checked} onChange={(e) => marcarComoLida(e, usuario.id, mensagemUsuario.idMensagem)} />
                    <label htmlFor="binary">Marcar como lida</label>
                </div>
                <Paginator first={contentFirst} rows={1} totalRecords={props.mensagensUsuario.length} onPageChange={onContentPageChange}
                    template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"></Paginator>
            </div>
        );
    }

    const anexos = [{
        id: 1,
        nome: 'relatorio_inconsistente.csv',
        path: '/osm/relatorios/'
    }];

    const downloadCSV = (e) => {

        setSelectedProduct1(e);

        fetch("/EsocialAPI/download", {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + localStorage.getItem("auth-token")
            },
            timeout: 600000
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Erro na requisição.");
                }
                console.log(response)
                return response.blob();
            })
            .then(csvBlob => {
                //console.log(resposta);
                //const data = JSON.parse(resposta);
                //const csvBlob = data.fileBytes.blob();
                const url = URL.createObjectURL(csvBlob);

                const a = document.createElement('a');
                a.href = url;
                a.download = 'relatorio.csv';
                a.click();

                URL.revokeObjectURL(url);
            })
            .catch(error => {
                Utils.handleAjaxError(error);
            });

    };

    const onContentPageChange = (event) => {
        setContentFirst(event.first);
        setChecked(true)
    }

    function renderImagemMensagem(mensagemUsuario) {

        const nomeImagem = mensagemUsuario.urlImagem
        const pathImage = `/EsocialAPI/api/mensagem/imagem/${mensagemUsuario.idMensagem}/${nomeImagem}`
        console.log(mensagemUsuario)
        if (Utils.isObjectHasValue(mensagemUsuario)) {
            return (
                <div className="pure-u-3-3" style={{ marginBottom: '25px' }}>
                    <a href={window.location.href} onClick={(e) => this.mostrarDiv('moreInfo')}><img src={pathImage} alt="" /></a>
                </div>
            )
        }
    }

    function marcarComoLida(e, idUsuario, idMensagem) {
        fetch("EsocialAPI/mensagem-usuario/marcar-como-lida/" + idUsuario + "/" + idMensagem, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + localStorage.getItem("auth-token")
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Erro na requisição.");
                }
                return response.json();
            })
            .then(resposta => {
                console.log(resposta);
                setChecked(resposta)
                resposta ? props.mensagensUsuario[contentFirst].dataLida = new Date() : props.mensagensUsuario[contentFirst].dataLida = null
            })
            .catch(error => {
                Utils.handleAjaxError(error);
            });
    }

    return (
        <div>
            {
                props.mensagensUsuario != null && mensagemUsuario != null ?
                    <Dialog header={'Notificação - ' + mensagemUsuario.titulo} visible={props.visible} style={{ width: '50vw' }} footer={renderFooter(props.usuario, mensagemUsuario)} onHide={props.onHide}>

                        <p>
                            {mensagemUsuario.corpo.split('\n').map((linha, index) => (
                                <React.Fragment key={index}>
                                    {linha}
                                    <br />
                                </React.Fragment>
                            ))}
                        </p>

                        {/* {renderImagemMensagem(props.mensagensUsuario[contentFirst])} */}
                        <br />
                        {mensagemUsuario.anexos != undefined && mensagemUsuario.anexos.length > 0 ?
                            <DataTable value={anexos} selectionMode="single" selection={selectedProduct1} onSelectionChange={e => downloadCSV(e.value)} dataKey="id" className="p-datatable-sm">
                                <Column field="nome" header="Anexo"></Column>
                            </DataTable> : ''
                        }
                    </Dialog> : ''
            }

        </div>
    );
};

export default DialogMensagem;
