import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faHandshake, faHandHoldingUsd, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Checkbox} from 'primereact/checkbox';

import $ from 'jquery';
import { Button } from 'primereact/button';
export class TabPreviewFechamento extends Component{
    constructor(){
      super();
      library.add(fab, faHandshake, faHandHoldingUsd, faMoneyCheckAlt);
      this.state={
          idEventoFila:0
          ,bolAtivo:false
          ,activeIndex:0
          ,evtValido:false
          ,nomeResp:''
          ,cpfResp:''
          ,emailResp:''
          ,telefoneResp:''
          ,frmPronto:true
          ,eventos:[]
          ,ano:0
          ,mes:0
          ,eventoFechamento:{idEventoFila:0,statusEvento:'PENDENTE'}
          ,hasNotretorno:true
          ,hasNotEvento:true
          ,tabActiveIndex:0
        };
    }
    componentDidMount(){
        this.carregaFechamento();
    }
    carregaFechamento(){
        $.ajax({
          url:"/EsocialAPI/folha/resumo/",
          method:'POST',
          data: JSON.stringify ({ano : this.props.anoApuracao,mes:+this.props.mesApuracao }),
          contentType: "application/json",
          dataType: 'json',
          success:function(resposta){
            console.log(resposta);
            this.setState({eventos:resposta.eventos});
            if(resposta.responsavel!=null){
                this.setState({
                    nomeResp:resposta.responsavel.nomeResponsavel
                    ,cpfResp:resposta.responsavel.cpfResponsavel
                    ,emailResp:resposta.responsavel.emailResponsavel
                    ,telefoneResp:resposta.responsavel.telefoneResponsavel
                });
            }

          }.bind(this),
          beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
        });
      }
      yearTemplate(rowData, column) {
          console.log(rowData);
        return <Checkbox onChange={e => rowData.evtEnviado=e.value} checked={rowData.evtEnviado}></Checkbox>;
      }
    render() {

      return(     
        <div>
            <h5>Fechamento de folha</h5>
            <div>
                <label>Nome do Responsável :</label>
                <label>{this.state.nomeResp}</label><br />
                <label>CPF do Responsável :</label>
                <label>{this.state.cpfResp}</label><br />
                <label>E-mail do Responsável :</label>
                <label>{this.state.emailResp}</label><br />
                <label>Telefone do Responsável :</label>
                <label>{this.state.telefoneResp}</label><br />
            </div>
            <div>
                    <DataTable value={this.state.eventos}>
                        <Column field="evt" header="Evento" />
                        <Column field="qtd" header="Quantidade" />
                        <Column field="evtEnviado" header="Eventos Enviados" body={this.yearTemplate} />
                    </DataTable>
            </div>
            <div style={{marginTop:'25px'}}>
                <Button label="Enviar" icon="pi pi-envelope" onClick={(e) => this.validar()} />
            </div>
        </div>
      );
    }
    }