import { BreadCrumb } from 'primereact/breadcrumb';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import React, { Component, useEffect, useState } from 'react';
import { MenuBar } from '../../componentes/MenuBar';
import { FiltroEventosEnviados } from './Filtro/FiltroEventosEnviados';
import $ from 'jquery';
import BtnExportCsv from '../../componentes/BtnExportCsv';
import TblEventosRecebidos from './Tabela/TblEventosRecebidos';
import usuarioService from '../../services/usuarioService';

const RelEventosRecebidos = () => {

    const [filtroPesquisa, setFiltroPesquisa] = useState({ idGrupo: 0, idEvento: 0 });
    const [grupos, setGrupos] = useState([]);
    const [eventos, setEventos] = useState([])
    const [grupo, setGrupo] = useState();
    const [evento, setEvento] = useState();
    const [mes, setMes] = useState();
    const [ano, setAno] = useState();
    const [indApuracao, setIndApuracao] = useState();
    const [usuario, setUsuario] = useState({});
    const [registrosRel, setRegistrosRel] = useState([])
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [progresBarVisible, setProgresBarVisible] = useState('none');
    const [isCsvVisible, setIsCsvVisible] = useState(false);
    const [headerCsv, setHeaderCsv] = useState([
        { label: 'Id', key: 'idEventoFila' },
        { label: 'Empresa', key: 'empresa' },
        { label: 'Usuário', key: 'usuario' },
        { label: 'Data envio', key: 'dataEnvio' },
        { label: 'Ação', key: 'acao' },
        { label: 'Código Evento', key: 'codigoEvento' },
        { label: 'ID Lote', key: 'idLote' },
        { label: 'Detalhe', key: 'objDetalhe' },
        { label: 'Recibo', key: 'recibo' }
    ]);


    useEffect(() => {
        consultaUsuario();
        atualizagrupo();
    }, [])

    function atualizagrupo() {
        $.ajax({
            url: '/EsocialAPI/grupo/combo/',
            type: 'get',
            dataType: 'json',
            success: function (resposta) {
                console.log(resposta)
                const gruposExcecao = ['TOTALIZADOR', 'EXCLUSAO']
                const gruposFiltrados = resposta.filter(obj => !gruposExcecao.includes(obj.label))
                setGrupos(gruposFiltrados)
            },
            error: function (erro) {
                console.log(erro);
            },
            contentType: 'application/json',
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    function consultaUsuario() {
        usuarioService.consultaUsuario((usuario) => {
            this.setState({ usuario });
        });
    }

    function atualizaEventos(idGrupo) {
        var urlEventos = '/EsocialAPI/evento/combo/' + idGrupo;
        $.ajax({
            url: urlEventos,
            type: 'get',
            dataType: 'json',
            success: function (resposta) {
                console.log(resposta);
                setEventos(resposta)
            },
            error: function (erro) {
                console.log(erro);
            },
            contentType: 'application/json',
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    function handleGrupo(grupo) {
        console.log(grupo);
        var grupoTemp = Object.assign({}, grupo);
        grupoTemp = grupo;
        setGrupo(grupoTemp);
        atualizaEventos(grupoTemp);
    }

    function handleEvento(evento) {
        console.log(evento);
        var eventoTemp = Object.assign({}, evento);
        eventoTemp = evento;
        setEvento(eventoTemp);
        setButtonDisabled(false);
    }

    function handleAno(ano) {
        console.log(ano);
        setAno(ano);
    }
    function handleMes(mes) {
        console.log(mes);
        setMes(mes)
    }
    function handleIndApuracao(indApuracao) {
        console.log(indApuracao);
        setIndApuracao(indApuracao);
    }

    function montaFiltroEvtEnviados() {
        var filtroEvtEnviados = {
            idTipoEvento: grupo,
            idEvento: evento,
            ano: ano,
            mes: mes,
            isAnual: indApuracao == 2
        }
        return filtroEvtEnviados;
    }

    function handleClick() {
        setProgresBarVisible('block');
        console.log(JSON.stringify(montaFiltroEvtEnviados()));
        $.ajax({
            timeout: 0,
            url: '/EsocialAPI/relatorios/eventosenviados/',
            type: 'post',
            dataType: 'json',
            data: JSON.stringify(montaFiltroEvtEnviados()),
            success: function (resposta) {
                console.log('relatorio');
                console.log(resposta);
                setRegistrosRel(resposta);
                setProgresBarVisible('none');
                setIsCsvVisible(true);
            },
            error: function (erro) {
                console.log(erro)
                console.log('A quantidade de eventos é muito grande');
            },
            contentType: 'application/json',
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    function geraNomeRelatorio() {
        const timeElapsed = Date.now();
        const today = new Date(timeElapsed);
        const nomeRelatorio = 'relat_recebidos_' + today.toISOString() + '.csv';
        return nomeRelatorio;
    }

    const items = [
        { label: 'Home', icon: 'pi pi-home', url: '/home' },
        { label: 'Relatórios', icon: 'pi pi-home', url: '/relatorio/inconsistencias' },
        { label: 'Relatório de Eventos Recebidos', icon: 'pi-home', url: '/relatorio/inconsistencias' }
    ];

    return (
        <div>
            <MenuBar usuario={usuario} />
            <div className="content">
                <div className="content align-center">
                </div>
                <div className="pure-u-5-5" style={{ marginTop: '15px' }}>
                    <Panel header="Eventos Recebidos">
                        <BreadCrumb home="principal" model={items} />

                        <div className="pure-u-1 content-section implementation" style={{ marginTop: '15px' }}>
                            <Panel header="Filtro">
                                <br />

                                <FiltroEventosEnviados
                                    filtroPesquisa={filtroPesquisa}
                                    grupos={grupos}
                                    eventos={eventos}
                                    grupo={grupo}
                                    evento={evento}
                                    ano={ano}
                                    mes={mes}
                                    indApuracao={indApuracao}
                                    isPeriodico={false}
                                    onChangeEvento={(evento) => handleEvento(evento)}
                                    onChangeGrupo={(grupo) => handleGrupo(grupo)}
                                    onChangeAno={(ano) => handleAno(ano)}
                                    onChangeMes={(mes) => handleMes(mes)}
                                    onChangeIndApuracao={(indApuracao) => handleIndApuracao(indApuracao)}
                                    onClick={() => handleClick()}
                                    buttonDisabled={buttonDisabled}
                                />
                            </Panel>
                            <br />
                            <ProgressBar mode="indeterminate" style={{ height: '6px', display: progresBarVisible }} />
                            <br />
                            {isCsvVisible ? <BtnExportCsv data={registrosRel} header={headerCsv} fileName={geraNomeRelatorio()} /> : ''}
                            <TblEventosRecebidos eventos={registrosRel}/>
                        </div>
                    </Panel>
                </div>

            </div>

            <div className="footer l-box is-center">
                {/* <PermissionCheck usuario={this.state.usuario}  /> */}
            </div>
        </div>
    )
}

export default RelEventosRecebidos;