import $ from 'jquery';
import Utils from '../utils/Util';

const grupoService = {

    carregarDropdownGrupoEvento(callback) {
        const url = "/EsocialAPI/grupo/combo/";
        $.ajax({
            url: url,
            dataType: 'json',
            error: function (e) {
                console.log(e);
            },
            success: function (resposta) {
                callback(resposta);
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); },
            error: Utils.handleAjaxError
        });
    },

    listarGrupo(callback) {
        $.ajax({
            url: "/EsocialAPI/api/grupo/listar",
            dataType: 'json',
            error: Utils.handleAjaxError,
            success: function (resposta) {
                callback(resposta);
            },
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

}

export default grupoService;