import React, {Component} from 'react';
import $ from 'jquery';
import {Calendar} from 'primereact/calendar';
import {Button} from 'primereact/button';
import {ProgressBar} from 'primereact/progressbar';

export class FormEvtPeriodico extends Component{
  constructor(){
    super();

    this.state={lista : [],eventos:[],usuario:{},xmljson:"",visiblePreview:false,sendPreview:false,idEvento:0,importados:0,aimportar:0,anoMesApur:null,hash:null}
  }
  componentWillReceiveProps(props) {
    this.setState({idEvento: props.idEvento});
  }
  componentWillMount(){
    //this.atualizaGrid();
  }
  importarEventos(){
    $.ajax({
      url:"/EsocialAPI/api/importacao/periodico/",
      method:'POST',
      data: JSON.stringify ({idEvento : this.props.idEvento, mesAnoApuracao : this.state.anoMesApur }),
      contentType: "application/json",
      dataType: 'json',
      success:function(resposta){
          console.log(resposta);
        this.setState({aimportar:resposta.registros,hash:resposta.hash});
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  displayValueTemplate(value) {
    return (
        <React.Fragment>
            {value}/<b>{this.state.aimportar}</b>
        </React.Fragment>
    ).bind(this);
  }
  handleClick(){
    this.importarEventos();
  }
  render(){
    return(
        <div>
            <h5>Favor Inserir o período de apuração</h5>
            <br />
            <label for="cldApuracao">Ano/Mes apuração:</label>
            <Calendar value={this.state.anoMesApur} onChange={(e) => this.setState({anoMesApur: e.value})} view="month" dateFormat="mm/yy" yearNavigator={true} yearRange="2010:2030"/>
            <br />
            <Button label="Importar" icon="pi pi-check" iconPos="left" onClick={this.handleClick.bind(this)} id="cldApuracao"/>
            <br />
            <div id={this.props.idEvento+'_pgbar'}>
                <ProgressBar value={this.state.importados} displayValueTemplate={this.props.displayValueTemplate}></ProgressBar>
            </div>
        </div>
    );
  }
}