import React, {Component} from 'react';
import $ from 'jquery';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Steps} from 'primereact/steps';
import {Card} from 'primereact/card';
import {Messages} from 'primereact/messages';


export class Submit extends Component{
  constructor(){
    super();
    this.state={lista : [],eventos:[],usuario:{},xmljson:"",visiblePreview:false
                ,sendPreview:false
                ,idEventoFila:0
                ,statusIndex:0
                ,eventoFila:{}
                ,evento:""
                ,codEvento:""
                ,interval:null
                ,mensagem:""
                ,anterior:"",
                repeticao:0,
                erro:false
            }
  }
  componentWillReceiveProps(props) {
    this.setState({idEventoFila: props.idEventoFila});
  }
  componentWillUnmount(){
    clearInterval(this.state.interval);
  }
  componentDidMount(){
    this.atualizaDialog();
    var intervalo = setInterval(() => {
      this.atualizaDialog();
      let repeticao = this.state.repeticao + 1;
      console.log(repeticao);
      this.setState({repeticao:repeticao});
      if(repeticao===5){
        clearInterval(this.state.interval);
        const msg = 'Por favor, consulte em '+window.location.hostname+'/EventoFila/'+this.state.idEventoFila;
        this.setState({erro: true});
        this.messages.show({sticky: true, severity: 'warn', summary: 'Erro ao Consultar', detail: 'Desculpe, houve um erro ao consultar o retorno do evento. '+msg});
      }
    }, 2000);
    this.setState({interval:intervalo}); 
  }
  atualizaStatus(status){
      let tipoAlerta="info";
      let label="";
    if(status==="PENDENTE"){
        this.setState({statusIndex:0,mensagem:"Preparando para enviar o evento!"});
        label="PENDENTE";
    }
    if(status==="ENVIANDO"){
        label="Enviando";
        this.setState({statusIndex:1,mensagem:"Enviando evento!"});
    }
    if(status==="AGUARDANDO_RETORNO"){
        label="Aguardando Retorno";
        this.setState({statusIndex:2,mensagem:"Aguardando Retorno"});
    }
    if(status==="REJEITADO"){
        label="Rejeitado";
        this.setState({statusIndex:3,mensagem:"Desculpe o evento foi rejeitado. Favor verifique as ocorrências"});
        clearInterval(this.state.interval);
        $("#loading").css("display","none");
        tipoAlerta="error";
    }
    if(status==="PROCESSADO"){
        label="Processado";
        clearInterval(this.state.interval);
        this.setState({mensagem:"Evento enviado com sucesso!",statusIndex:3});
        $("#loading").css("display","none");
        tipoAlerta="success";
    }
    if(this.state.anterior!==status){
      this.setState({anterior:status});
      this.messages.show({severity:tipoAlerta, detail:this.state.mensagem, summary: label});
    }
  }
  atualizaDialog(){   
      console.log(this.props.idEventoFila);
      this.setState({idEventoFila: this.props.idEventoFila});
    $.ajax({
        url:"/EsocialAPI/api/processamento/visualizar/eventofila/"+this.props.idEventoFila,
        type:'GET',
        error:function(error){
            console.log(error);
        },
        success:function(retorno){
            console.log(retorno);
          this.setState({eventoFila : retorno, codEvento: retorno.evento.codeventomensageria, evento:retorno.evento.nomeEvento});

          this.atualizaStatus(retorno.enviado);
        }.bind(this),
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      });
  }
  showProgressSpinner(erro){
    if(erro===false){
      return <ProgressSpinner id="loading" style={{width:'24px',heigth:'24px'}} animationDuration="3s" />
    }
  }
  render(){
    const items = [{
        label: 'Enviando',
        command: (event) => {
            this.messages.show({severity:'info', detail:this.state.mensagem, summary: event.item.label});
        }
    },
    {
        label: 'Evento Recebido',
        command: (event) => {
            this.messages.show({severity:'info', detail:this.state.mensagem, summary: event.item.label});
        }
    },
    {
        label: 'Consultando Retorno',
        command: (event) => {
            this.messages.show({severity:'info', summary:  event.item.label, detail:this.state.mensagem});
        }
    },
    {
        label: 'Evento Enviado',
        command: (event) => {
            this.messages.show({severity:'info', detail:this.state.mensagem, summary: event.item.label});
        }
    }
  ];
    return(
        <div>
        <Messages ref={(el) => this.messages = el}></Messages>
         <Card title={"S"+this.state.codEvento} subTitle={this.state.evento}>
          <Steps model={items} activeIndex={this.state.statusIndex} onSelect={(e) => this.setState({statusIndex: e.index})} readOnly={true} />
          {this.showProgressSpinner(this.state.erro)}
         </Card>
        </div>
    );
  }
}