import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import {Panel} from 'primereact/panel';
import {BreadCrumb} from 'primereact/breadcrumb';
import{ MenuBar } from '../componentes/MenuBar'
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Password} from 'primereact/password';
import {InputMask} from 'primereact/inputmask';
import {InputText} from 'primereact/inputtext';
import {Messages} from 'primereact/messages';
import usuarioService from '../services/usuarioService';

export class UserConfig extends Component{
  constructor(){
    super();
    this.state={
      home :{icon: 'pi pi-home'},
      agendamentos:[],
      tipoEvento:"1",
      usuario:{},
      sms:false,
      whatsApp:false,
      telegram:false,
      grupo:{},
      newPassword:'', 
      retypePassword:'', 
      telefone:'',
      email:''
    };

    this.editarUsuario = this.editarUsuario.bind(this);
  }
  componentDidMount(){
    $.ajax({
      url:"/EsocialAPI/usuario/logado/",
      dataType: 'json',
      success:function(resposta){
        console.log('resposta', JSON.stringify(resposta));
        this.setState({usuario:resposta,
                      email:resposta.email, 
                      sms:resposta.recebeSms,
                      whatsApp:resposta.whatsapp,
                      telegram:resposta.telegram,
                      grupo:resposta.idGrupo,
                      telefone:resposta.telefone, 
                    });
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
    this.consultaUsuario();
  }
  editarUsuario() {

    if (this.state.newPassword != null && this.state.newPassword !== undefined && this.state.newPassword !== '') {
      if (this.state.newPassword !== this.state.retypePassword) {
          this.messages.show({
              severity: 'error',
              summary: 'As senhas não coincidem!',
              detail: 'Por favor, verifique e tente novamente.'
          });
          return; // Interrompe o fluxo se as senhas não coincidirem
      }

      var usuario = {
        idusuario: this.state.usuario.id, 
        nome: this.state.usuario.nome, 
        login: this.state.usuario.login, 
        idgrupo: this.state.usuario.idGrupo, 
        email: this.state.email, 
        password: this.state.newPassword, 
        recebeSms: this.state.sms, 
        whatsapp: this.state.whatsApp, 
        telegram: this.state.telegram,
        recebeEmail: this.state.usuario.recebeEmail,
        datacadastro:this.state.usuario.dataCadastro,
        dataExpiracao:this.state.usuario.dataExpiracao,
        telefone: this.state.telefone

      };
      console.log(usuario)
  
        var dados = JSON.stringify(usuario);
        $.ajax({
          url: "/EsocialAPI/usuario/atualiza",
          type: "POST",
          contentType: "application/json; charset=utf-8",
          dataType: 'json',
          data: dados,
          error: function (erro) {
          },
          success: function (resposta) {
            this.messages.show(resposta);
           console.log('Atualizou')
          }.bind(this),
          beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }else{
        this.messages.show({ severity: 'error', summary: 'Senha é obrigatório! ', detail: ' Por favor, preencha.' });
    }

  }

  limparSenhas = () => {
    this.setState({
      newPassword: '',
      retypePassword: ''
  });
  }

  consultaUsuario(){
    usuarioService.consultaUsuario((usuario) => {
      this.setState({ usuario });
    });
  }
  render() {
    const items = [
        { label: 'Home', icon: 'pi pi-home', url: '/home' },
        { label: 'Configurações', icon: 'pi pi-home', url: '/user/config' }
    ];

    const footer = (
        <div style={{ display: 'flex', justifyContent: 'space-evenly'}}>
            <Button 
                label="Salvar" 
                onClick={this.editarUsuario} 
                icon="pi pi-check" 
            />
            <Button 
                label="Cancelar" 
                icon="pi pi-times" 
                onClick={this.limparSenhas} 
                className="p-button-secondary" 
            />
        </div>
    );

    return (
        <div>
            <MenuBar usuario={this.state.usuario} />
            <div className="content">
                <div className="pure-u-5-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', padding: '1px 0' }}>
                    <Panel header="Configurações">
                        <Messages ref={(el) => this.messages = el} />
                        <BreadCrumb home="principal" model={items} />
                        <br />
                        <Card title={`Dados do usuário - ${this.state.usuario.nome}`} footer={footer} style={{ width: '70vh' }}>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>

                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                                  <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Login:</label>
                                  <label style={{ flexGrow: 1 }}>{this.state.usuario.login}</label>
                              </div>

                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                  <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Email:</label>
                                  <InputText style={{ flexGrow: 1 }} value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                              </div>

                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                  <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Digite a nova senha:</label>
                                  <Password style={{ flexGrow: 1 }} value={this.state.newPassword} onChange={(e) => this.setState({ newPassword: e.target.value })} promptLabel="Digite uma senha" weakLabel="Senha fraca" mediumLabel="Boa senha" strongLabel="Senha forte" toggleMask/>
                              </div>

                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                  <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Repita a nova senha:</label>
                                  <Password style={{ flex: 1 }} value={this.state.retypePassword} onChange={(e) => this.setState({ retypePassword: e.target.value })} promptLabel="Digite uma senha" weakLabel="Senha fraca" mediumLabel="Boa senha" strongLabel="Senha forte" toggleMask/>
                              </div>

                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                  <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Telefone:</label>
                                  <InputMask mask="(99) 99999-9999" style={{ flexGrow: 1 }} value={this.state.telefone} onChange={(e) => this.setState({ telefone: e.value })} />
                              </div>

                          </div>
                      </Card>
                    </Panel>
                </div>
            </div>
            <div className="footer l-box is-center">
                {/* <PermissionCheck usuario={this.state.usuario} /> */}
            </div>
        </div>
    );
  }
}
