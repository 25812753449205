import React, {Component} from 'react';
import $ from 'jquery';
import AceEditor from "react-ace-builds";
import "ace-builds/src-noconflict/theme-eclipse";
import "ace-builds/webpack-resolver";
import beautify from 'xml-beautifier';
import {Messages} from 'primereact/messages';
import {ProgressBar} from 'primereact/progressbar';
import {Menubar} from 'primereact/menubar';



export class XmlEditor extends Component{
  constructor(){
    super();
    this.state={lista : [],height:'400px',helpDiv:'none',pgBar:'block',width:'*',alteracao:"",eventos:[],usuario:{},xmljson:"",visiblePreview:false,sendPreview:false,xml:"",idEventoFila:0,items:[
      {
        label: 'Reverter', 
        icon: 'pi pi-refresh', 
        command: (e) => {
            this.atualizaDialog();
        }
    },
    {   label: 'Salvar', 
        icon: 'pi pi-save', 
        command:(e) => {
          this.setState({pgBar:'block'});
          this.salvarEdicao();
        }
    }
    ]}
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.atualizaDialog =  this.atualizaDialog.bind(this);
  }
  componentWillReceiveProps(props) {
    this.setState({idEventoFila: props.idEventoFila});
  }
  componentDidMount(){
    console.log(this.props.idEventoFila);
    this.atualizaDialog(this.props.idEventoFila);
  }
  showSuccess() {
    this.messages.show({ severity: 'success', summary: 'Mensagem Salva. ', detail: ' Alteração realizada com sucesso!' });
    this.setState({pgBar:'none'});
  }
  showError() {
    this.messages.show({ severity: 'Erro', summary: 'Erro ', detail: 'Foi impossível salvar' });
  }
  salvarEdicao(){   
    $.ajax({
        url:"/EsocialAPI/api/processamento/eventofila/editar",
        type:'POST',
        contentType: "application/json; charset=utf-8",
        dataType: 'json',
        data:JSON.stringify({idEventoFila:this.props.idEventoFila, xmlEditado:this.state.alteracao}),
        error:function(error){

        },
        success:function(retorno){
          this.showSuccess();
        }.bind(this),
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      });
  }
  atualizaDialog(){   
    console.log(this.props.idEventoFila);
    $.ajax({
        //url:"/EsocialAPI/api/processamento/visualizar/"+this.props.idEventoFila,
        url: "/EsocialAPI/api/processamento/valida/" + this.props.idEventoFila,
        type:'GET',
        error:function(error){
            //alert(error);
        },
        success:function(retorno){
          console.log(retorno.xml);
            var parseString = require('xml2js').parseString;
            parseString(retorno, function (err, result) {
              if(result!==undefined){
                this.setState({xmljson:result});
              }else{
                this.setState({xmljson:{}});
              }
            }.bind(this))
            this.validaRetorno(retorno.xml);
            this.setState({pgBar:'none'});
        }.bind(this),
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      });
  }
  validaRetorno(xml){
    if(xml!==null){
      this.setState({xml:beautify(xml)});
    }else{
      this.setState({xml:'Xml Inválido'});
    }
  }
  onChange(texto){
    
  };
  onBlur(e, code){
    this.setState({alteracao:code.getValue(),xml:code.getValue()});
    
  }
  render(){
      const xml = this.state.xml;
      const items = [
        {
              label: 'Reverter', 
              icon: 'pi pi-refresh', 
              command: (e) => {
                  this.atualizaDialog();
              }
          },
          {   label: 'Salvar', 
              icon: 'pi pi-save', 
              command:(e) => {
                this.setState({pgBar:'block'});
                this.salvarEdicao();
              }
          },
          {   label: 'Ajuda', 
              icon: 'pi pi-question-circle', 
              command:(e) => {
                this.setState({helpDiv:'block'});
               }
          }
        
     ];
    return(
    <div>
      <Messages ref={(el) => this.messages = el}></Messages>

        <div style={{textAlign:'left'}}>
        {/* <Menubar model={items} /> */}
          <ProgressBar mode="indeterminate" style={{height: '6px',display:this.state.pgBar}} />
          <br />
          <div className="pure-u-3-4" style={{clear:'both'}}>
          <AceEditor
                  mode="xml"
                  theme="eclipse"
                  name="eventoXml"
                  onLoad={this.onLoad}
                  onChange={this.onChange}
                  onBlur={(e, code) => this.onBlur(e, code)}
                  width={this.state.width}
                  height={this.state.height}
                  fontSize={16}
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  value={xml}
                  setOptions={{
                  enableBasicAutocompletion: false,
                  enableLiveAutocompletion: false,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 3
                  }}/>
          </div>
          <div style={{float:'right',textAlign:'left',paddingLeft:'10px'}} className="pure-u-1-4">
          <div id="dvAjuda" style={{display:this.state.helpDiv}}>
            <em>
              <p>Em caso de Edição o evento as informações não estarão em conformidade com o Mentorh.<br />
              Para reverter as alterações clique em: <strong>Arquivo -{'>'} Reverter</strong><br />
              Para salvar as alterações clique em: <strong>Arquivo -{'>'} Salvar</strong>
              </p>
            </em>
          </div>
          </div>

        </div>
  </div>
    );
  }
}