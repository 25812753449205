import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Calendar} from 'primereact/calendar';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';

export class FiltroAfastamento extends Component{
  constructor(){
    super();
    this.state={menu:[],usuario:{},dataPeriodo:null,codAfastamento:null,pgrbar:'none',tipoAfastamento:null };
  }
  consultando(){
    console.log('cliquei');
    this.props.pgrbar("block");
  }
  componentWillReceiveProps(props) {
    this.setState({idEventoFila: props.pgrbar});
  }
  render() {
    const pt = {
        firstDayOfWeek: 1,
        dayNames: ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"],
        dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
        dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        monthNames: ["Janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"],
        monthNamesShort: ["ja", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"]
    };

    return(
      <div className="pure-g" style={{paddingTop:'15px'}}>

        <div className="pure-u-1-4">
          <Dropdown
              value={this.props.tipoAfastamento} 
              options={this.props.tiposAfastamento}
              onChange={(e)=>{this.props.onChangeTipoAfastamento(e.value)}} 
              placeholder={'Selecione o tipo'}
          />
        </div>

        <div className="pure-u-1-4" style={{display:'inline-block'}}>
          <span className="p-float-label">
              <InputText id="codAfastamento" type="text" keyfilter="pint" value={this.props.codAfastamento} onChange={(e) => this.props.onChangeCodAfastamento(e.target.value)} />
              <label htmlFor="codAfastamento">CodAfastamento:</label>
          </span>  
        </div>

        <div className="pure-u-1-4">
          <div >
          <span className="p-float-label" style={{display:'inline-block'}}>
                <Calendar 
                  dateFormat="dd/mm/yy" 
                  id="prdAfastamento" 
                  value={this.props.periodoAfastamento} 
                  onChange={(e) => this.props.onChangePeriodoAfastamento(e.value)} 
                  selectionMode="range" 
                  style={{display:'inline-block'}}
                />
                <label htmlFor="prdAfastamento">Período do afastamento</label>
            </span>
            
                          {/* <div style={{display: 'block'}}>
                              <RadioButton inputId="rb1" name="tipoAfastamento" value="Afastamento" onChange={(e) => this.props.onChangeTipoAfastamento(e.value)} checked={this.state.tipoAfastamento === 'Afastamento'} />
                              <label htmlFor="rb1" className="p-radiobutton-label">Afastamento</label>
                          </div>
                          <div style={{display: 'block'}}>
                              <RadioButton inputId="rb2" name="tipoAfastamento" value="Férias" onChange={(e) => this.props.onChangeTipoAfastamento(e.value)} checked={this.state.tipoAfastamento === 'Férias'} />
                              <label htmlFor="rb2" className="p-radiobutton-label">Férias</label>
                          </div>
                          <div style={{display: 'block'}}>
                              <RadioButton inputId="rb3" name="tipoAfastamento" value="Licença Prêmio" onChange={(e) => this.props.onChangeTipoAfastamento(e.value)} checked={this.state.tipoAfastamento === 'Licença Prêmio'} />
                              <label htmlFor="rb3" className="p-radiobutton-label">Licença Prêmio</label>
                          </div>
                          <div style={{display: 'block'}}>
                              <RadioButton inputId="rb4" name="tipoAfastamento" value="Cessão" onChange={(e) => this.props.onChangeTipoAfastamento(e.value)} checked={this.state.tipoAfastamento === 'Cessão'} />
                              <label htmlFor="rb4" className="p-radiobutton-label">Cessão</label>
                          </div> */}
                      </div>
        </div>

        <div className="pure-u-1-4">
            <Button label="Consultar" icon="pi pi-search" iconPos="left" onClick={(e)=>this.props.onClick()} />
        </div>
      </div>
    );
  }
}