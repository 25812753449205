import React,{Component} from 'react';
import 'primereact/resources/themes/saga-green/theme.css';
import $ from 'jquery';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Messages } from 'primereact/messages';


export class EventosVinculados extends Component {
  constructor(){
    super();
    this.state={
        visible: false
        ,eventos : null
        ,erro:{codErro:0,descErro:'Erro Inesperado.',detalhes:'',exibeErro:'none', loadErro:'block'} 
    };
  }
  componentDidMount(){
    this.consultaEventosVinculados();
  }
  consultaEventosVinculados(){
    var baseurl = window.location.protocol + "//" + window.location.host;
    $.ajax({
      url:"/EsocialAPI/eventoFila/filhos/"+this.props.idEventoFila,
      dataType: 'json',
      error:function(){
        //window.location.href= baseurl;
      },
      success:function(resposta){
          console.log(resposta);
        this.setState({eventos:resposta});
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  actionTemplate(rowData){
      try{
        let detalhe = JSON.parse(rowData.objDetalhe);
        return detalhe.nome;
      }catch(error){
        console.log(error);
      }

  }
  render() {
    return(
        <div>
            <div className="card">
                <DataTable value={this.state.eventos} header="Eventos de Alteração/Exclusão" className="p-datatable-sm" emptyMessage="Nenhum evento de Alteração/Exclusão encontrado">
                    <Column field="id" header="Id" style={{width:'5em'}}></Column>
                    <Column field="natureza" header="Ação" style={{textAlign:'center'}}/>
                    <Column field="origem" header="Origem" style={{textAlign:'center'}} />
                    <Column body={this.actionTemplate} header="Descricao" style={{textAlign:'center'}} />
                </DataTable>
            </div>
        </div>
    );
  }
}
