import { Button } from 'primereact/button';
import React, { Component, useEffect, useRef } from 'react';
import { CSVLink } from "react-csv";


const BtnExportCsv = (props) =>{

    const csvLink = useRef();

    const formatValue = (value) => {
        if (typeof value === 'number') {
          return value.toString().replace('.', ',');
        }
        return value;
      }
      

    const data = props.data.map(item => ({
        ...item,
        vrCpSeg: formatValue(item.vrCpSeg),
        vrDescSeg: formatValue(item.vrDescSeg),
        valor: formatValue(item.valor),
        remFGTS: formatValue(item.remFGTS),
        dpsFGTS: formatValue(item.dpsFGTS),
        vrDescCP: formatValue(item.vrDescCP),
        vrBcCp00: formatValue(item.vrBcCp00),
        vrBcCp15: formatValue(item.vrBcCp15),
        vrBcCp20: formatValue(item.vrBcCp20),
        vrBcCp25: formatValue(item.vrBcCp25),
        vrSalMat: formatValue(item.vrSalMat),
        vrCR: formatValue(item.vrCR),
        vrSuspCR: formatValue(item.vrSuspCR),
        vrCRSusp: formatValue(item.vrCRSusp),
        vrCRMen: formatValue(item.vrCRMen),
        vlrCRMen: formatValue(item.vlrCRMen),
      }));

    const headers = props.header;
    
    const csvReport = {
        filename: props.fileName,
        headers: headers,
        data: data
    }

    function handleButtonClick(){
        csvLink.current.link.click();
    }

    return (
        <div>
            <Button onClick={handleButtonClick} label='CSV' icon="pi pi-file-excel" className="p-button-sm p-button-info" style={{marginBottom: '10px', marginRight: '10px'}}>
                <CSVLink target='_blank' ref={csvLink} className="p-button-sm" style={{visibility:'hidden' }} {...csvReport}>CSV</CSVLink>
            </Button>
        </div>
    )
}

export default BtnExportCsv