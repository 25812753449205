import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Messages } from 'primereact/messages';
import { TblFolhaGeral } from '../sessoes/FolhaPagamento/TblFolhaGeral';
import { MenuBar } from '../componentes/MenuBar';
import usuarioService from '../services/usuarioService';

export class FolhaPagamento extends Component {
  constructor() {
    super();
    this.state = { home: { icon: 'pi pi-home' }, agendamentos: [], tipoEvento: "1", usuario: {} };
  }
  componentDidMount() {
    this.consultaUsuario();
  }
  consultaUsuario() {
    usuarioService.consultaUsuario((usuario) => {
      this.setState({ usuario });
    });
  }
  render() {
    return (
      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content">
          <div className="content align-center">
            <Messages ref={(el) => this.messages = el}></Messages>
            <div className="pure-u-5-5">
              <TblFolhaGeral />
            </div>
          </div>

        </div>
{/*         <div className="footer l-box is-center">
          <PermissionCheck usuario={this.state.usuario} />
        </div> */}

      </div>
    );
  }
}
