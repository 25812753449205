import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {DataTable} from 'primereact/datatable';
import {TabView,TabPanel} from 'primereact/tabview';
export class CertificadosViewer extends Component{
  constructor(){
    super();
    this.state={lista : [],visible:false,selecionado:{}};
  }
  itemTemplate(cert, layout) {
    if (layout === 'list') {
        return (
            <div className="p-grid">
                <div>{cert.arquivo}</div>
            </div>
        );
    }
    if (layout === 'grid') {
        return (
            <div className="p-col-12 p-md-3">
                <div>{cert.arquivo}</div>
            </div>
        );
    }
}
rowClassName(rowData) {
  let ativo = rowData.ativo;
  
  return {'font-weight:bold' : (ativo === true)};
}
displaySelection(data) {
    if(!data || data.length === 0) {
        return <div style={{textAlign: 'left'}}>No Selection</div>;
    }
    else {
        if(data instanceof Array)
            return <ul style={{textAlign: 'left', margin: 0}}>{data.map((cert,i) => <li key={cert.nome}>{cert.nome + ' - ' + cert.year + ' - ' + cert.brand + ' - ' + cert.color}</li>)}</ul>;
        else
            return <div style={{textAlign: 'left'}}>Certificado Secionado: {data.nome} +{new Date(data.dataEmissao).getDate() + ' - ' + new Date(data.dataExpiro) + ' - ' + data.arquivo}</div>
    }
}
  componentWillMount(){

    $.ajax({
      url:"/EsocialAPI/api/certificado/lista",
      dataType: 'json',
      success:function(resposta){
        this.setState({lista:resposta.certificados});
        //certs.forEach(this.selecionaDefault);
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });

  }
  render() {
    return(
      <div>
        <div className="content-section introduction">
                            <div className="feature-intro">
                              <br />
                                <h4>Certificados </h4>
                                <em>Certificados localizados na pasta de configuração:</em>
                            </div>
                            <div>
                                <Button label="Novo" icon="pi pi-user-plus" iconPos="left" style={{margin: '3px'}} onClick={(e) => this.setState({visible: true})} />
                            </div>
                            <Dialog header="Novo Certificado" visible={this.state.visible} style={{width: '50vw'}} modal={true} onHide={(e) => this.setState({visible: false})}>
                              <div className="pure-g">
                              <div className="pure-u-1-3">
                                  teste left
                                </div>
                                <div className="pure-u-1-3">
                                  teste middle
                                </div>
                                <div className="pure-u-1-3">
                                  teste right
                                </div>
                              </div>
                            </Dialog>
                        </div>
                        <div className="content-section implementation">
                        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                        <TabPanel header="Certificados Carregados">

                        </TabPanel>
                        <TabPanel header="Arquivos Locais certificação digital.">
                            <DataTable value={this.state.lista} selectionMode="single" header="Certificados" footer={this.displaySelection(this.state.selecionado)}
                                selection={this.state.selecionado} rowClassName={this.rowClassName} onSelectionChange={e => this.setState({selecionado: e.value})}>
                                <Column field="nome" header="Empresa Certificado" />
                                <Column field="arquivo" header="Localização" />
                                <Column field="extensão" header="Extensão" />
                                <Column field="ativo" header="Ativo" />
                            </DataTable>
                        </TabPanel>
                        </TabView>
                        </div>
      </div>
    );
  }
}
