
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect, useRef } from 'react';
import BtnExportCsv from '../../../../componentes/BtnExportCsv';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from 'primereact/badge';
import { Tooltip } from 'primereact/tooltip';
import Utils from '../../../../utils/Util';

const TblEvtCS = (props) => {

  const [infoCPSegDTOs, setInfoCPSegDTOs] = useState([])
  const [basesRemunDTOs, setBasesRemunDTOs] = useState([])
  const [infoCREstabDTOs, setInfoCREstabDTOs] = useState([])
  const [infoCRContribDTOs, setInfoCRContribDTOs] = useState([])
  const [first2, setFirst2] = useState(0);
  const [rows2, setRows2] = useState(100);

  useEffect(() => {
    setInfoCPSegDTOs(props.relatorios.infoCPSegDTOs);
    setBasesRemunDTOs(props.relatorios.basesRemunDTOs);
    setInfoCREstabDTOs(props.relatorios.infoCREstabDTOs);
    setInfoCRContribDTOs(props.relatorios.infoCRContribDTOs);
  }, [props])


  const [headerCsvInfoCPSegDTOs, setHeaderCsvInfoCPSegDTOs] = useState([
    { label: 'vrDescCP', key: 'vrDescCP' },
    { label: 'vrCpSeg', key: 'vrCpSeg' }
  ]);

  const [headerCsvBasesRemunDTOs, setHeaderCsvBasesRemunDTOs] = useState([
    { label: 'codCateg', key: 'codCateg' },
    { label: 'vrBcCp00', key: 'vrBcCp00' },
    { label: 'vrBcCp15', key: 'vrBcCp15' },
    { label: 'vrBcCp20', key: 'vrBcCp20' },
    { label: 'vrBcCp25', key: 'vrBcCp25' },
    { label: 'vrSalMat', key: 'vrSalMat' }
  ]);

  const [headerCsvInfoCREstabDTOs, setHeaderCsvInfoCREstabDTOs] = useState([
    { label: 'tpCR', key: 'tpCR' },
    { label: 'vrCR', key: 'vrCR' },
    { label: 'vrSuspCR', key: 'vrSuspCR' }
  ]);

  const [headerCsvInfoCRContribDTOs, setHeaderCsvInfoCRContribDTOs] = useState([
    { label: 'tpCR', key: 'tpCR' },
    { label: 'vrCR', key: 'vrCR' },
    { label: 'vrSuspCR', key: 'vrSuspCR' }
  ]);

  function geraNomeRelatorio(nome) {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const nomeRelatorio = nome+ '_' + today.toISOString() + '.csv';
    return nomeRelatorio;
  }



  function onPage(event, values, setter) {
    console.log(event)
    setFirst2(event.first)
    const endIndex = event.first + event.rows;
    const newArray = [];
    for (let i = 0; i < values.length; i++) {
      if (i >= event.first && i < endIndex) {
        newArray.push(values[i])
      }
    }
    setter(newArray);
  }

  function onChangePaginator(event, options, values, setter) {
    console.log(event);
    console.log(options.props);
    options.currentPage = event.value
    const startIndex = event.value * options.rows;
    const endIndex = startIndex + options.rows
    setFirst2(startIndex);

    const newArray = [];
    for (let i = 0; i < values.length; i++) {
      if (i >= startIndex && i < endIndex) {
        newArray.push(values[i])
      }
    }
    setter(newArray);
  }
  const templatePaginatorInfoCPSeg = {
    layout: 'CurrentPageReport PrevPageLink NextPageLink',
    'CurrentPageReport': (options) => {
      const totalReg = options.totalRecords === null ? 0 : options.totalRecords
      const dropdownOptions = [];
      const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
      for (let i = 0; i <= totalPages; i++) {
        const item = {
          label: i + 1,
          value: i
        };

        dropdownOptions.push(item);
      }

      return (
        <>
          <div className="p-grid">
            <div className="p-col">
              <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square ' />
              <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => onChangePaginator(e, options, props.relatorios.infoCPSegDTOs, setInfoCPSegDTOs)} />
            </div>
          </div>
        </>
      )
    }
  };

  const templatePaginatorBasesRemun = {
    layout: 'CurrentPageReport PrevPageLink NextPageLink',
    'CurrentPageReport': (options) => {
      const totalReg = options.totalRecords === null ? 0 : options.totalRecords
      const dropdownOptions = [];
      const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
      for (let i = 0; i <= totalPages; i++) {
        const item = {
          label: i + 1,
          value: i
        };

        dropdownOptions.push(item);
      }

      return (
        <>
          <div className="p-grid">
            <div className="p-col">
              <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square ' />
              <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => onChangePaginator(e, options, props.relatorios.basesRemunDTOs, setBasesRemunDTOs)} />
            </div>
          </div>
        </>
      )
    }
  };

  const templatePaginatorInfoCREstab = {
    layout: 'CurrentPageReport PrevPageLink NextPageLink',
    'CurrentPageReport': (options) => {
      const totalReg = options.totalRecords === null ? 0 : options.totalRecords
      const dropdownOptions = [];
      const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
      for (let i = 0; i <= totalPages; i++) {
        const item = {
          label: i + 1,
          value: i
        };

        dropdownOptions.push(item);
      }

      return (
        <>
          <div className="p-grid">
            <div className="p-col">
              <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square ' />
              <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => onChangePaginator(e, options, props.relatorios.infoCREstabDTOs, setInfoCREstabDTOs)} />
            </div>
          </div>
        </>
      )
    }
  };

  const templatePaginatorInfoCRContrib = {
    layout: 'CurrentPageReport PrevPageLink NextPageLink',
    'CurrentPageReport': (options) => {
      const totalReg = options.totalRecords === null ? 0 : options.totalRecords
      const dropdownOptions = [];
      const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
      for (let i = 0; i <= totalPages; i++) {
        const item = {
          label: i + 1,
          value: i
        };

        dropdownOptions.push(item);
      }

      return (
        <>
          <div className="p-grid">
            <div className="p-col">
              <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square ' />
              <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => onChangePaginator(e, options, props.relatorios.infoCRContribDTOs, setInfoCRContribDTOs)} />
            </div>
          </div>
        </>
      )
    }
  };

    return (
        <div>
            <TabView>
                <TabPanel header="infoCPSeg">
                    <h3>Informações de contribuição previdenciária do segurado</h3>
                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                        Informações de contribuição previdenciária do segurado.
                    </p>
                    <div className="content-section implementation">
                        {props.relatorios.infoCPSegDTOs.length > 0 ? <BtnExportCsv data={props.relatorios.infoCPSegDTOs} header={headerCsvInfoCPSegDTOs} fileName={geraNomeRelatorio('5011_infoCPSeg_' + props.mesPerApur + '_' + props.anoPerApur + '_' + props.indApuracao)} /> : ''}
                        <Tooltip target=".vrDescCP" />
                        <Tooltip target=".vrCpSeg" />
                        <DataTable
                            value={infoCPSegDTOs}
                            emptyMessage="Nenhum registro encontrado."
                            lazy={true}
                            rows={50}
                            loading={false}
                            totalRecords={props.relatorios.infoCPSegDTOs.length}
                            className="p-datatable-sm"
                            paginator={true}
                            paginatorTemplate={templatePaginatorInfoCPSeg}
                            first={first2}       
                            onPage={(e)=>onPage(e, props.relatorios.infoCPSegDTOs, setInfoCPSegDTOs)}
                            footer={
                              <>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <div style={{ textAlign: 'right', marginLeft: 'auto', paddingRight: '10px' }}>
                                    Total vrDescCP
                                    <br />
                                    {Utils.formatValue(Utils.calculateSum(infoCPSegDTOs, 'vrDescCP'))}
                                  </div>
                                  <div style={{ textAlign: 'right' }}>
                                    Total vrCpSeg
                                    <br />
                                    {Utils.formatValue(Utils.calculateSum(infoCPSegDTOs, 'vrCpSeg'))}
                                  </div>
                                </div>
                              </>
                            }
                        >
                            <Column field="vrDescCP" style={{ width: '1px' }} body={(rowData) => Utils.formatValue(rowData.vrDescCP)} header={<>vrDescCP <i className="vrDescCP pi pi-info-circle" data-pr-tooltip="Valor total da contribuição descontada dos segurados."/> </>}/>
                            <Column field="vrCpSeg" style={{ width: '0.5em' }} body={(rowData) => Utils.formatValue(rowData.vrCpSeg)} header={<>vrCpSeg <i className="vrCpSeg pi pi-info-circle" data-pr-tooltip="Valor total calculado relativo à contribuição dos segurados."/> </>}/>
                        </DataTable>
                    </div>
                </TabPanel>
                <TabPanel header="basesRemun">
                    <h3>Bases de cálculo por categoria</h3>
                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                        Bases de cálculo da contribuição previdenciária incidente sobre remunerações, por categoria.
                    </p>
                    <div className="content-section implementation">
                        {props.relatorios.basesRemunDTOs.length > 0 ? <BtnExportCsv data={props.relatorios.basesRemunDTOs} header={headerCsvBasesRemunDTOs} fileName={geraNomeRelatorio('5011_basesRemun_' + props.mesPerApur + '_' + props.anoPerApur + '_' + props.indApuracao)} /> : ''}
                        <Tooltip target=".codCateg" />
                        <Tooltip target=".vrBcCp00" />
                        <Tooltip target=".vrBcCp15" />
                        <Tooltip target=".vrBcCp20" />
                        <Tooltip target=".vrBcCp25" />
                        <Tooltip target=".vrSalMat" />
                        <DataTable
                            value={basesRemunDTOs}
                            emptyMessage="Nenhum registro encontrado."
                            lazy={true}
                            rows={50}
                            loading={false}
                            totalRecords={props.relatorios.basesRemunDTOs.length}
                            className="p-datatable-sm"
                            paginator={true}
                            paginatorTemplate={templatePaginatorBasesRemun}
                            first={first2}       
                            onPage={(e)=>onPage(e, props.relatorios.basesRemunDTOs, setBasesRemunDTOs)}
                            footer={
                              <>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <div style={{ textAlign: 'right', marginLeft: 'auto', paddingRight: '10px' }}>
                                    Total vrBcCp00
                                    <br />
                                    {Utils.formatValue(Utils.calculateSum(basesRemunDTOs, 'vrBcCp00'))}
                                  </div>
                                  <div style={{ textAlign: 'right' }}>
                                    Total vrBcCp15
                                    <br />
                                    {Utils.formatValue(Utils.calculateSum(basesRemunDTOs, 'vrBcCp15'))}
                                  </div>
                                  <div style={{ textAlign: 'right' }}>
                                    Total vrBcCp20
                                    <br />
                                    {Utils.formatValue(Utils.calculateSum(basesRemunDTOs, 'vrBcCp20'))}
                                  </div>
                                  <div style={{ textAlign: 'right' }}>
                                    Total vrBcCp25
                                    <br />
                                    {Utils.formatValue(Utils.calculateSum(basesRemunDTOs, 'vrBcCp25'))}
                                  </div>
                                  <div style={{ textAlign: 'right' }}>
                                    Total vrSalMat
                                    <br />
                                    {Utils.formatValue(Utils.calculateSum(basesRemunDTOs, 'vrSalMat'))}
                                  </div>
                                </div>
                              </>
                            }
                        >
                            <Column field="codCateg" style={{ width: '1px' }} header={<>codCateg <i className="codCateg pi pi-info-circle" data-pr-tooltip="Preencher com o código da categoria do trabalhador, conforme definido em S-5001."/> </>}/>
                            <Column field="vrBcCp00" style={{ width: '0.5em' }} body={(rowData) => Utils.formatValue(rowData.vrBcCp00)} header={<>vrBcCp00 <i className="vrBcCp00 pi pi-info-circle" data-pr-tooltip="Preencher com a base de cálculo da contribuição previdenciária sobre a remuneração."/> </>}/>
                            <Column field='vrBcCp15' style={{ width: '0.5em' }} body={(rowData) => Utils.formatValue(rowData.vrBcCp15)} header={<>vrBcCp15 <i className="vrBcCp15 pi pi-info-circle" data-pr-tooltip="Preencher com a base de cálculo da contribuição adicional para o financiamento dos benefícios de aposentadoria especial após 15 anos de contribuição."/> </>}/>
                            <Column field='vrBcCp20' style={{ width: '0.5em' }} body={(rowData) => Utils.formatValue(rowData.vrBcCp20)} header={<>vrBcCp20 <i className="vrBcCp20 pi pi-info-circle" data-pr-tooltip="Preencher com a base de cálculo da contribuição adicional para o financiamento dos benefícios de aposentadoria especial após 20 anos de contribuição."/> </>}/>
                            <Column field='vrBcCp25' style={{ width: '0.5em' }} body={(rowData) => Utils.formatValue(rowData.vrBcCp25)} header={<>vrBcCp25 <i className="vrBcCp25 pi pi-info-circle" data-pr-tooltip="Preencher com a base de cálculo da contribuição adicional para o financiamento dos benefícios de aposentadoria especial após 25 anos de contribuição."/> </>}/>
                            <Column field='vrSalMat' style={{ width: '0.5em' }} body={(rowData) => Utils.formatValue(rowData.vrSalMat)} header={<>vrSalMat <i className="vrSalMat pi pi-info-circle" data-pr-tooltip="Valor total do salário-maternidade para a categoria indicada."/> </>}/>
                        </DataTable>
                    </div>
                </TabPanel>
                <TabPanel header="infoCREstab">
                    <h3>Códigos de Receita por estabelecimento</h3>
                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                        Informações das contribuições sociais devidas à Previdência Social e Outras Entidades e Fundos, consolidadas por estabelecimento e por Código de Receita - CR.
                    </p>
                    <div className="content-section implementation">
                        {props.relatorios.infoCREstabDTOs.length > 0 ? <BtnExportCsv data={props.relatorios.infoCREstabDTOs} header={headerCsvInfoCREstabDTOs} fileName={geraNomeRelatorio('5011_infoCREstab_' + props.mesPerApur + '_' + props.anoPerApur + '_' + props.indApuracao)} /> : ''}
                        <Tooltip target=".tpCR" />
                        <Tooltip target=".vrCR" />
                        <Tooltip target=".vrSuspCR" />
                        <DataTable
                            value={infoCREstabDTOs}
                            emptyMessage="Nenhum registro encontrado."
                            lazy={true}
                            rows={50}
                            loading={false}
                            totalRecords={props.relatorios.infoCREstabDTOs.length}
                            className="p-datatable-sm"
                            paginator={true}
                            paginatorTemplate={templatePaginatorInfoCREstab}
                            first={first2}       
                            onPage={(e)=>onPage(e, props.relatorios.infoCREstabDTOs, setInfoCREstabDTOs)}
                            footer={
                              <>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <div style={{ textAlign: 'right', marginLeft: 'auto', paddingRight: '10px' }}>
                                    Total vrCR
                                    <br />
                                    {Utils.formatValue(Utils.calculateSum(infoCREstabDTOs, 'vrCR'))}
                                  </div>
                                  <div style={{ textAlign: 'right' }}>
                                    Total vrSuspCR
                                    <br />
                                    {Utils.formatValue(Utils.calculateSum(infoCREstabDTOs, 'vrSuspCR'))}
                                  </div>
                                </div>
                              </>
                            }
                        >
                            <Column field="tpCR" style={{ width: '1px' }} header={<>tpCR <i className="tpCR pi pi-info-circle" data-pr-tooltip="Código de Receita - CR relativo a contribuições sociais devidas à Previdência Social e a Outras Entidades e Fundos (Terceiros), conforme legislação em vigor na competência."/> </>}/>
                            <Column field="vrCR" style={{ width: '0.5em' }} body={(rowData) => Utils.formatValue(rowData.vrCR)} header={<>vrCR <i className="vrCR pi pi-info-circle" data-pr-tooltip="Valor correspondente ao CR apurado."/> </>}/>
                            <Column field='vrSuspCR' style={{ width: '0.5em' }} body={(rowData) => Utils.formatValue(rowData.vrSuspCR)} header={<>vrSuspCR <i className="vrSuspCR pi pi-info-circle" data-pr-tooltip="Valor suspenso correspondente ao CR apurado."/> </>}/>
                        </DataTable>
                    </div>
                </TabPanel>
                <TabPanel header="infoCRContrib">
                    <h3>Totalizador dos Códigos de Receita do contribuinte</h3>
                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                        Informações consolidadas das contribuições sociais devidas à Previdência Social e Outras Entidades e Fundos, por Código de Receita - CR.
                    </p>
                    <div className="content-section implementation">
                        {props.relatorios.infoCRContribDTOs.length > 0 ? <BtnExportCsv data={props.relatorios.infoCRContribDTOs} header={headerCsvInfoCRContribDTOs} fileName={geraNomeRelatorio('5011_infoCRContrib_' + props.mesPerApur + '_' + props.anoPerApur + '_' + props.indApuracao)} /> : ''}
                        <Tooltip target=".tpCR" />
                        <Tooltip target=".vrCR" />
                        <Tooltip target=".vrCRSusp" />
                        <DataTable
                            value={infoCRContribDTOs}
                            emptyMessage="Nenhum registro encontrado."
                            lazy={true}
                            rows={50}
                            loading={false}
                            totalRecords={props.relatorios.infoCRContribDTOs.length}
                            className="p-datatable-sm"
                            paginator={true}
                            paginatorTemplate={templatePaginatorInfoCRContrib}
                            first={first2}       
                            onPage={(e)=>onPage(e, props.relatorios.infoCRContribDTOs, setInfoCRContribDTOs)}
                            footer={
                              <>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <div style={{ textAlign: 'right', marginLeft: 'auto', paddingRight: '10px' }}>
                                    Total vrCR
                                    <br />
                                    {Utils.formatValue(Utils.calculateSum(infoCRContribDTOs, 'vrCR'))}
                                  </div>
                                  <div style={{ textAlign: 'right' }}>
                                    Total vrSuspCR
                                    <br />
                                    {Utils.formatValue(Utils.calculateSum(infoCRContribDTOs, 'vrSuspCR'))}
                                  </div>
                                </div>
                              </>
                            }
                        >
                            <Column field="tpCR" style={{ width: '1px' }} header={<>tpCR <i className="tpCR pi pi-info-circle" data-pr-tooltip="Código de Receita - CR relativo a contribuições sociais devidas à Previdência Social e a Outras Entidades e Fundos (Terceiros), conforme legislação em vigor na competência."/> </>}/>
                            <Column field="vrCR" style={{ width: '0.5em' }} body={(rowData) => Utils.formatValue(rowData.vrCR)} header={<>vrCR <i className="vrCR pi pi-info-circle" data-pr-tooltip="Valor correspondente ao CR apurado."/> </>}/>
                            <Column field='vrCRSusp' style={{ width: '0.5em' }} body={(rowData) => Utils.formatValue(rowData.vrCRSusp)} header={<>vrCRSusp <i className="vrCRSusp pi pi-info-circle" data-pr-tooltip="Valor do tributo com exigibilidade suspensa."/> </>}/>
                        </DataTable>
                    </div>
                </TabPanel>
            </TabView>

        </div>
    )
}

export default TblEvtCS;