import $ from 'jquery';
import ApiRequestException from '../exceptions/ApiRequestException';

const apiAjax = {
    getRequest: () => {
        return (url) => new Promise((resolve, reject) => {
            $.ajax({
                url: url,
                dataType: 'json',
                method: 'GET',
                success: function (resposta) {
                    resolve(resposta)
                },
                error: function (erro) {
                    reject(erro)
                },
                beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
            });
        })
    },

    postRequest: (url, data) => {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: url,
                type: 'post',
                data: JSON.stringify(data),
                dataType: 'json',
                success: (function (resposta) {
                    console.log(resposta);
                    resolve(resposta);
                })(),
                error: function (erro) {
                    console.log(erro);
                    reject(erro);
                },
                contentType: 'application/json',
                beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
            });
            reject();
        });
    },

    postRequestNew: async function postAjax(url, data){
        let result;
        try{
            result = await $.ajax({
                url: url,
                type: 'post',
                data: JSON.stringify(data),
                dataType: 'json',
                contentType: 'application/json',
                beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
            });
            console.log(result);
            return result;
        }catch(e){
            console.log(e);
            throw new ApiRequestException(e);
        }
        
    }
}

export default apiAjax;