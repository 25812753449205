import $ from 'jquery';

const auditoriaService = {
    listarAuditoria(dados, callback) {
        $.ajax({
            type: "POST",
            data: JSON.stringify(dados),
            url: "/EsocialAPI/auditoria/listar",
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            success: function(resposta) {
                if (callback) callback(resposta);
            },
            beforeSend: function(xhr, settings) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token'));
            }
        });
    },
};

export default auditoriaService;
