import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { MenuBar } from '../../componentes/MenuBar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery';
import Utils from '../../utils/Util';
import usuarioService from '../../services/usuarioService';
import { Panel } from 'primereact/panel';
import './Mensagem.css';
import DialogMensagem from '../../componentes/mensagens/DialogMensagem';


export class Mensagem extends Component {
	constructor() {
		super();
		this.state = {
			usuario: {},
			qtdeMensagens: '',
			mensagens: [],
			mensagemSelecionada: null,
			mensagemUsuarioVisible:false
		};

		this.consultaUsuario = this.consultaUsuario.bind(this);
		this.qtdeMensagens = this.qtdeMensagens.bind(this);
		this.carregaMensagensUsuario = this.carregaMensagensUsuario.bind(this);
		this.renderDataMensagem = this.renderDataMensagem.bind(this)
		this.renderConteudoPreviaMensagem = this.renderConteudoPreviaMensagem.bind(this)
		this.onMensagemSelected = this.onMensagemSelected.bind(this)
		this.rowClass = this.rowClass.bind(this)
		library.add(fab, faEnvelope, faEnvelopeOpenText);
	}
	componentDidMount() {
		this.consultaUsuario();
		this.carregaMensagensUsuario();
	}

	carregaMensagensUsuario(usuario) {
		if (usuario != undefined && usuario != null) {
			fetch("/EsocialAPI/api/mensagem/usuario/" + usuario.id, {
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					"Authorization": "Bearer " + localStorage.getItem("auth-token")
				}
			})
				.then(response => {
					if (!response.ok) {
						throw new Error("Erro na requisição.");
					}
					return response.json();
				})
				.then(resposta => {
					console.log(resposta);
					this.setState({ qtdeMensagens: resposta.length.toString(), mensagens: resposta });
				})
				.catch(error => {
					Utils.handleAjaxError(error);
				});
		}

	}

	// carregaMensagem(idMensagem) {
	// 	fetch("/EsocialAPI/api/mensagem/" +idMensagem, {
	// 		method: "GET",
	// 		headers: {
	// 			"Content-Type": "application/json; charset=UTF-8",
	// 			"Authorization": "Bearer " + localStorage.getItem("auth-token")
	// 		}
	// 	})
	// 		.then(response => {
	// 			if (!response.ok) {
	// 				throw new Error("Erro na requisição.");
	// 			}
	// 			return response.json();
	// 		})
	// 		.then(resposta => {
	// 			console.log(resposta);
	// 			this.setState({mensagemSelecionada:[resposta], mensagemUsuarioVisible: true})
	// 		})
	// 		.catch(error => {
	// 			Utils.handleAjaxError(error);
	// 	});

	// }

	carregaMensagem(mensagem) {
		console.log(mensagem);
		this.setState({mensagemSelecionada:[mensagem], mensagemUsuarioVisible: true})

	}


	qtdeMensagens() {
		console.log('mensagens');
		var baseurl = window.location.protocol + "//" + window.location.host;
		let us = this.state.usuario.idUsuario;
		$.ajax({
			url: "/EsocialAPI/api/mensagem/usuario/" + this.state.usuario.idUsuario,
			dataType: 'json',
			success: function (resposta) {
				this.setState({ qtdeMensagens: resposta.length.toString(), mensagens: resposta });
			}.bind(this),
			error: function (error) {
				console.log(error);
				//window.location.href= baseurl;
			},
			beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
		});

	}

	consultaUsuario() {
		usuarioService.consultaUsuario((usuario) => {
			console.log(usuario)
			this.carregaMensagensUsuario(usuario)
			this.setState({ usuario });
		});
	}

	renderDataMensagem(data) {
		console.log(data)
		const date = new Date(data);
		return date.toLocaleString();
	}

	renderDataLida(rowData) {
		try {
			if (rowData.dataLida !== null) {
				const date = new Date(rowData.dataLida);
				return date.toLocaleString();
			} else {
				return "";
			}
		} catch (erro) {
			console.log(erro);
			return "";
		}
	}
	renderDataEmissao(rowData) {
		try {
			if (rowData.dataEmissao !== null) {
				const date = new Date(rowData.dataEmissao);
				return date.toLocaleString();
			} else {
				return "";
			}
		} catch (erro) {
			console.log(erro);
			return "";
		}
	}
	onPage(event) {
		console.log(event);
		//this.setState({loading: true});
		setTimeout(() => {
			const startIndex = event.first;
			this.setState({
				first: startIndex,
				pagina: event.page,
				loading: false
			});
			this.atualizaAuditoria(true);
		}, 250);
	}

	rowClass(data) {
		console.log(data)
	
        return 'row-inativo';
    }

	renderConteudoPreviaMensagem(rowData){
		return rowData.corpo.length > 40 ? rowData.corpo.slice(0, 40) + '...' : rowData.corpo;
	}

	onMensagemSelected(rowData){
		console.log(rowData)
		this.carregaMensagem(rowData.value)
	}

	onHideDialogMensagem(){
		this.setState({mensagemSelecionada: null, mensagemUsuarioVisible: false})
	}

	render() {
		const bread = [
			{ label: 'Home', url: '/home' },
			{ label: 'Alertas', url: '/mensagem' }
		];
		return (
			<div>
				<MenuBar usuario={this.state.usuario} />
				<div className="content">
					<DialogMensagem usuario = {this.state.usuario} mensagensUsuario = {this.state.mensagemSelecionada} visible={this.state.mensagemUsuarioVisible} onHide={()=>{this.onHideDialogMensagem()}} />
					<Panel header="Mensagens">
						<div className='breadcrumb-changed'>
							<BreadCrumb model={bread} />
						</div>

						<div className="content align-center">
							<Messages ref={(el) => this.messages = el}></Messages>
							<div className='datatable-style-demo'>
								<DataTable 
									rowClassName={this.rowClass}
									value={this.state.mensagens} 
									paginator={true} rows={20} 
									emptyMessage="Nenhuma mensagem registrada" 
									onPage={this.onPage.bind(this)} 
									selectionMode="single" 
									selection={this.state.selectedProduct1} 
									onSelectionChange={this.onMensagemSelected} 
									dataKey="id"
								>
									<Column header="Id" field="idMensagem" style={{ width: '5%' }} />
									<Column header="Titulo" field="titulo" />
									<Column header="Conteúdo" body={this.renderConteudoPreviaMensagem} />
									<Column header="Data de emissão" field="dataEmissao" body={this.renderDataEmissao} />
									<Column header="Data de leitura" field="dataLida" body={this.renderDataLida} />
								</DataTable>
								<hr />
							</div>
						</div>
					</Panel>

				</div>
				<div className="footer l-box is-center">
					Copyright © Todos os direitos reservados.
				</div>

			</div>
		);
	}
}
