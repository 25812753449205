import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Messages} from 'primereact/messages';
import {CertificadosViewer} from '../sessoes/Certificado/CertDataView.js';
import{ MenuBar } from '../componentes/MenuBar';

export class Certificado extends Component{
  constructor(){
    super();
    this.state={home :{icon: 'pi pi-home'},agendamentos:[],tipoEvento:"1"};
  }
  render() {
    return(
      <div>
        <MenuBar/>
        <div className="content">
          <div className="content align-center">
              <Messages ref={(el) => this.messages = el}></Messages>
              <div className="pure-u-5-5">
                <CertificadosViewer />
              </div>
          </div>

            </div>
            <div className="footer l-box is-center">
                Copyright © Todos os direitos reservados.
            </div>

      </div>
    );
  }
}
