
import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
export class EvtIrrf  extends Component{
  constructor(){
    super();
    this.state={
        home :{icon: 'pi pi-home'}
        ,agendamentos:[]
        ,activeIndex:0
    };
  }
  render() {
    return(
        <div>
            
        </div>
    );
  }
}
