import React, { Component, useState, useRef } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Messages, Message } from 'primereact/messages';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import usuarioService from '../../../services/usuarioService';

export default class TabGeracaoIndividual extends Component {
    constructor() {
        super();
        this.state = {
            grupoEvento: null,
            descricaoGrupoEvento: '',
            idEvento: null,
            dropdownEventos: [],
            dropdownGrupoEventos: [],
            mes: null,
            ano: null,
            indApuracao: '',
            matriculaCodigo: '',
            isCollapsed: true,
            usuario: {},
            position: 'center',
            displayPosition: false,
            isInProgress: false,
            displayBasic: false,
            displayModal: false,
            showFiltroRubrica: false,
            dropdownIdentificadores: [],
            identificadorRubrica: null,
            descricaoIdentificadorRubrica: '',
            filtros: {},
            loading: false,
            descricaoEvento: '',
            indApuracaoDescricao: '',
            dropdownIndApuracao: [{label: 'Mensal', value:1}, {label: 'Anual', value:2}],
            dropdownMes: [
                { label: "Janeiro", value: 1 },
                { label: "Fevereiro", value: 2 },
                { label: "Março", value: 3 },
                { label: "Abril", value: 4 },
                { label: "Maio", value: 5 },
                { label: "Junho", value: 6 },
                { label: "Julho", value: 7 },
                { label: "Agosto", value: 8 },
                { label: "Setembro", value: 9 },
                { label: "Outubro", value: 10 },
                { label: "Novembro", value: 11 },
                { label: "Dezembro", value: 12 },

            ]
        };

        this.msgs = React.createRef();
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onLoadingClick = this.onLoadingClick.bind(this);
        this.addMessages = this.addMessages.bind(this);

    }

    onLoadingClick() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2000);
    }

    componentDidMount() {
        this.consultaUsuario();
        this.carregarDropdownGrupoEvento();
        this.carregarDropdownEvento(null);
    }

    addMessages = (msg) => {
        this.msgs.show([
            msg
        ]);
    }

    showMessage(titulo, mensagem, tipoMensagem, sticky, life) {
        this.msgs.show({ severity: tipoMensagem, summary: titulo, detail: mensagem, sticky: sticky, life: life});
    }

    verificaDados() {

        this.setState({ displayBasic: true });

        const filtros = {
            idEvento: parseInt(this.state.idEvento),
            matriculaCodigo: this.state.matriculaCodigo,
            mes: parseInt(this.state.mes),
            ano: parseInt(this.state.ano),
            indApuracao: parseInt(this.state.indApuracao),
            dropdownEventos: this.state.dropdownEventos
        };

        this.setState({ filtros: filtros })
    }

    renderFooter() {
        return (
            <div>
                <Button label="Corrigir os dados" icon="pi pi-times" onClick={() => { this.limparFiltros() }} onHide={() => { this.limparFiltros() }} className="p-button-danger" autoFocus />
                <Button label="Os dados estão corretos!" icon="pi pi-check" className="p-button-sucess" loading={this.state.loading} onClick={() => { this.gerarEventoIndividual() }} />
            </div>
        );
    }

    gerarEventoIndividual() {

        const msg = { severity: 'info', summary: '', detail: 'Gerando evento, por favor, aguarde!', sticky: false, life: 3000 }

        this.onLoadingClick(true)
        this.addMessages(msg);
        this.setState({ isInProgress: true })
        this.setState({ displayBasic: false });

        const filtros = {

            idUsuario: parseInt(this.state.usuario.id),
            idEvento: parseInt(this.state.idEvento),
            matriculaCodigo: this.state.matriculaCodigo,
            mes: this.state.mes,
            ano: this.state.ano,
            indApuracao: this.state.indApuracao == 2,
            grupoEvento: this.state.descricaoGrupoEvento,
            idIdentificadorTabela: this.state.identificadorRubrica
        };

        fetch("/EsocialAPI/api/importacao/eventoIndividual/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
            },
            body: JSON.stringify(filtros),
        })
            .then(response => response.text())
            .then(data => {
                const importacaoEventoDTO = JSON.parse(data);
                const mensagemDTO = importacaoEventoDTO.mensagemDTO
                this.showMessage('', mensagemDTO.detail, mensagemDTO.severity, mensagemDTO.sticky, 7000);
                this.onLoadingClick(false)
                this.setState({ isInProgress: false })
            })
            .catch(error => {
                console.error('Error:', error);
                this.setState({ isInProgress: false })
            });
    }

    consultaUsuario() {
        usuarioService.consultaUsuario((usuario) => {
            this.setState({ usuario });
        });
    }

    carregarDropdownGrupoEvento() {
        const url = "/EsocialAPI/grupo/combo/";
        $.ajax({
            url: url,
            dataType: 'json',
            error: function (e) {
                console.log(e);
            },
            success: function (resposta) {
                console.log(resposta)
                const filteredData = resposta.filter(item => ['TABELA', 'NÃO_PERIÓDICO', 'PERIÓDICO'].includes(item.label));

                this.setState({ dropdownGrupoEventos: filteredData });
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    carregarDropdownEvento(grupoEvento) {
        const url = grupoEvento !== null ?
            "/EsocialAPI/evento/lista/tipo/" + grupoEvento
            :
            "/EsocialAPI/evento/lista/atual";
        $.ajax({
            url: url,
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            error: function (e) {
                console.log(e);
            },
            success: function (resposta) {
                console.log(resposta);
                const opcoes = resposta
                    .filter(evento => evento.codigo !== "1299" && evento.codigo !== "1298")
                    .map(evento => {
                        return { label: evento.codigo + ' - ' + evento.nome, value: evento.idEventoEsocial }
                    });
                this.setState({ dropdownEventos: opcoes });
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    carregaDropdownIdentificadorTabela(){
        const url = "/EsocialAPI/api/importacao/rubrica/identab" ;

        $.ajax({
            url: url,
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            error: function (e) {
                console.log(e);
            },
            success: function (resposta) {
                const opcoes = resposta.map((evento) => {
                    return { label: evento.descricao, value: evento.id }
                })
                this.setState({ dropdownIdentificadores: opcoes });
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
        });
    }

    renderProgressBar(isInProgress) {
        if (isInProgress) {
            return (
                <div style={{ marginTop: '40px' }}>
                    <div>
                        <ProgressBar mode="indeterminate" />
                    </div>
                </div>
            )
        }
    }

    onClick(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }

    onHide() {
        this.setState({
            displayBasic: false
        });
    }

    limparFiltros = () => {
        this.setState({
          displayBasic: false ,
          ano: '',
          mesDescricao: '',
          mes: '',
          indApuracaoDescricao: '',
          indApuracao: '',
          idEvento: '',
          descricaoEvento: ''
        });
      }


    handleEventoChange(idEvento) {

        this.limparFiltros();

        this.carregarDropdownEvento(idEvento.value);

        console.log(this.state.dropdownEventos);
        
        const descEvento = this.state.dropdownEventos.find(item => item.value === idEvento);
        this.setState({showFiltroRubrica: descEvento.label.includes("1010")})
        if(descEvento.label.includes("1010")){
            this.carregaDropdownIdentificadorTabela();
        }
        this.setState({
            idEvento: idEvento,
            descricaoEvento: descEvento.label
          });
    }

    handleMesChange(mes) {

        const mesDescricao = this.state.dropdownMes.find(item => item.value === mes);

        this.setState({
          mes: mes,
          mesDescricao: mesDescricao.label
        });
      }


    handleAnoChange(ano) {
        this.setState({ ano });
    }

    handleIndApuracaoChange(indApuracao) {

        const indicativoDescricao = this.state.dropdownIndApuracao.find(item => item.value === indApuracao);

        this.setState({
            indApuracao: indApuracao,
            indApuracaoDescricao: indicativoDescricao.label
          });
    }

    handleObjDetalheChange(matriculaCodigo) {
        this.setState({ matriculaCodigo });
    }

    handleGrupoEventoChange(grupoEvento) {

        this.limparFiltros();

        this.carregarDropdownEvento(grupoEvento.value);

        const descGrupoEvento = this.state.dropdownGrupoEventos.find(item => item.value === grupoEvento.value);

        this.setState({
            grupoEvento: grupoEvento.value,
            descricaoGrupoEvento: descGrupoEvento.label
        })
    }

    handleIdentificadorRubricaChange(identificadorRubrica) {

        const descIdentificadorRubrica= this.state.dropdownIdentificadores.find(item => item.value === identificadorRubrica.value);
        console.log(identificadorRubrica)
        this.setState({
            identificadorRubrica: identificadorRubrica.value,
            descricaoIdentificadorRubrica: descIdentificadorRubrica.label
        })
    }
    setIsCollapsed(e) {
        this.setState({ e });
    }

    render() {
        const style = {
            label: {
                fontSize: 16,
                fontWeight: 'bold',
                display: 'block',
            },
            dropDown: {
                width: '80%'
            },
            inputText: {
                width: '80%'
            },
            inputContainer: {
                paddingTop: '10px',
                paddingBottom: '10px'
            }
        }

        const anoAtual = new Date().getFullYear();

        const ano = [];
        for (let year = anoAtual; year >= 2020; year--) {
            ano.push(year);
        }

        const showFiltrosFolha = this.state.dropdownGrupoEventos.find(item => item.value === this.state.grupoEvento)?.label === 'PERIÓDICO';

        const showFiltrosTabela = this.state.dropdownGrupoEventos.find(item => item.value === this.state.grupoEvento)?.label === 'TABELA';

        const showFiltrosNaoPeriodico = this.state.dropdownGrupoEventos.find(item => item.value === this.state.grupoEvento)?.label === 'NÃO_PERIÓDICO';

        return (
            <div>
                <div className="content" >
                    <div className="content align-center">
                        <div>
                            <div>
                                    <h3>Importação individual de eventos de Tabela, Periódicos e Não Periódicos</h3>
                                    <p></p>
                                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                                        Este recurso possibilita a importação individualizada de eventos para a Mensageria.
                                    </p>
                                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                                        Nota: caso eventos gerados exclusivamente via trigger (ex: S-2205/S-2206) não estejam sendo gerados automaticamente, favor contate o suporte!
                                    </p>
                                    <p>
                                        Para isto, selecione os filtros abaixo, de acordo com a necessidade:
                                    </p>
                                <div style={style.inputContainer} className="pure-g">
                                    <div className="pure-u-1-3">
                                        <label style={style.label}>Grupo Evento:</label>
                                        <Dropdown
                                            value={this.state.grupoEvento}
                                            options={this.state.dropdownGrupoEventos}
                                            onChange={(e) => this.handleGrupoEventoChange(e)}
                                            placeholder="Escolha o grupo"
                                            style={style.dropDown}
                                        />
                                    </div>
                                    <div className="pure-u-1-3">
                                        <label style={style.label}>Evento:</label>
                                        <Dropdown
                                            value={this.state.idEvento}
                                            options={this.state.dropdownEventos}
                                            onChange={(e) => this.handleEventoChange(e.value)}
                                            placeholder="Escolha o evento"
                                            style={style.dropDown}
                                        />
                                    </div>

                                    <div className="pure-u-1-3">
                                        <label style={style.label}>Código/Matricula:</label>
                                        <InputText
                                            id="objDetalhe"
                                            value={this.state.objDetalhe}
                                            onChange={(e) => this.handleObjDetalheChange(e.target.value)}
                                            style={style.inputText}
                                        />
                                    </div>
                                </div>
                            </div>

                            {this.state.showFiltroRubrica && (
                                <div style={style.inputContainer} className="pure-g">
                                    <div className="pure-u-1-3">
                                        <label style={style.label}>Identificador Tabela:</label>
                                        <Dropdown
                                            value={this.state.identificadorRubrica}
                                            options={this.state.dropdownIdentificadores}
                                            onChange={(e) => this.handleIdentificadorRubricaChange(e)}
                                            placeholder="Escolha o identificador"
                                            style={style.dropDown}
                                        />
                                    </div>
                                </div>
                            )}
                            {showFiltrosFolha && (
                                <div style={style.inputContainer} className="pure-g">
                                    <div className="pure-u-1-4">
                                        <label style={style.label}>Mês: </label>
                                        <Dropdown
                                            value={this.state.mes}
                                            options={this.state.dropdownMes}
                                            onChange={(e) => this.handleMesChange(e.value)}
                                            placeholder="Escolha o mês"
                                            style={style.dropDown}
                                        />
                                    </div>
                                    <div className="pure-u-1-4">
                                        <label style={style.label}>Ano:</label>
                                        <Dropdown
                                            value={this.state.ano}
                                            options={ano}
                                            onChange={(e) => this.handleAnoChange(e.value)}
                                            placeholder="Escolha o ano"
                                            style={style.dropDown}
                                        />
                                    </div>
                                    <div className="pure-u-1-4">
                                        <label style={style.label}>Ind. Apuração:</label>
                                        <Dropdown
                                            value={this.state.indApuracao}
                                            options={this.state.dropdownIndApuracao}
                                            onChange={(e) => this.handleIndApuracaoChange(e.value)}
                                            placeholder="Escolha uma opção"
                                            style={style.dropDown}
                                        />
                                    </div>

                                    <div className="pure-u-3-3" style={{ textAlign: 'center', alignContent: 'right' }}>
                                        <Button style={{ marginTop: '32px' }} onClick={() => this.verificaDados()} label="Importar Evento de Folha" icon="pi pi-play" />
                                    </div>

                                </div>
                            )}

                            {showFiltrosNaoPeriodico && (
                                <div className="pure-u-3-3" style={{ textAlign: 'center', alignContent: 'center' }}>
                                    <Button style={{ marginTop: '110px', marginLeft: '70%' } } onClick={() => this.verificaDados()} label="Importar Evento Não Periódico" icon="pi pi-play" />
                                </div>
                            )}

                            {showFiltrosTabela && (
                                <div className="pure-u-3-3" style={{ textAlign: 'center', alignContent: 'right' }}>
                                    <Button style={{ marginTop: '110px', marginLeft: '70%' } } onClick={() => this.verificaDados()} label="Importar Evento de Tabela" icon="pi pi-play" />
                                </div>
                            )}

                            <Messages ref={(el) => this.msgs = el} />

                            {this.renderProgressBar(this.state.isInProgress)}

                            <div>
                                <Dialog header="Confirmação dos Dados da Importação" visible={this.state.displayBasic} style={{ width: '50vw' }} footer={this.renderFooter()} onHide={() => { this.limparFiltros() }}>
                                    <p><b>Os dados da importação abaixo estão corretos?</b></p>
                                     {this.state.descricaoGrupoEvento ? <p><b>Grupo Evento: </b>{this.state.descricaoGrupoEvento}</p> : ""}
                                     {this.state.descricaoEvento ? <p><b>Evento: </b>{this.state.descricaoEvento}</p> : ""}
                                     {this.state.filtros.matriculaCodigo ? <p><b>Código/Matricula: </b>{this.state.filtros.matriculaCodigo}</p> : ""}
                                     {this.state.mesDescricao ? <p><b>Mês: </b>{this.state.mesDescricao}</p> : ""}
                                     {this.state.filtros.ano ? <p><b>Ano: </b>{this.state.filtros.ano}</p> : ""}
                                     {this.state.indApuracaoDescricao ? <p><b>Ind. Apuração: </b>{this.state.indApuracaoDescricao}</p> : ""}
                                </Dialog>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}