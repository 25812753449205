import React, { Component } from 'react';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { ListBox } from 'primereact/listbox';
import apiAjax from '../../services/apiAjax';
import { Messages } from 'primereact/messages';
import $ from 'jquery';
import { Editor } from 'primereact/editor';
import ReactHtmlParser  from 'react-html-parser';


export class Requisito extends Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      visibilidadeDoc:false,
      selectedEventos: [],
      eventos: [],
      eventosListBox: [],
      documentacao:{},
      eventosRequisitosSalvos:[],
      idDocumentacao:0,
      conceito:'',
      informacaoadicional:'',
      obrigacao:'',
      prazo:'',
      preRequisito:'',
      idEventoEsocial:0,
      eventoDialog:{}, editarDoc:'none',visualizaDoc:'block'
    };
    this.carregaDocumentacao = this.carregaDocumentacao.bind(this);
    this.salvarDocumentacao = this.salvarDocumentacao.bind(this);
  }
  componentDidMount() {
    this.setState({ editarDoc:'none',visualizaDoc:'block'});
  }
  carregaDocumentacao(){
    this.setState({visibilidadeDoc:true});
    try{
      $.ajax({
        url:"/EsocialAPI/documentacao/evento/"+this.props.idEvento,
        type:'GET',
          error:function(error){
            console.log(error);
        },
        success:function(retorno){
          this.contrucaoDocumentacao(retorno);          
        }.bind(this),
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      });
    }catch(erro){
      console.log(erro);
    }
  }
  salvarDocumentacao(){
    //this.setState({visibilidadeDoc:true});
    let data = {
      conceito:this.state.conceito,
      informacaoadicional:this.state.informacaoadicional,
      obrigacao:this.state.obrigacao,
      prazo:this.state.prazo,
      preRequisito:this.state.preRequisito,
      idEventoEsocial:this.props.idEvento,
    };
    data = JSON.stringify(data);
    try{
      $.ajax({
        url:"/EsocialAPI/documentacao/novo/",
        data:data,
        type:'POST',
        contentType:"application/json; charset=utf-8",
        dataType: 'json',
        error:function(error){
            console.log(error);
        },
        success:function(retorno){
          console.log(retorno);
        }.bind(this),
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      });
    }catch(erro){
      console.log(erro);
    }
  }
  contrucaoDocumentacao(retorno){
    
    let doc = {
      idDocumentacao:retorno.idDocumentacao,
      conceito:retorno.conceito,
      informacaoadicional:retorno.informacaoadicional,
      obrigacao:retorno.obrigacao,
      prazo:retorno.prazo,
      preRequisito:retorno.preRequisito,
      idEventoEsocial:this.props.idEvento
    };    
    if(retorno===''){
      this.setState({
        idDocumentacao:0,
        conceito:'',
        informacaoadicional:'',
        obrigacao:'',
        prazo:'',
        preRequisito:'',
        idEventoEsocial:0,
        editarDoc : 'inline-block', visualizaDoc:'none'
      }); 
    }else{      
      this.setState({
        idDocumentacao:retorno.idDocumentacao,
        conceito:retorno.conceito,
        informacaoadicional:retorno.informacaoadicional,
        obrigacao:retorno.obrigacao,
        prazo:retorno.prazo,
        preRequisito:retorno.preRequisito,
        idEventoEsocial:this.props.idEvento,
        editarDoc : 'none',visualizaDoc:'block',documentacao : doc
      });
    }
  }
  async handleClickDialog() {

    const eventos = await this.apiGetCall('/EsocialAPI/evento/lista/atual');
    console.log(eventos);
    const eventoDialog = eventos.filter((evt)=>evt.idEventoEsocial == this.props.idEvento);
    console.log(eventoDialog);
    this.setState({eventoDialog: eventoDialog[0]});

    const eventosListBox = eventos.filter((evento) => {
      return evento.descricaoTipo != "EXCLUSÃO" && evento.descricaoTipo != "TOTALIZADORES";
    }).map((evento) => {
      return { label: evento.codigo + ' - ' + evento.nome, value: { label: evento.codigo + ' - ' + evento.nome, value: evento.codigo, id:evento.idEventoEsocial } }
    });

    const eventosPreReq = await this.apiGetCall('/EsocialAPI/requisitos/evento/' + this.props.idEvento);
    const idEventosPreReq = eventosPreReq.map((evt)=>evt.idEvento);
    const eventosPreReqSelecionados = eventos.filter((evento) => {
      return idEventosPreReq.includes(evento.idEventoEsocial);
    }).map((evento)=>{
      return { label: evento.codigo + ' - ' + evento.nome, value: evento.codigo, id:evento.idEventoEsocial }
    });

    console.log(eventosPreReq);
    console.log(eventosPreReqSelecionados);

    this.setState({eventosRequisitosSalvos: eventosPreReqSelecionados});
    this.setState({selectedEventos:eventosPreReqSelecionados})
    this.setState({ eventosListBox: eventosListBox });
    

    this.setState({ eventos: (eventos != 0 ? eventos : []) });
    this.setState({ visible: true });
  }

  async apiGetCall(url) {
    const urlEventos = url;
    const apiGetRequest = apiAjax.getRequest();
    try {
      const responseEventos = await apiGetRequest(urlEventos);
      console.log(responseEventos);
      return responseEventos;
    } catch (e) {
      console.log(e);
      return 0;
    }
  }

  async apiPostCall(url, data){
    const apiPostRequest = apiAjax.postRequest();
    try{
      const response = await apiPostRequest(url, data);
      console.log(response);
      return response;
    }catch(e){
      console.log(e);
      return 0;
    }
  }

  handleChangeListBox(event){
    console.log(event);
    console.log(this.state.selectedEventos);
    this.setState({ selectedEventos: event });
  }
  async handleSalveButtonClick(idEvento){
    const eventosReqSalvos = this.state.eventosRequisitosSalvos;
    const eventosSelecionados = this.state.selectedEventos;
    console.log(eventosSelecionados);

    const idEventosRequisitos = eventosSelecionados.map((evt)=>evt.id);
    console.log(idEventosRequisitos);
    const requisitoEventoDTO = {idEvento: idEvento, idEventosRequisitos: idEventosRequisitos}
    console.log(requisitoEventoDTO);

    const deletedEventosReq = eventosReqSalvos.filter((evt)=>!eventosSelecionados.includes(evt));
    const deletedidEventosRequisitos = deletedEventosReq.map((evt)=>evt.id);
    const deletedRequisitoEventoDTO = {idEvento:idEvento, idEventosRequisitos: deletedidEventosRequisitos}
    console.log(deletedRequisitoEventoDTO);

    const responseSalvar = await this.apiPostCall('/EsocialAPI/requisitos/salvar', requisitoEventoDTO);
    const responseDeletar = await this.apiPostCall('/EsocialAPI/requisitos/deletar', deletedRequisitoEventoDTO);
    this.showMessage(responseSalvar);
    console.log(responseDeletar);
  }

  showMessage(messsage){
    this.msgs1.show(messsage);
  }

  render() {
    function NumberList(props) {
      console.log(props);
      const numbers = props.numbers;
      if (numbers != null) {
        if (numbers.length === 0) {
          return "Nenhum evento selecionado";
        }
        const listItems = numbers.map((number) =>
          <Button key={number.value} label={number.label} style={{ marginLeft: '5px', marginTop: '5px' }} />
        );
        return (
          <em>{listItems}</em>
        );
      } else {
        return "Nenhum evento selecionado";
      }
    }
    const footerDocuDialog = (
      <div>
        <Button label="Salvar" icon="pi pi-check" onClick={() => this.salvarDocumentacao()} />
        <Button label="Cancelar" icon="pi pi-times" onClick={() => this.setState({ visibilidadeDoc: false })} />
      </div>
    );
    const footerDialog = (
      <div>
        <Button label="Salvar" icon="pi pi-check" onClick={() => this.handleSalveButtonClick(this.props.idEvento)} />
        <Button label="Cancelar" icon="pi pi-times" onClick={() => this.setState({ visible: false })} />
      </div>
    );

    return (
      <div>
        <div style={{display:'inline-block'}}>
          <Dialog
            header="Documentação de evento."
            visible={this.state.visibilidadeDoc}
            modal={true}
            onHide={() => this.setState({ visibilidadeDoc: false })}
            style={{ width: '960px',height:'640px' }}
            footer={footerDocuDialog}
          >
            <div style={{ width: '100%', height:'100%',verticalAlign: 'top', paddingLeft: '25px', textAlign: 'left', borderBottom: '1px ccc solid' }}>
              <div>
                <label>Conceito:</label> <br />
                <div style={{display:this.state.visualizaDoc}}>{ReactHtmlParser(this.state.conceito)}</div>
                <div style={{display:this.state.editarDoc}}>
                  <Editor style={{height:'220px'}} value={this.state.conceito} onTextChange={(e) => this.setState({ conceito: e.htmlValue })} />
                </div>
              </div>
              <div>
                <label>Quem está obrigado:</label> <br />
                <div style={{display:this.state.visualizaDoc}}>{ReactHtmlParser(this.state.obrigacao)}</div>
                <div style={{display:this.state.editarDoc}}>
                <Editor style={{height:'220px'}} value={this.state.obrigacao} onTextChange={(e) => this.setState({ obrigacao: e.htmlValue} )} />
                </div>
              </div>
              <div>
                <label>Prazo de envio:</label> <br />
                <div style={{display:this.state.visualizaDoc}}>{ReactHtmlParser(this.state.prazo)}</div>
                <div style={{display:this.state.editarDoc}}>
                <Editor style={{height:'220px'}} value={this.state.prazo} onTextChange={(e) => this.setState({ prazo: e.htmlValue} )} />
                </div>
              </div>
              <div>
                <label>Pré requisito:</label> <br />
                <div style={{display:this.state.visualizaDoc}}>{ReactHtmlParser(this.state.preRequisito)}</div>
                <div style={{display:this.state.editarDoc}}>
                <Editor style={{height:'220px'}} value={this.state.preRequisito} onTextChange={(e) => this.setState({ preRequisito: e.htmlValue })} />
                </div>
              </div>
              <div>
                <label>Informações adicionais:</label> <br />
                <div style={{display:this.state.visualizaDoc}}>{ReactHtmlParser(this.state.informacaoadicional)}</div>
                <div style={{display:this.state.editarDoc}}>
                <Editor style={{height:'320px', display:this.state.editarDoc}} value={this.state.informacaoadicional} onTextChange={(e) => this.setState({ informacaoadicional: e.htmlValue} )} />
                </div>
              </div>
            </div>

          </Dialog>
          <Button onClick={(e)=>this.carregaDocumentacao()} icon="pi pi-question-circle" className="p-button-info" tooltip="Documentação do evento" iconPos="right"/>
        </div>
        <div style={{display:'inline-block',marginLeft:'22px;'}}>
          <Dialog
            header='Eventos pré requisitos.'
            visible={this.state.visible}
            style={{ width: '50vw' }}
            modal={true}
            onHide={() => this.setState({ visible: false })}
            footer={footerDialog}
          >
            <div style={{ width: '100%', verticalAlign: 'top', paddingLeft: '25px', textAlign: 'left', overflowY: 'scroll', borderBottom: '1px ccc solid' }}>
              <Messages ref={(el) => this.msgs1 = el} />
              <em>Eventos selecionados</em><br />
              <NumberList numbers={this.state.selectedEventos} />
            </div>
            <div style={{ width: '100%', verticalAlign: 'top' }}>
              <em>Selecione os eventos requisitos</em>
              <ListBox
                value={this.state.selectedEventos}
                options={this.state.eventosListBox}
                onChange={(e) => this.handleChangeListBox(e.value)}
                multiple={true}
                optionLabel="label"
                style={{ textAlign: 'left', height: '180px', width: '100%', overflowY: 'scroll' }} />
            </div>

          </Dialog>
          <Button onClick={() => this.handleClickDialog()} icon="pi pi-check-square" className="p-button-warning" tooltip="Eventos requisitos para envio"  />
        </div>
        
      </div>
    );
  }
}