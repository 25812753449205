import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faHandshake, faHandHoldingUsd, faMoneyCheckAlt, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { Panel } from 'primereact/panel';
import { Messages } from 'primereact/messages';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import $ from 'jquery';
import { BtnFolhaPagamento } from '../FolhaPagamento/BtnFolhaPagamento.js';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { BreadCrumb } from 'primereact/breadcrumb';
import 'moment/locale/pt-br';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import Utils from "../../utils/Util";
import DialogGeracaoEventos from '../../componentes/eventosPeriodicos/DialogGeracaoEventos/DialogGeracaoEventos.js';
import Configuracoes from '../../componentes/eventosPeriodicos/Configuracoes/Configuracoes.js';


export class TblFolhaGeral extends Component {
  constructor() {
    super();
    library.add(fab, faHandshake, faHandHoldingUsd, faMoneyCheckAlt, faThumbsUp);
    this.state = {
      welcome: 'Carregando folha de pagamento ...'
      , tblLoading: 'block'
      , wdwImportacao: false
      , activeIndex: 0
      , date: null
      , activeStep: 0
      ,configvisible: false
      , apuracao: [
        { label: 'MENSAL', value: '1' },
        { label: 'ANUAL (13° salário)', value: '2' }
      ]
      , todosMeses: false
      , InitialLoading: 'block'
      , carregando: false
      , indApuracao: 1
      , anoApur: new Date()
      , anoMesApur: null
      , interval: null
      , regPagina: 12
      , pagina: 0
      , warning: false
      , btnAnterior: true
      , btnProximo: false
      , perApur: null
      , mesesSlt: []
      , mesSelected: []
      , listaMeses: []
      , ano: '18'
      , mes: '01'
      , qtdFolha: 0
      , dvImportacao: 'none'
      , divStatusEnvio: 'block'
      , btnFechamento: 'none'
      , fechamentoModal: false
      , modalMudarStatus: false
      , dropdownStatusModal: null
      , desabilitaMudarStatus: true
      , idFolhaPagamentoSelecionada: 0
      , perApurSelecionado: null
      , statImportacao: 0
      , servidoresFiltrados: null
      , servidor: { cargo: null, cpf: null, datadesligamento: null, email: null, id: null, matricula: null, nome: '', regimePrevidenciario: 1, telefone: null, tipoVinculo: null }
      , percentEnviados: 0
      , totalRegImpor: 0
      , hash: ''
      , filtro: { responsavelTecnico: '', perApuracao: new Date(), status: 'PENDENTE', pagina: 0, numRegPagina: 12 }
      , folhas: null
      , total: 0
    };
    this.atualizarLista = this.atualizarLista.bind(this)
    this.actionTemplate = this.actionTemplate.bind(this);
    this.displayValueTemplate = this.displayValueTemplate.bind(this);
    this.listaMesesCheck = this.listaMesesCheck.bind(this);
    this.consultaServidor = this.consultaServidor.bind(this);
    this.renderTipoApuracao = this.renderTipoApuracao.bind(this);
    //this.yearNavigatorTemplate = this.yearNavigatorTemplate.bind(this);
    //this.servers = [];
  }
  componentWillMount() {
    this.atualizarLista(false);
  }

  atualizarLista(bolOnpage) {
    let pagina = 0;
    if (bolOnpage === false) {
      pagina = this.state.pagina;
      if (pagina < 0) {
        pagina = 0;
      }
    } else {
      pagina = this.state.filtro.pagina;
    }
    const f = this.state.filtro;
    f.pagina = pagina;
    this.setState({ filtro: f });
    //console.log(this.state.ano+"/"+this.state.mes+"/"+pagina+"/"+this.state.regPagina+"/"+this.state.servidor);
    $.ajax({
      type: "POST",
      data: JSON.stringify(this.state.filtro),
      url: "/EsocialAPI/folha",
      dataType: 'json',
      contentType: "application/json; charset=utf-8",
      success: function (resposta) {
        console.log(resposta);
        this.desabilitaLoading();
        this.setState({ folhas: resposta.folhas, qtdFolha: resposta.totalRegistros, carregando: false })
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  atualizarServidores() {
    try {
      console.log(this.state.anoApur.getFullYear());

      //console.log(this.state.ano+"/"+this.state.mes+"/"+pagina+"/"+this.state.regPagina+"/"+this.state.servidor);
      $.ajax({
        type: "POST",
        data: JSON.stringify(this.state.filtro),
        url: "/EsocialAPI/folha",
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        success: function (resposta) {
          console.log(resposta);
          this.desabilitaLoading();
          this.setState({ folhas: resposta.folhas })
        }.bind(this),
        beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
      });
    } catch (err) {
      console.log('Erro ao consultar a quantidade de servidores');
    }
  }
  validaImportacao(resposta) {
    console.log(resposta.servidores.length);
    if (resposta.servidores.length === 0) {
      this.setState({ dvImportacao: 'block', divStatusEnvio: 'none' });
    } else {
      this.setState({ dvImportacao: 'none', divStatusEnvio: 'block' });
    }
  }
  desabilitaLoading() {
    this.setState({ tblLoading: 'none', InitialLoading: 'none' });
  }
  onPage(event) {
    this.setState({ tblLoading: 'block' });
    setTimeout(() => {
      const startIndex = event.first;
      this.setState({
        first: startIndex,
        pagina: event.page,
        tblLoading: 'none',
        carregando: true
      });
      this.atualizarLista(false);
    }, 250);
  }
  consultaServidor(event) {
    try {
      var dados = this.state.servidor;
      $.ajax({
        url: "/EsocialAPI/folha/trabalhador/",
        type: "POST",
        contentType: "application/json; charset=utf-8",
        data: dados,
        dataType: 'json',
        success: function (resposta) {
          var filtrados = [];
          resposta.map(function (objeto) {
            return filtrados.push(objeto);
          });
          this.setState({ servidoresFiltrados: filtrados });
        }.bind(this),
        beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
      });/*
      let results = this.state.servidoresFiltrados.filter((server) => {
            return server.toLowerCase().startsWith(event.query.toLowerCase());
      });*/
      //this.setState({ servidoresFiltrados: results }).bind(this);
    } catch (err) {
      console.log(err);
    }
  }
  actionTemplate(rowData) {
    let retorno = '';
    let perApur = new Date(rowData['perApur']);
    retorno = (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <BtnFolhaPagamento style={{ margin: '4px' }} status={rowData['statusFolha']} idFolhaPagamento={rowData['idFolhaPagamento']} anoApur={perApur.getFullYear()} mesApur={perApur.getMonth() + 1} responsavelTecnico={rowData['responsavelTecnico']} />
        <Button
            icon={
                <svg width="25px" height="25px" viewBox="-9.6 -9.6 67.20 67.20" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="48" height="48" fill="white" fill-opacity="0.01"></rect> <path d="M18 31H38V5" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M30 21H10V43" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M44 11L38 5L32 11" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M16 37L10 43L4 37" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            }
            style={{ margin: '4px' }}
            tooltip="Mudar Status"
            className="p-button-info"
            onClick={(e) => this.handleMudarStatusClick(rowData['idFolhaPagamento'], rowData['perApur'])}
        />
      </div>
    )

    return retorno
  }
  
  changeAnoMesApur(rowData, column) {
    let perApur = new Date(rowData['perApur']);
    let retorno = '';
    retorno = (
      <div>
        <strong>{perApur.getMonth() + 1}/{perApur.getFullYear()}</strong>
      </div>
    )
    return retorno
  }
  carregaEventos() {
    $.ajax({
      url: "/EsocialAPI/api/importacao/periodico/preview",
      method: 'POST',
      data: JSON.stringify({ mesAnoApuracao: this.state.ano + "-" + this.state.mes }),
      contentType: "application/json",
      dataType: 'json',
      success: function (resposta) {
        this.setState({ hash: resposta.hash }, () => this.iniciarImportEventos());
        this.setState({ totalRegImpor: resposta.registros }, () => this.atualizaBarra());

      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  atualizaBarra() {
    this.consultaImportacao();
    var intervalo = setInterval(() => {
      this.consultaImportacao();
    }, 2000);
    this.setState({ interval: intervalo });
  }
  displayValueTemplate(value) {
    return (
      <React.Fragment>
        {value}%
      </React.Fragment>
    );
  }
  leaveImportacao(e) {
    this.setState({ warning: false });
    //this.buscar(e);
  }
  mesesDoAno() {
    try {
      var moment = require('moment');
      moment.locale('pt-br');
      const meses = moment.months();
      return meses;
    } catch (err) {
      console.log(err)
    }

  }

  onMesChange(e) {
    try {
      let selectedMeses = [];
      if (this.state.mesesSlt !== null) {
        selectedMeses = [...this.state.mesesSlt];
      }
      if (e.checked) {
        selectedMeses.push(e.value);
      } else {
        selectedMeses.splice(selectedMeses.indexOf(e.value), 1);
      }
      this.setState({ mesesSlt: selectedMeses });
    } catch (err) {
      console.log(err);
    }
  }
  handleMudarStatusClick(idFolhaPagamentoSelecionada, perApurSelecionado) {
    let perApur = new Date(perApurSelecionado)
    let perApurFormatado = perApur.getMonth() + 1 + "/" + perApur.getFullYear()
    this.setState({ idFolhaPagamentoSelecionada })
    this.setState({ perApurSelecionado: perApurFormatado })
    this.setState({ modalMudarStatus: true });
  }
  handleDropdownStatusModalChange(dropdownStatusModal) {
    this.setState({ dropdownStatusModal });
  }
  mudarStatusFolha() {
    $.ajax({
        type: "POST",
        data: { 'idFolha': this.state.idFolhaPagamentoSelecionada, 'status': this.state.dropdownStatusModal },
        url: "/EsocialAPI/folha/mudar-status/",
        dataType: 'text',
        success: function (resposta) {
            this.setState({ modalMudarStatus: false, dropdownStatusModal: null });
            this.atualizarLista(false);
        }.bind(this),
        beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); },
        error: Utils.handleAjaxError
    });
  }
  renderFooterMudarStatus(desabilitaMudarStatus) {
    if (this.state.idFolhaPagamentoSelecionada > 0) {
        desabilitaMudarStatus = false;
    }
    return (
        <div>
            <Button
                label="Mudar"
                icon={
                    <svg width="25px" height="25px" viewBox="-9.6 -9.6 67.20 67.20" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="48" height="48" fill="white" fill-opacity="0.01"></rect> <path d="M18 31H38V5" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M30 21H10V43" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M44 11L38 5L32 11" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M16 37L10 43L4 37" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                }
                onClick={(e) => this.mudarStatusFolha()}
                disabled={desabilitaMudarStatus}
            />
            <Button label="Cancelar" icon="pi pi-times" onClick={(e) => this.setState({ modalMudarAcao: false })} className="p-button-secondary" />
        </div>
    );
  }

  onChangePaginator(event, options) {
    options.currentPage = event.value
    this.setState(prevState => ({
      loading: true,
      pagina: event.value,
      filtro: {
          ...prevState.filtro,
          pagina: event.value
      },
    }));
    setTimeout(() => {
        const page = parseInt(this.state.filtro.pagina);
        const startIndex = this.state.filtro.pagina ? options.rows * page : 0;
        this.setState(prevState => ({
            first: startIndex,
            pagina: event.value,
            filtro: {
                ...prevState.filtro,
                pagina: event.value
            },
            loading: false
        }));
        this.atualizarLista(true);
    }, 250);
  }

  renderMudarStatus() {
    const style = {
        dropDown: {
            width: '80%'
        },
    }
    const dropdownOptions = [];
    dropdownOptions.push({label: 'Aberta', value: 'ABERTO'});
    dropdownOptions.push({label: 'Fechada', value: 'FECHADO'});

    return (
        <div className="p-grid">
            <div className="p-col">
                <Dropdown
                    value={this.state.dropdownStatusModal}
                    options={dropdownOptions}
                    onChange={(e) => { this.handleDropdownStatusModalChange(e.value) }}
                    placeholder="Escolha o status"
                    style={style.dropDown}
                />
            </div>
        </div>
    )
  }
  listaMesesCheck(item, index) {
    try {
      let chk = 'mesCheck' + index;
      var anoSelecionado = this.state.anoApur.getFullYear();
      var mesAtual = new Date().getMonth();
      var anoAtualAtual = new Date().getFullYear();


      var disable = false;
      if (anoAtualAtual === anoSelecionado) {
        if (index > mesAtual) {
          disable = true
        }
      }
      if (anoAtualAtual < anoSelecionado) {
        disable = true;
      }
      var retorno =
        (
          <div key={index} className="pure-u-1-4">
            <Checkbox inputId={chk} onChange={e => this.onMesChange(e)} value={item} checked={this.state.mesesSlt.includes(item)} disabled={disable} />
            <label htmlFor={chk} className="p-checkbox-label">{item}</label>
          </div>
        );
      return retorno;
    } catch (err) {
      console.log(err);
    }
  }
  marcaTodosMeses(e) {
    try {
      if (e.checked) {
        this.setState({ todosMeses: true });
        let ano = new Date().getFullYear();
        let anoApur = this.state.anoApur.getFullYear();
        var moment = require('moment');
        moment.locale('pt-br');
        const meses = moment.months();
        if (anoApur === ano) {
          let mesesAnteriores = [];
          meses.map(function (valor, index) {
            if (new Date().getMonth() >= index) {
              return mesesAnteriores.push(valor);
            }
            return null;
          });
          this.setState({ mesesSlt: mesesAnteriores });
          console.log(mesesAnteriores);
        } else {
          console.log(anoApur);
          if (anoApur > ano) {
            console.log('ano atual maior que o ano apuração');
            this.setState({ mesesSlt: [] });
          }
          if (anoApur < ano) {
            console.log('ano atual menor que o ano apuração');
            this.setState({ mesesSlt: meses });
          }
        }

      } else {
        this.setState({ todosMeses: false, mesesSlt: [] });

      }

    } catch (err) {
      console.log(err);
    }

  }
  renderCheckMeses() {
    let meses = this.mesesDoAno();
    let retorno = meses.map(this.listaMesesCheck);
    return retorno;
  }
  mudaPassos(index) {
    //console.log("Index:"+index);
    var steps = ['Ano Apuração', 'Eventos de Folha', 'Confirmação', 'Fim'];
    this.regraBotoes(index);
    if (index === 2) {
      console.log('Atualiza Servidores');
    }
    var anterior = "#step" + this.state.activeIndex;
    $(anterior).css("display", "none");//.fadeOut("fast");
    this.setState({ activeIndex: index });
    var objeto = "#step" + index;
    if (index !== 0) {
      $(objeto).fadeIn(this.toast.show({ severity: 'info', summary: index + 'º passo -' + steps[index], detail: '' }));
    } else {
      $(objeto).fadeIn();
    }
    //$(objeto).fadeIn();
  }
  regraBotoes(index) {
    if (index === 0) {
      this.setState({ btnAnterior: true });
    } else {
      this.setState({ btnAnterior: false });
    }
    if (index === 3) {
      this.setState({ btnProximo: true });
    } else {
      this.setState({ btnProximo: false });
    }
  }

  concluiWizard() {
    this.setState({ visible: false });
    this.mudaPassos(0);
    this.atualizarLista(false);
  }
  mudarAnoApuracao(valor) {
    try {
      if (valor !== null) {
        console.log('Valor do ano: ' + valor);
        this.setState({ anoApur: valor, mesesSlt: [], todosMeses: false });
      }
    } catch (err) {

    }
  }
  valorRemuneradoTemplate(rowData) {
    let valor = rowData.valorRemuneracaoTotal;
    return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });;
  }
  valorPrevidenciaTemplate(rowData) {
    let valor = rowData.valorPrevidenciaTotal;
    return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });;
  }
  renderMesesSelecionados() {
    try {
      if (this.state.mesesSlt !== null) {
        var meses = this.state.mesesSlt;
        if (meses.length > 0) {
          return meses.map(function (valor, index) {
            var id = 'spn' + index;
            return (<span key={id} className="p-tag" style={{ marginLeft: '8px' }}>{valor}</span>);
          });
        } else {
          return (<span className="p-tag p-tag-success">Nenhum mês selecionado</span>);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  renderTipoApuracao(opcoes, indApuracao) {
    try {
      const valorSelecionado = opcoes.filter((opcao) => opcao.value == indApuracao)
      console.log(valorSelecionado)
      console.log(opcoes)
      console.log(indApuracao);

      return (<span key={valorSelecionado[0].value} className="p-tag" style={{ marginLeft: '8px' }}>{valorSelecionado[0].label}</span>);

    } catch (err) {
      console.log(err);
    }
  }

  renderPerApurPicker(indApuracao) {
    if (indApuracao == 2) {
      return (
        <div>
          <label>Ano Apuração</label><br />
          <Calendar value={this.state.anoApur} onChange={(e) => this.mudarAnoApuracao(e.value)} view="month" dateFormat="yy" yearNavigator yearRange="2018:2030" /><br />
        </div>
      )
    } else {
      return (
        <div className="pure-u-1">
          <label>Perído de Apuração</label><br />
          <Calendar id="monthpicker" value={this.state.perApur} onChange={(e) => this.setState({ perApur: e.value })} view="month" dateFormat="mm/yy" />
        </div>
      )

    }
  }

  onPerApurChange(value){
    this.setState({perApur: value})
    this.setState({mesesSlt: [(value.getMonth()+1).toString()]})
  }

  renderIndApurValue(rowData){
    return rowData.isAnual ? 'ANUAL (13º Salário)' : 'MENSAL';
  }

  render() {
    const items = [
      { label: 'Home', icon: 'pi pi-home', url: '/home' },
      { label: 'Eventos Periódicos', icon: 'pi pi-home', url: '/FolhaPagamento' },

    ];
    
    const templatePaginator = {
      layout: 'CurrentPageReport PrevPageLink NextPageLink',
      'CurrentPageReport': (options) => {
          const totalReg = options.totalRecords === null ? 0 : options.totalRecords
          const dropdownOptions = [];
          const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
          for (let i = 0; i <= totalPages; i++) {
              const item = {
                  label: i + 1,
                  value: i
              };

              dropdownOptions.push(item);
          }

          return (
              <>
                  <div className="p-grid">
                      <div className="p-col">
                          <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square ' />
                          <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => this.onChangePaginator(e, options)} />
                      </div>
                  </div>
              </>
          )
      }
  };
    return (
      <div>
        <Messages ref={(el) => this.messages = el}></Messages>
        <Panel header={"Folha de Pagamento "}>
          <h5>Gerenciamento de Eventos Periódicos</h5>
          <BreadCrumb home="principal" model={items} onSelect={(e) => this.setState({ activeIndex: e.index })} readOnly={false} />
          <br />
          <div style={{ marginBottom: '20px' }}>

            <Dialog header={"Deseja mudar o status da folha " + this.state.perApurSelecionado + "?"} visible={this.state.modalMudarStatus} style={{ width: '50vw' }}
                onHide={(e) => this.setState({ modalMudarStatus: false})}
                footer={this.renderFooterMudarStatus(this.state.desabilitaMudarStatus)}>
                {this.renderMudarStatus()}
            </Dialog>
            <DialogGeracaoEventos visible={this.state.visible} onHide={()=>{this.setState({visible: false});window.location.reload(false);}}/>
            <Configuracoes visible={this.state.configvisible} onHide={()=>{this.setState({configvisible: false});}}/>
            <Button label="Novo" icon="pi pi-plus-circle" onClick={(e) => this.setState({ visible: true })} style={{ margin: '10px' }}/>
            <Button label="Configurações" icon="pi pi-cog" onClick={(e) => this.setState({ configvisible: true })} style={{ margin: '10px' }}/>
            <br />
          </div>
          <div id="infFolhaReferencia" style={{ display: this.state.InitialLoading }}>
            <br />
            <label style={{ height: '30px', verticalAlign: 'middle' }}>
              <ProgressSpinner style={{ width: '30px', height: '30px' }} animationDuration="5s" />
              <strong>{this.state.welcome}</strong>
            </label>
          </div>

          <div id="tblEventosPaginada" style={{ marginTop: '25px' }}>
            <ProgressBar mode="indeterminate" style={{ height: '15px', display: this.state.tblLoading }} id="tblLoading" />
            <DataTable
              value={this.state.folhas}
              emptyMessage="Nenhum Evento de folha Gerado"
              className="p-datatable-sm"
              paginator={true}
              paginatorTemplate={templatePaginator}
              loading={this.state.carregando}
              totalRecords={this.state.qtdFolha}
              rows={this.state.regPagina}
              first={this.state.first}
              lazy={true}
              onPage={this.onPage.bind(this)} >
              <Column field="idFolhaPagamento" header="Id" style={{ width: "5%", textAlign: 'center' }} />
              <Column body={this.changeAnoMesApur.bind(this)} header="Prd. Apuração" style={{ width: "6%", textAlign: 'center' }} />
              <Column body={this.renderIndApurValue} header="Ind. Apuração" style={{ width: "6%", textAlign: 'center' }} />
              <Column field="statusFolha" header="Status" style={{ width: "5%", textAlign: 'center' }} />
              <Column body={this.actionTemplate} header="Opções" style={{ width: "12%" }} />
            </DataTable>
          </div>

        </Panel>
      </div>
    );
  }
}
