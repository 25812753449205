import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';

export class TblImportacao extends Component{
  constructor(){
    super();
    this.state={menu:[],usuario:{},dataPeriodo:null,codAfastamento:null };
  }
  retornaDataImportacao(rowData){
    var dataSaida = new Date(rowData.dataImportacao);
    return dataSaida.toLocaleString();
  }
  render() {
    var footer = <div style={{textAlign:'left'}}>
        <Button type="button" icon="pi pi-file-excel" iconPos="left" label="CSV" onClick={this.export}></Button>&nbsp;
        <Button type="button" icon="pi pi-file-pdf" iconPos="left" label="PDF" onClick={this.export}></Button>
    </div>;
    return(
      <div className="pure-u-5-5"  style={{marginBottom: '30px'}}>
        <DataTable footer={footer} value={this.props.eventos} rowClassName={this.rowClassName} style={{marginBottom: '30px'}} emptyMessage="Nenhum evento encontrado">
            <Column field="codEvento" header="Codigo do Evento" style={{width:'15%'}} />
            <Column field="nomeEvento" header="Descrição do Evento" style={{width:'10%'}} />
            <Column body={this.retornaDataImportacao} field="dataImportacao" header="Data de importação" style={{width:'15%'}} />
            <Column field="quantidade" header="Qtd. eventos" style={{width:'5%'}} />
        </DataTable>
      </div>
    );
  }
}