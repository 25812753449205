import $ from 'jquery';
import Utils from '../utils/Util';

const eventoService = {

    carregarDropdownEvento(grupoEvento, callback) {
        const url = grupoEvento !== null ?
            "/EsocialAPI/evento/lista/tipo/" + grupoEvento
            :
            "/EsocialAPI/evento/lista/atual";
        $.ajax({
            url: url,
            dataType: 'json',
            error: function (e) {
                console.log(e);
            },
            success: function (resposta) {
                callback(resposta);
            }.bind(this),
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); },
            error: Utils.handleAjaxError
        });
    },

    carregarEventoAtual(callback) {
        this.carregarDropdownEvento(null, callback);
    }

}

export default eventoService;