import React, { useState, useEffect, useRef } from 'react';

import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import $ from 'jquery';
import 'brace/mode/sql';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MenuBar } from '../../../componentes/MenuBar';
import AgendaDetalhe from './AgendaDetalhe';
import agendaService from '../../../services/agendaService';
import grupoService from '../../../services/grupoService';


const Agenda = () => {
    //Equivalente ao this.state do construtor do component com Classe - https://pt-br.reactjs.org/docs/hooks-state.html
    const [compromissosAgenda, setCompromissosAgenda] = useState([]);
    const [grupos, setGrupos] = useState([]);

    useEffect(() => {
        fetchAgenda();
        fetchGrupos();
    }, [])  

    function fetchAgenda() {
        agendaService.obterCompromissosAgenda(setCompromissosAgenda);
    }

    function fetchGrupos() {
        grupoService.listarGrupo(setGrupos);
    }
    const style = {
        panel: {
            marginTop: 30,
        },
        breadCrumb: {
            marginBottom: 30,
        },
        dropdown: {
            marginRight: 30
        },
        card: {
            paddingBottom: 10
        },
        button: {
            marginTop: 20,
            marginBottom: 20
        },
        label: {
            fontSize: 16,
            fontWeight: 'bold',
            display: 'block',
        }
    };
    const items = [
        { label: 'Home', icon: 'pi pi-home', url: '/home' },
        { label: 'Administração', icon: 'pi pi-home', url: '/home' },
        { label: 'Agenda', icon: 'pi pi-home', url: '/administracao/agenda' }
    ];

    function actionTemplate(row) {
        return <AgendaDetalhe agendamento={row} grupos={grupos}/>
    }


    return (
        <div>
            <MenuBar usuario="OSM" />
            <div className="content">
                <div className="content align-center">
                    <Panel header="Agenda" style={style.panel}>
                        <div>
                            <Button label="Novo" icon="pi pi-user-plus" iconPos="left" style={{ margin: '3px' }} onClick={(e) => console.log(e)} />
                        </div>
                        <div className="content-section implementation">
                            <DataTable value={compromissosAgenda} paginator={true} rows={10} emptyMessage="Desculpe, nenhum compromisso agendado.">
                                <Column field="idAgenda" header="Id" sortable={true} />
                                <Column field="titulo" header="Título" />
                                <Column field="dataInicioFormatada" header="Data Início" />
                                <Column field="dataFimFormatada" header="Data Fim" />
                                <Column body={actionTemplate} header="Ver" />
                            </DataTable>
                        </div>
                    </Panel>
                </div>
            </div>
        </div>


    );
}

export default Agenda;