
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';


const EventoEmProcessamentoPreview = (props) =>{


    const [statusEventoEmProcessamento, setStatusEventoEmProcessamento] = useState(null);
   

    useEffect(()=>{
        consultarStatusEventoEmPorcessamento();
    }, [])

    function consultarStatusEventoEmPorcessamento(){

        $.ajax({
            type: "GET",
            url: "/EsocialAPI/api/processamento/status/"+props.idEventoFila,
            contentType: "application/json; charset=utf-8",
            success: function (resposta) {
                console.log(resposta);
                setStatusEventoEmProcessamento(resposta)
            },
            beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
          });
    }

    function direcionar() {
        var url = window.location.origin + "/processamento/lote";
        console.log(url);
        window.location.replace(url);
    }

    function renderInfosDetalhadas() {
        return (
            <div>
                <h4>ID: {statusEventoEmProcessamento == null ? '': statusEventoEmProcessamento.identificador}</h4>
                <h4>Id do Lote enviado: {statusEventoEmProcessamento == null ? '': statusEventoEmProcessamento.idLote} </h4>
                <h4>Protocolo Lote enviado: {statusEventoEmProcessamento == null ? '': statusEventoEmProcessamento.protocoloLote} </h4>
                <h4>Status do lote: {statusEventoEmProcessamento == null ? '': statusEventoEmProcessamento.statusLote} </h4>
            </div>

        )
   
        
    }

    function renderFooter() {
        return (
            <span>
                <Button label="Lote" icon="pi pi-clone" style={{ margin: '10px' }} onClick={() => direcionar()} />
            </span>
        )
    }
    function renderInfoEvento(show) {
        console.log(props);
        if (show) {
            return (
                <div className="fadeInDown">
                    <Card title="Em processamento." subTitle="Evento encontra-se em processamento. Consulte manualmente o lote ou aguarde a consulta periódica automática." footer={renderFooter()}>
                        {renderInfosDetalhadas()}
                        {/* <h4>Status: {props.respostaEnvio.status}</h4> */}
                        Para consulta manual acesse a tela de lote e consulte o lote que contém o evento.
                    </Card>
                </div>
            )
        }
    }
    return (
        <div>
            {renderInfoEvento(true)}
        </div>
    )
}

export default EventoEmProcessamentoPreview;