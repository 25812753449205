import React, {Component} from 'react';
import {TabView,TabPanel} from 'primereact/tabview';
import {Documentacao} from '../../../sessoes/Processamento/preview/Documentacao.js';
import {Ajuda} from '../../../sessoes/Processamento/preview/Ajuda';


export class TabInfoEvento extends Component{
    constructor(){
      super();
  
      this.state={lista : [],eventos:[],usuario:{},xmljson:"",visiblePreview:false,sendPreview:false,xml:"ei",idEventoFila:0,ocorrencias:[]
      ,activeIndex:0,activeView:0,statusIndex:0,idEventoEsocial:0}
    }
    componentWillReceiveProps(props) {
      this.setState({idEventoFila: props.idEventoFila,idEventoEsocial:this.props.idEventoEsocial});
    }
    componentWillMount(){
    }
    render(){
      return(
        <div>
          <TabView activeIndex={this.state.activeView} onTabChange={(e) => this.setState({activeView: e.index})} style={{width:'880px'}}>
{/*               <TabPanel header="Informações do evento" leftIcon="fa fa-edit">
                <h5>{this.props.eventoEsocial}</h5>
                <Documentacao idEventoEsocial={this.props.idEventoEsocial} />
              </TabPanel> */}
              <TabPanel header="Ajuda" leftIcon="pi pi-question-circle" >
                <Ajuda idEventoEsocial={this.props.idEventoEsocial} />
              </TabPanel>
          </TabView>
        </div>
      );
    }
  }