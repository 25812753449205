import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useState, useEffect, useRef } from 'react';

const ProgressSpinnerWithMessage = (props)=>{

    function renderProgressSpinner(show, msg) {
        if (show) {
            return (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <ProgressSpinner />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                        <h4>{msg}</h4>
                    </div>
                </div>
            );
        }
    }

    return (
        <div>
            {renderProgressSpinner(props.show, props.msg)}
        </div>
        
    )
}

export default ProgressSpinnerWithMessage;
    