import React, { Component } from 'react';
import $ from 'jquery';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
export class TabelaEvtAfastamento extends Component{
    constructor(){
        super();
        this.state={idEventoFila:0,bolAtivo:false,evtValido:true, eventos:[]};
      }
      componentWillReceiveProps(props) {
        //console.log(props.eventos);
        this.setState({idObjeto: props.idObjetos});
      }
      componentDidMount(){
          this.consultaEventos();
      }
      consultaEventos(){
      $.ajax({
          url:"/EsocialAPI/api/trabalhador/afastamento/"+this.props.idObjeto,
          dataType: 'json',
          success:function(resposta){
            this.setState({eventos:resposta});
          }.bind(this),
          beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
        });
      }
  
      render() {
        return(
            <div>
              <h5>Evento de Afastamento do Trabalhador</h5>
              <hr />
                    <DataTable value={this.state.eventos} emptyMessage="Nenhum Evento de afastamento encontrado para o Servidor" >
                    <Column field="idEventoFila" header="Id" />
                        <Column field="evento" header="Evento" />
                        <Column  header="Ação"/>
                    </DataTable>
            </div>
        );
  }
  }
    