import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputMask } from 'primereact/inputmask';
import $ from 'jquery';

export class FechamentoDeFolha extends Component{
  constructor(){
    super();
    this.state={
        home :{icon: 'pi pi-home'}
        ,agendamentos:[]
        ,tipoEvento:"1"
        ,usuario:{}
        ,btnEnviar:'none'
        ,btnSalvar:'block'
        ,blocoForm:'block'
        ,blocoEnviarEvento:'none'
        ,ldngEnviando:'none'
        ,desabilitaSalvar:false
    };
  }
  enviarFechamento(){
    var baseurl = window.location.protocol + "//" + window.location.host;
    $.ajax({
      url:"/EsocialAPI/usuario/logado/",
      dataType: 'json',
      error:function(){
        window.location.href= baseurl;
      },
      success:function(resposta){
        this.setState({usuario:resposta});
        //this.qtdeMensagens();
      }.bind(this),
      beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  habilitaEnvio(){
    this.setState({btnSalvar:'none',btnEnviar:'block'});
  }
  enviarEvento(){
    this.setState({blocoForm:'none',blocoEnviarEvento:'block',btnEnviar:'none'});
  }
  trataTelefone(){
      let telefone = this.props.responsavelTecnico.telefone;
      var cleaned = ('' + telefone).replace(/\D/g, '');
      console.log(cleaned);
      var match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return null

  }
  render() {
    return(
        <div>
            <div style={{display:this.state.blocoEnviarEvento}}>
            <ProgressSpinner style={{width: '35px', height: '30px'}} animationDuration="5s"/>Enviando o evento de informação complementar 
            </div>
            <div style={{display:this.state.blocoForm}}>
                <div>
                    <em>Deseja gerar o evento de fechamento?</em>
                    <Button icon="pi pi-check" className="p-button-rounded p-button-text" onClick={(e)=> this.setState({desabilitaSalvar:false})} />
                    <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" onClick={(e)=> this.setState({desabilitaSalvar:true})} />
                </div>
                <div>
                    <label><strong>Responsável Técnico:</strong></label><label style={{marginLeft:'12px'}}>{this.props.responsavelTecnico.nome}</label><br />
                    <label><strong>CPF:</strong></label><label style={{marginLeft:'12px'}}>{this.props.responsavelTecnico.cpf}</label><br />
                    <label><strong>Email:</strong></label><label style={{marginLeft:'12px'}}>{this.props.responsavelTecnico.email}</label><br />
                    <label><strong>Telefone:</strong></label><label style={{marginLeft:'12px'}}>{this.trataTelefone()}</label><br />
                </div>
            </div>
            <div style={{marginTop:'15px'}}>
                <Button label="Salvar" icon="pi pi-save" style={{display:this.state.btnSalvar}} onClick={(e) => this.habilitaEnvio() } disabled={this.state.desabilitaSalvar}/>
                <Button label="Enviar" icon="pi pi-envelope" style={{display:this.state.btnEnviar}} onClick={(e) => this.enviarEvento() }/>
          </div>
    </div>
    );
  }
}
