import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';

export class FrmInfoComplementares extends Component{
  constructor(){
    super();
    this.state={
        home :{icon: 'pi pi-home'}
        ,agendamentos:[]
        ,infoSubstPatr:null
        ,indApuracao:null
        ,mesApuracao:null
        ,anoApuracao:null
        ,indDesFolha:null
        ,percentNSubstContrib:null
        ,fatorMes:null
        ,fatorDT:null
        ,btnEnviar:'none'
        ,btnSalvar:'block'
        ,blocoForm:'block'
        ,blocoEnviarEvento:'none'
        ,ldngEnviando:'none'
    };
  }
  componentWillMount(){
    this.setState({mesApuracao:this.props.mesApur,anoApuracao:this.props.anoApur});
  }
  habilitaEnvio(){
    this.setState({btnSalvar:'none',btnEnviar:'block'});
  }
  enviarEvento(){
    this.setState({blocoForm:'none',blocoEnviarEvento:'block',btnEnviar:'none'});
  }
  render() {
      const infoSubstPatrOpt=[
          {name:'Integralmente Substituida',value:'1'},
          {name:'Parcialmente substituida',value:'2'},
      ];
    return(
      <div style={{textAlign:'left'}}>
        <div style={{display:this.state.blocoEnviarEvento}}>
        <ProgressSpinner style={{width: '35px', height: '30px'}} animationDuration="5s"/>Enviando o evento de informação complementar 
        </div>
        <div style={{display:this.state.blocoForm}}>
        <div style={{display:'inline-block',marginRight:'25px'}}>
          <div style={{display:'inline-block'}}>
              <div>IndApuracao</div>
              <div>
                {this.props.anoApuracao}
                <InputText value={this.state.indApuracao} onChange={(e) => this.setState({indApuracao:e.target.value})} /> 
              </div>
          </div><br />
          <div style={{display:'inline-block'}}>
              <div>Mes Apuracao</div>
              <div>                  
                <InputText value={this.state.mesApuracao} onChange={(e) => this.setState({mesApuracao:e.target.value})} /> 
              </div>
          </div><br />
          <div style={{display:'inline-block'}}>
              <div>Ano Apuracao</div>
              <div>
                <InputText value={this.state.anoApuracao} onChange={(e) => this.setState({anoApuracao:e.target.value})} /> 
              </div>
          </div><br />
          <div style={{display:'inline-block'}}>
              <div>IndDesFolha</div>
              <div>
                <InputText value={this.state.indDesFolha} onChange={(e) => this.setState({indDesFolha:e.target.value})} /> 
              </div>
          </div>
          </div>
            <div style={{display:'inline-block'}}>
              <div style={{display:'inline-block'}}>
                  <div>infoSubstPatr</div>
                  <div><Dropdown optionLabel="name" value={this.state.infoSubstPatr} options={infoSubstPatrOpt} onChange={(e) => this.setState({infoSubstPatr:e.value})} placeholder="infoSubstPatr"/></div>
              </div><br />
              <div style={{display:'inline-block'}}>
                  <div>Percentual não substituido pela contribuição</div>
                  <div>
                    <InputText value={this.state.percentNSubstContrib} onChange={(e) => this.setState({percentNSubstContrib:e.target.value})} />
                  </div>
              </div><br />
              <div style={{display:'inline-block'}}>
                  <div>Fator mês</div>
                  <div>
                    <InputText value={this.state.fatorMes} onChange={(e) => this.setState({fatorMes:e.target.value})} /> 
                  </div>
              </div><br />
              <div style={{display:'inline-block'}}>
                  <div>Fator 13º</div>
                  <div>
                    <InputText value={this.state.fatorDT} onChange={(e) => this.setState({fatorDT:e.target.value})} /> 
                  </div>
              </div>
            </div>
          </div>
          <div style={{marginTop:'15px'}}>
            <Button label="Salvar" icon="pi pi-save" style={{display:this.state.btnSalvar}} onClick={(e) => this.habilitaEnvio() }/>
            <Button label="Enviar" icon="pi pi-envelope" style={{display:this.state.btnEnviar}} onClick={(e) => this.enviarEvento() }/>
          </div>
      </div>
    );
  }
}