import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import React, { Component, useEffect, useRef } from 'react';
import { useState } from 'react';
import $ from 'jquery';
import { ProgressBar } from 'primereact/progressbar';
import { Messages } from 'primereact/messages';

const Configuracoes = (props) => {

    const msgs = useRef(null);
    const [importaAutomatico, setImportaAutomatico] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isBtnGeracaoDisabled, setIsBtnGeracaoDisabled] = useState(true);


    useEffect(()=>{
        fetch('/EsocialAPI/folha/configuracoes', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
            },
            })
            .then(response => response.text())
            .then(data => {
                const configuracoes = JSON.parse(data);
                setImportaAutomatico(configuracoes.importacaoAutomatica1210);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [])

    const addMessages = (msg) => {
        msgs.current.show([
            msg
        ]);
    }

    function salvarConfiguracao(){

        const novaConfiguracao = {
            importacaoAutomatica1210: importaAutomatico
        }

        fetch('/EsocialAPI/folha/configuracoes/salvar', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
            },
            body: JSON.stringify(novaConfiguracao),
            })
            .then(response => response.text())
            .then(data => {
                const mensagemDTO = JSON.parse(data);
                addMessages(mensagemDTO);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const options = [
        {label: 'Sim', value: true},
        {label: 'Não', value: false}
    ]

    function renderContentDialog(){
        return (
            <Card className="p-fluid p-grid">
                <label htmlFor="anomesapur">Importação Automática S-1210</label>
                <SelectButton value={importaAutomatico} options={options} onChange={(e) => setImportaAutomatico(e.value)}  />
            </Card>
        )
    }



    const footer = (
        <div>
            <Button label="Salvar" rightIcon="pi pi-save" onClick={() => salvarConfiguracao()} />
        </div>
    );
    return (
        <div>
            <Dialog header="Geração de Folha de Pagamento" style={{ width: '40%' }} footer={footer} visible={props.visible} modal={true} onHide={() => props.onHide()}>
                <Messages ref={msgs} />
                {isLoading ? <ProgressBar mode="indeterminate" /> : ''}
                {renderContentDialog()}
                

            </Dialog>
        </div>
    )

}

export default Configuracoes;