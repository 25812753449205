import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

export class FiltroEventosEnviados extends Component {

  constructor(){
    super();
    this.state = {}
  }
  componentDidMount() {

  }



  renderDropDown(value, options, onChangeFunction, placeholder) {
    return (
      <Dropdown
        value={value}
        options={options}
        style={{ width: '70%' }}
        onChange={(e) => { onChangeFunction(e.value) }}
        placeholder={placeholder}
      />
    );
  }

  
  render() {
    const style = {
      label: {
          fontSize: 16,
          fontWeight: 'bold',
          display: 'block',
      },
      dropDown: {
          width: '80%'
      },
      inputText: {
          width: '80%'
      },
      inputContainer: {
          paddingTop: '10px',
          paddingBottom: '10px'
      },
      toolbar: {
          margin: 0,
          padding: 0,
      },
    }
    const isPer = this.props.grupos.find(item => item.value === this.props.grupo)?.label === 'PERIÓDICO';

    const currentYear = new Date().getFullYear();
    const anos = [];
    for (let year = currentYear; year >= 2018; year--) {
        anos.push(year);
    }

    const meses = [
      { label: "Janeiro", value: 1 },
      { label: "Fevereiro", value: 2 },
      { label: "Março", value: 3 },
      { label: "Abril", value: 4 },
      { label: "Maio", value: 5 },
      { label: "Junho", value: 6 },
      { label: "Julho", value: 7 },
      { label: "Agosto", value: 8 },
      { label: "Setembro", value: 9 },
      { label: "Outubro", value: 10 },
      { label: "Novembro", value: 11 },
      { label: "Dezembro", value: 12 },
  ];

    const indApuracao = [{label: 'Mensal', value:1}, {label: 'Anual', value:2}]
    return (

      <div>
        <div style={style.inputContainer} className="pure-g">

          <div className="pure-u-1-3">
            <label style={style.label}>Tipo de evento: </label>
            {this.renderDropDown(this.props.grupo, this.props.grupos, this.props.onChangeGrupo, 'Selecione o Grupo')}
          </div>

          <div className="pure-u-1-3">
            <label style={style.label}>Eventos: </label>
            {this.renderDropDown(this.props.evento, this.props.eventos, this.props.onChangeEvento, 'Selecione o Evento')}
          </div>
        </div>

        {!isPer ? '' : 
          <div style={style.inputContainer} className="pure-g">
            <div className="pure-u-1-3">
              <label style={style.label}>Mês: </label>
              {this.renderDropDown(this.props.mes, meses, this.props.onChangeMes, 'Selecione o Mês')}
            </div>

            <div className="pure-u-1-3">
              <label style={style.label}>Ano: </label>
              {this.renderDropDown(this.props.ano, anos, this.props.onChangeAno, 'Selecione o Ano')}
            </div>

            <div className="pure-u-1-3">
              <label style={style.label}>Ind. Apuração: </label>
              {this.renderDropDown(this.props.indApuracao, indApuracao, this.props.onChangeIndApuracao, 'Selecione o Ind. Apuração')}
            </div>
          </div>
        }
        <div style={style.inputContainer} className="pure-g">
          <div className="pure-u-1-1">
            <Button label="Consultar" disabled={this.props.buttonDisabled} icon="pi pi-search" iconPos="left" onClick={this.props.onClick} style={{ float: 'right' }} />
          </div>
        </div>
      </div>

    );
  }
}