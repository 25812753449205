import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Messages} from 'primereact/messages';
import {GrupoTabela} from '../sessoes/Grupo/GrupoTabela.js';
import{ MenuBar } from '../componentes/MenuBar';
import {PermissionCheck} from '../componentes/PermissionCheck';
import usuarioService from '../services/usuarioService.js';

export class Grupo extends Component{
  constructor(){
    super();
    this.state={home :{icon: 'pi pi-home'},agendamentos:[],tipoEvento:"tabela",usuario:{}};
  }
  componentDidMount(){
    this.consultaUsuario();
  }
  consultaUsuario(){
    usuarioService.consultaUsuario((usuario) => {
      this.setState({ usuario });
    });
  }
  render() {
    return(
      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content">
          <div className="content align-center">
              <Messages ref={(el) => this.messages = el}></Messages>
              <div className="pure-u-5-5">
                <GrupoTabela />
              </div>
          </div>

            </div>
            <div className="footer l-box is-center">
              <PermissionCheck usuario={this.state.usuario} url="/grupos"  />
            </div>

      </div>
    );
  }
}
