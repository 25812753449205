import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Button} from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import $ from 'jquery';

export class TabHistorico extends Component{
    constructor(){
        super();
        this.state={idEventoFila:0,bolAtivo:false,activeIndex:0,evtValido:false};
      }
      render() {
        const footer = (
            <div>
                <Button label="Enviar" icon="pi pi-check" onClick={(e) => this.enviar()} disabled={this.state.evtValido} />
                <Button label="Cancelar" icon="pi pi-times" onClick={(e) => this.setState({bolAtivo:false})} />
            </div>
        );

      return(
      <div>
          <div>
            <strong>Historico de atividades de folha de pagamento.</strong>
          </div>
          <div>
            <DataTable value={this.state.products}>
                <Column field="idHistFolhaPagamento" header="#" style={{width:'5%'}}></Column>
                <Column field="datacricao" header="Data de criação"style={{width:'10%'}}></Column>
                <Column field="datacricao" header="Ação"style={{textAlign:'right'}}></Column>
            </DataTable>
          </div>
      </div>
      );
    }
}