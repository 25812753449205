import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import Alert from 'react-bootstrap/Alert';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandshake, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip';
import Utils from '../../../utils/Util';

export class ListaErros extends Component{
  constructor(){
    super();
    this.state ={listaErros: [], listaPrerequisitos:[],carregando:'block'}
    this.renderBadgeEventoRequisito=this.renderBadgeEventoRequisito.bind(this);
  }
  
  
  montaObjetoErro(listaErro){
    var dados = [];
    if(listaErro !== undefined && listaErro != null){
      listaErro.forEach(element => {
        var dado = {erro: element};
        dados.push(dado);
      });
    }

    return dados;
  }
  escondeCarga(){
    this.setState({carregando:'none'});
  }
  templateSucesso(rowData, column){
    return (
      <Alert variant="success">
        <div style={{fontSize: '130%', textAlign:'center', marginTop: '20px', marginBottom:'20px'}}>{rowData.erro+"!"}</div>
      </Alert>
    );
    
  }
  templateErro(rowData,column){
    return (
      
      <label style={{fontSize:'10px'}}><Button className="p-button-danger" icon="pi pi-times-circle" iconPos="left" />{rowData.erro}</label>
    );
  }
  componentWillReceiveProps(props){
    //console.log(props); 
  }
  
  renderBadgeEventoRequisito(req){
    console.log(req);
    try{
    const retorno = req.map((el)=>{
    //  console.log(el.classeBotao);
      return (
        <div style={{display: "inline-block",marginRight:'5px'}}key={el.idEvento}>
          <span className={el.classeBotao} data-tip={el.descricao}>{el.codEvento}</span>
          <ReactTooltip place="top" type="light" effect="float"/>
        </div>
      ) ; 
    });
    }catch(erro){
      console.log(erro);
    }
    //console.log(retorno);
    //return retorno;
  }
  renderDataTable(listaErros){    
    var dataTable;
    
    if(listaErros!==null && listaErros!==undefined){
      if(listaErros[0]!=="Evento pronto para Envio"){
        let erros = this.montaObjetoErro(listaErros);
        dataTable = (          
          <div>
          { erros.map(function(name,index){
            let er = name.erro;
            return (<div id={index} key={index} style={{listStyle:'none',padding:'5px 5px 5px 5px',margin:'5px 15px 5px 5px',backgroundColor:'#edf1f7', border:'solid 1px #ffa187',verticalAlign:'middle'}}>
                      <FontAwesomeIcon icon={faExclamationTriangle} style={{color: 'Tomato'}} />
                      <label style={{fontSize:'12px',marginLeft:'5px'}}>
                        {er}
                      </label>
                    </div>
                   )
          })}
        </div>
        );

      }else{
        dataTable = (
          <div style={{backgroundcolor:'#b5ffbf',textAlign:'center',color:'#00b064',border:'solid 1px #00b064',padding:'15px 5px 5px 5px',margin:'5px 15px 5px 5px'}}>
            <FontAwesomeIcon icon={faHandshake} style={{color:'#00b064'}} />
            <label style={{fontSize:'18px',}}>Evento pronto para envio ao eSocial.</label>
          </div>
        );
      }
    }else{
      console.log(listaErros);
      dataTable = (
        <DataTable value={this.montaObjetoErro(listaErros)}>
          <Column field="erro" header="Erro!" body={this.templateErro}/>
        </DataTable>
      );
    }
    return dataTable;
  }

  renderEventosPreRequisitos(listaPreRequisito){
    if(Utils.isObjectHasValue(listaPreRequisito) && listaPreRequisito!=undefined && listaPreRequisito!=null){
      return (
        <div>
          <label>Eventos pré-requisitos :</label>&nbsp;
          {this.renderBadgeEventoRequisito(listaPreRequisito)}
        </div>
      );
    }
  }
  render(){
   return(
      <div style={{height:"400px",textAlign:"left"}}>
        <h5>Favor conferir as informações antes de Enviar.</h5>
        <div style={{border:'1px solid #e3e3e3',backgroundColor:'#e8fdff',fontWeight:'strong',padding:'3px 3px 3px 3px'}}>
          <em>Verificar sempre se os eventos pré requisitos estão com status processados</em>
        </div>
        <div>
            {/*this.renderEventosPreRequisitos(this.props.listaPreRequisito)*/}
        </div>
        <div style={{overflowY: "scroll", height:"320px"}}>
          {this.renderDataTable(this.props.listaErros)}
        </div>
                  
      </div>
    );
  }
}