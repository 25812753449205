import React, { Component } from 'react';
import $ from 'jquery';
import { Preview } from '../../sessoes/Processamento/preview/preview';
import { AdmissaoView } from '../Trabalhador/View/AdmissaoView';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { AdmissaoSemVinculoView } from './View/AdmissaoSemVinculoView';

export class TabelaEvtAdmissao extends Component {
  constructor() {
    super();
    this.state = {
      idEventoFila: 0,
      bolAtivo: false,
      evtValido: true,
      eventos: null
    }
    this.consultaEventos = this.consultaEventos.bind(this);
    this.renderTrabalhador = this.renderTrabalhador.bind(this);
  }
  componentWillReceiveProps(props) {
    //console.log(props.eventos);
    this.setState({ idObjeto: props.idObjetos });
  }
  renderDependente(dependente) {

  }
  renderObservacoes(observacao) {

  }
  renderTreinamentoCapacitacao(codTreiCap) {

  }
  componentDidMount() {
    this.consultaEventos();
  }
  renderTrabalhador(evt) {
    try {
      let evento = JSON.parse(evt.json);
      console.log(evento.evtAdmissao.vinculo);
      this.setState({ trabalhador: evento.evtAdmissao.trabalhador, vinculo: evento.evtAdmissao.vinculo });
    } catch (error) {
      console.log(error);
    }

  }
  consultaEventos() {
    $.ajax({
      url: "/EsocialAPI/api/trabalhador/admissao/" + this.props.idObjeto,
      dataType: 'json',
      success: function (resposta) {
        this.setState({eventos:resposta});
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  actionTemplate(rowData, column) {
    console.log(rowData);
    //
    return (
      <div>        
        <Preview idEventoFila={rowData['idEventoFila']} eventoEsocial={rowData['eventoEsocial']} idEventoEsocial={rowData['idEventoEsocial']} />
      </div>
    );
  }
  actionView(rowData,column){
    console.log(rowData.eventoEsocial.codeventomensageria)
    
    if(rowData.eventoEsocial.codeventomensageria==2300){
      return (
        <div>
          <AdmissaoSemVinculoView idEventoFila={rowData.idEventoFila} />
        </div>
      );
    }else{
      return (
        <div>
          <AdmissaoView idEventoFila={rowData.idEventoFila} />
        </div>
      );
    }
    
  }
  renderData(rowData){
    //console.log(rowData);
    let dataCriacao = new Date(rowData.dataCriacao);
    console.log(dataCriacao.toLocaleDateString());
    return dataCriacao.toLocaleDateString();
  }

  render() {
    return (
      <div>
        <h5>Evento de Admissão do Trabalhador</h5>
        <hr />
        <DataTable value={this.state.eventos} emptyMessage="Nenhum Evento de admissão encontrado para o Servidor" >
          <Column field="idEventoFila" header="Id" style={{width:'5em'}} />
          <Column header="Natureza" field="acao" />
          <Column header="Status" field="status" />
          <Column header="Data Inclusão" body={this.renderData} />
          <Column header="Visualizar" body={this.actionView} style={{textAlign:'center'}} />
          <Column header="Ação" body={this.actionTemplate} style={{textAlign:'center'}} />
        </DataTable>
      </div>
    );
  }
}
