import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Button} from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import $ from 'jquery';


export class TabInfoComplementar extends Component{
    constructor(){
        super();
        this.state={idEventoFila:0,bolAtivo:false,activeIndex:0,evtValido:false,eventos:[]};
      }
      render() {
        const footer = (
            <div>
                <Button label="Enviar" icon="pi pi-check" onClick={(e) => this.enviar()} disabled={this.state.evtValido} />
                <Button label="Cancelar" icon="pi pi-times" onClick={(e) => this.setState({bolAtivo:false})} />
            </div>
        );

      return(
      <div>
          <div>              
            <h3>Informações complementares aos eventos periódicos</h3>              
          </div>
          <div>
          <DataTable value={this.state.eventos}>
            <Column field="code" header="Id" style={{width:'5%'}}></Column>
            <Column field="name" header="Data Saída"></Column>
            <Column field="category" header="Data Entrada"></Column>
            <Column field="quantity" header="Resumo" style={{textAlign:'right'}}></Column>
          </DataTable>
          </div>
      </div>
      );
    }
}