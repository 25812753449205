import React, {Component} from 'react';
import { LegendaCor } from './LegendaCor';

export class GridLegendaCor extends Component {
    
    montaGridLegendaCor(listaLegenda){
        var listaLegendaCor = listaLegenda.map((element)=>{
            return <LegendaCor legenda={element.legenda} cor={element.cor} key={element.legenda}/>
        });
        return listaLegendaCor;
    }
    render(){
        return(
            <div id="gridLegendaCor" style={{'display':'inline-block'}}>
                {this.montaGridLegendaCor(this.props.dados)}
            </div>
        );
    }
}