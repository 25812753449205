import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import $ from 'jquery';
import { Dialog } from 'primereact/dialog';
import XMLViewer from 'react-xml-viewer';


export class TblEventosInconsistentes extends Component {
  constructor() {
    super();
    this.state = { menu: [], usuario: {}, dataPeriodo: null, codAfastamento: null, displayBasic: false, visiblePreview:false, xmlOcorrencia:'' };
    this.exportXls = this.exportXls.bind(this);
    this.exportPdf = this.exportPdf.bind(this);
    this.retornaBtnRelatorio = this.retornaBtnRelatorio.bind(this);
    this.handleOcorrenciaButtonClick = this.handleOcorrenciaButtonClick.bind(this);
  }
  retornaDataEntrada(rowData) {
    var dataSaida = new Date(rowData.dataCriacao);
    return dataSaida.toLocaleString();
  }
  retornaDataSaida(rowData) {
    var dataSaida = new Date(rowData.dataEnvio);
    return dataSaida.toLocaleString();
  }
  retornaDetalhes(rowData) {
    var dataSaida = JSON.parse(rowData.detalhe);//  new Date(rowData.dataEnvio);
    return dataSaida.matricula + ' - ' + dataSaida.nome;
  }
  mostraErrosQtd(rowData) {
    if (rowData !== null) {
      return this.mostraErros();
    }
  }
  mostraErros() {
    return (<div></div>);
  }
  montaFiltroEvtEnviados(ext) {
    var filtroEvtEnviados = {
      idGrupo: this.props.idGrupo,
      idEvento: this.props.idEvento,
      extensao: ext
    }
    return filtroEvtEnviados;
  }
  retornaErroInconsist(evento) {
    const erros = evento.erros;
    let errosComponent = erros.map((erro, index) => {
      return <p key={index} style={{ fontWeight: 'bold' }}>{erro}</p>
    })
    return errosComponent;
  }
  imprimeRelatorio(extRelatorio) {
    var urlEventos = '/EsocialAPI/relatorio/eventos/inconsistentes/';
    var dados = JSON.stringify(this.montaFiltroEvtEnviados(extRelatorio));
    $.ajax({
      type: 'post',
      url: urlEventos,
      dataType: 'application/pdf',
      data: dados,
      contentType: 'application/json',
      error: function (xhr, error, response) {
        console.log('erro');
        console.log(error);
        var filename = "";
        var disposition = xhr.getResponseHeader('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]=((['"]).?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
        }

        var type = xhr.getResponseHeader('Content-Type');
        var blob = new Blob([response], { type: type });

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(blob, filename);
        } else {
          console.log('Não sou IE');
          var URL = window.URL || window.webkitURL;
          var downloadUrl = URL.createObjectURL(blob);

          if (filename) {
            var a = document.createElement("a");
            if (typeof a.download === 'undefined') {
              window.location.href = downloadUrl;
            } else {
              a.href = downloadUrl;
              a.download = filename;
              a.target = "_blank";
              document.body.appendChild(a);
              a.click();
            }
          } else {
            window.location.href = downloadUrl;
          }

          setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
        }
      },
      success: function (response, status, xhr) {
        var filename = "";
        var disposition = xhr.getResponseHeader('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]=((['"]).?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
        }

        var type = xhr.getResponseHeader('Content-Type');
        var blob = new Blob([response], { type: type });
        console.log(blob);
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(blob, filename);
        } else {
          var URL = window.URL || window.webkitURL;
          var downloadUrl = URL.createObjectURL(blob);

          if (filename) {
            var a = document.createElement("a");
            if (typeof a.download === 'undefined') {
              window.location.href = downloadUrl;
            } else {
              a.href = downloadUrl;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
            }
          } else {
            window.location.href = downloadUrl;
          }

          setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
        }
      },
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  retornaBtnRelatorio(evt) {
    console.log(evt)
    if (evt.situacao == 'REJEITADO') {
      return (
        <div style={{ textAlign: 'left' }}>
          <Button type="button" className="p-button-danger" icon="pi pi-file-excel" iconPos="left" onClick={() => this.handleOcorrenciaButtonClick(evt.id)}></Button>&nbsp;
          {/* <Button type="button" icon="pi pi-file-excel" iconPos="left" onClick={this.exportXls}></Button>&nbsp;
        <Button type="button" icon="pi pi-file-pdf" iconPos="left" onClick={this.exportPdf}></Button> */}
        </div>
      );
    }
  }

  handleOcorrenciaButtonClick(idEventoFila) {
    $.ajax({
      url: "/EsocialAPI/api/processamento/visualizar/historico/" + idEventoFila,
      type: 'GET',
      error: function (error) {

        console.log(error);
        //alert(error);
      },
      success: function (retorno) {
        console.log(retorno);
        // Retorna o ultimo histórico do eventoFila
        const idsHistorico = retorno.map((hist) => hist.idHistEventoMensageria)
        const maiorId = Math.max(...idsHistorico)
        const ultimoHist = retorno.find((hist) => hist.idHistEventoMensageria == maiorId);
        console.log(ultimoHist);
        this.setState({ xmlOcorrencia: ultimoHist.xmlEntrada });
        this.setState({ visiblePreview: true });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  exportPdf() {
    if (this.props.eventos.length > 0) {
      console.log(this.props);
      this.imprimeRelatorio('pdf');
    } else {
      console.log('Nenhum evento com inconsistência');
    }
  }
  exportXls() {
    console.log("teste")
    if (this.props.eventos.length > 0) {
      this.imprimeRelatorio('xls');

    } else {
      console.log('Nenhum evento com inconsistência');
    }
  }
  render() {
    return (
      <div className="pure-u-5-5" style={{ marginBottom: '30px' }}>
        <Dialog header="Visualização Ocorrência" visible={this.state.visiblePreview} style={{ width: '50vw' }} modal onHide={() => this.setState({ visiblePreview: false })}>
          <XMLViewer xml={this.state.xmlOcorrencia} />
        </Dialog>
        <DataTable value={this.props.eventos} rowClassName={this.rowClassName} style={{ marginBottom: '30px' }} emptyMessage="Nenhuma inconsistencia encontrada" paginator={true} rows={50} >
          <Column field="id" header="Id" style={{ width: '5%' }} />
          <Column field="codEvento" header="Cod. Evento" style={{ width: '8%' }} />
          <Column field="situacao" header="Situação" style={{ width: '10%' }} />
          <Column field="detalhe" header="Detalhe" style={{ width: '20%' }} />
          <Column body={this.retornaErroInconsist} header="Inconsistências" />
          {/* <Column body={this.retornaBtnRelatorio} header="Rejeições" style={{ width: '10%' }} /> */}
        </DataTable>
      </div>
    );
  }
}
