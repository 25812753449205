const Utils = {
    isObjectHasValue: (obj) => {
        if (obj && Object.keys(obj).length === 0 && obj.constructor === Object) {
            return false;
        }
        return true;
    },
    timeStampToDate: (timestamp) => {
        var date = new Date(timestamp);
        var day = date.getDate();
        var month = date.getMonth() + 1; // Os meses são indexados a partir de 0
        var year = date.getFullYear();

        // Adicionar zero à esquerda se o dia ou mês tiverem apenas um dígito
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        const dataFormatada = day + "/" + month + "/" + year;
        return dataFormatada
    },
    handleAjaxError(xhr, status, error) {
        if (xhr.status === 403) {
            window.location.href = "/";
        } else {
            console.log(error);
        }
    },

    timestampToHumanDate: (timestamp) => {

        const date = new Date(timestamp);
        console.log(date);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        // Format the date components into a human-readable format
        const humanDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day} ${hours}:${minutes}:${seconds}`;

        return humanDate;
    },
    stringToDate: (dateString) => {


        // Substituir o espaço entre a data e a hora por 'T' para o formato ISO 8601
        var isoDateString = dateString.replace(' ', 'T');

        // Criar um objeto Date
        var date = new Date(isoDateString);

        // Verificar se a data é válida
        if (isNaN(date.getTime())) {
            throw new Error('Data inválida');
        }

        // Retornar o timestamp
        return date;
    },
    nvl:(value, defaultValue)=>{
        return value == null ? defaultValue : value;
    },
    formatValue: (value) => {
        return value ? value.toFixed(2).replace('.', ',') : '0,00';
    },
    calculateSum: (info, propertyName) => {
        return info.reduce((sum, item) => sum + (parseFloat(item[propertyName]) || 0), 0);
    }
}

export default Utils;