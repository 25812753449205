import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../../../css/statusTabela.css';
import { Messages } from 'primereact/messages';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { MenuBar } from '../../../componentes/MenuBar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import usuarioService from '../../../services/usuarioService';
import testeEnvioService from '../../../services/testeEnvioService';

export class TesteEnvio extends Component {
    constructor() {
        super();
        this.state = {
            email: null,
            mensagem: null,
            usuario: {},
        };
        this.enviar = this.enviar.bind(this);
    }

    enviar() {
        if(this.state.email === null) {
            this.showMessage('', 'Preencha o campo de e-mail.', 'warning');
        } else {
            const dados = {
                email: this.state.email,
                mensagem: this.state.mensagem,
            };

            testeEnvioService.enviar(
                dados,
                (mensagemDTO) => {
                    this.showMessage('', mensagemDTO.mensagem, mensagemDTO.tipoMensagem);
                },
                (error) => {
                    console.error('Error:', error);
                }
            );
        }
    }

    componentDidMount() {
        this.consultaUsuario();
    }

    componentWillUnmount() {
    }

    consultaUsuario() {
        usuarioService.consultaUsuario((usuario) => {
            this.setState({ usuario });
        });
    }

    handleEmailChange(email) {
        this.setState({ email });
    }

    handleMensagemChange(mensagem) {
        this.setState({ mensagem });
    }

    showMessage(titulo, mensagem, tipoMensagem) {
        this.messages.show({ severity: tipoMensagem, summary: titulo, detail: mensagem });
    }

    render() {
        const items = [
            { label: 'Home', icon: 'pi pi-home', url: '/home' },
            { label: 'Administração', icon: 'pi pi-home', url: '/home' },
            { label: 'Teste Envio', icon: 'pi pi-home', url: '/teste-envio' }
          ];

        const style = {
            label: {
                fontSize: 16,
                fontWeight: 'bold',
                display: 'block',
            },
            dropDown: {
                width: '80%'
            },
            inputText: {
                width: '80%'
            },
            inputContainer: {
                paddingTop: '10px',
                paddingBottom: '10px'
            },
            toolbar: {
                margin: 0,
                padding: 0,
            },
        }

        const rightContents = (
            <React.Fragment>
                <Button style={{ margin: '4px' }} onClick={() => this.enviar()} tooltip="Enviar" tooltipOptions={{ position: 'right' }} label="Enviar" icon="pi pi-play"/>
            </React.Fragment>
        );

        return (
            <div>
                <MenuBar usuario={this.state.usuario} />
                <div className="content" >
                    <div className="content align-center">
                        <BreadCrumb home="principal" model={items} />
                        <div>
                            <Panel header="Teste de envio de e-mail">
                                <Messages ref={(el) => (this.messages = el)}></Messages>

                                <div style={style.inputContainer} className="pure-g">
                                    <div className="pure-u-1-3">
                                        <label style={style.label}>E-mail: </label>
                                        <InputText
                                            id="email"
                                            value={this.state.email}
                                            onChange={(e) => this.handleEmailChange(e.target.value)}
                                            style={style.inputText}
                                        />
                                    </div>
                                    <div className="pure-u-1-3">
                                        <label style={style.label}>Mensagem: </label>
                                        <InputText
                                            id="mensagem"
                                            value={this.state.mensagem}
                                            onChange={(e) => this.handleMensagemChange(e.target.value)}
                                            style={style.inputText}
                                        />
                                    </div>
                                </div>
                            </Panel>
                            <Toolbar right={rightContents} style={style.toolbar}/>
                        </div>
                    </div>
                </div>
                <div className="footer l-box is-center">
                    
                </div>
            </div>
        );
    }
}
