import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Button} from 'primereact/button';
import { Dialog } from 'primereact/dialog';


export class DialogErros extends Component{
    constructor(){
      super();
      this.state={dialogo:false, cadastroValido:false};
    }
    brandTemplate(rowData) {
      var erros = [];
      var src = "/img/icons/Success.svg";
      if(rowData.tipoArquivoCqc == 'PROCESSADO'){
        if(rowData.codNisInv!=='0'){src = "/img/icons/Error.svg"; erros.push('Erro : NIS inválido')}
        if(rowData.codCpfInv!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : CPF inválido")}
        if(rowData.codNomeInv!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : NOME inválido")}
        if(rowData.codDnInv!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : DN inválida")}
        if(rowData.codCnisNis!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : NIS inconsistente")}
        if(rowData.codCnisDn!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : Data de Nascimento informada diverge da existente no CNIS.")}
        if(rowData.codCnisObito!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : NIS com óbito no CNIS")}
        if(rowData.codCnisCpf!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : CPF informado diverge do existente no CNIS")}
        if(rowData.codCnisCpfNaoInf!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : CPF não preenchido no CNIS")}
        if(rowData.codCpfNaoConsta!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : CPF informado não consta no Cadastro CPF")}
        if(rowData.codCpfNulo!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : CPF informado NULO no Cadastro CPF")}
        if(rowData.codCpfCancelado!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : CPF informado CANCELADO no Cadastro CPF")}
        if(rowData.codCpfSuspenso!=='0'){src = "/img/icons/Error.svg";erros.push("Erro :  CPF informado SUSPENSO no Cadastro CPF")}
        if(rowData.codCpfDn!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : Data de Nascimento informada diverge da existente no Cadastro CPF.")}
        if(rowData.codCpfNome!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : NOME informado diverge do existente no Cadastro CPF.")}
        if(rowData.codOrientacaoCpf!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : Procurar Conveniadas da RFB( 1).")}
        if(rowData.codOrientacaoNis=='1'){
          src = "/img/icons/Error.svg";erros.push("Erro : Atualizar NIS no INSS (1)")
        }
        if(rowData.codOrientacaoNis=='2'){
          src = "/img/icons/Error.svg";erros.push("Erro : Verifique os dados digitados. Se estiverem corretos, antes de realizar a atualização cadastral do PIS ou PASEP, é necessário verificar o vínculo empregatício atual: 1- se vinculado à empresa privada, a atualização cadastral deve ser solicitada na CAIXA; 2- se vinculado a órgão público, a atualização cadastral deve ser solicitada no Banco do Brasil. Obs: a atualização cadastral poderá ser realizada pelo interessado ou pela empresa/órgão público.(2)")
        }
      }else{
        if(rowData.codCpfInv!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : CPF inconsistente")}
        if(rowData.codNisInv!=='0'){src = "/img/icons/Error.svg"; erros.push('Erro : NIS inconsistente')}
        if(rowData.codNomeInv!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : NOME inconsistente")}
        if(rowData.codDnInv!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : DN inconsistente")}
        if(rowData.separador!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : SEPARADOR INVÁLIDO")}
        if(rowData.formatacao!=='0'){src = "/img/icons/Error.svg";erros.push("Erro : FORMATAÇÃO inválida")}
      }
      

      // if(erros.length==0){
      //   this.setState({cadastroValido:true});
      // }
      return (
        <div style={{textAlign:'left',marginTop:'25px'}}>        
        <ul style={{listStyle:'circle'}}>
          {erros.map(item => {
            return <li><img src={src} alt={rowData.brand} width="18px" height="18px" style={{marginRight:'14px'}} /><strong>{item}</strong></li>;
          })}
        </ul>
        </div>
      );
  
    }

    renderButton(valido){
      if(valido){
        return <Button icon="pi pi-check" onClick={(e) => this.setState({dialogo:true})} className="p-button-success" />
      }else{
        return <Button icon="pi pi-times-circle" onClick={(e) => this.setState({dialogo:true})} className="p-button-danger" />
      }
    }
    render() {
      return(
        <div>
            <Button icon="pi pi-times-circle" onClick={(e) => this.setState({dialogo:true})} className="p-button-danger" />
            <Dialog header="Exceções para o Trabalhador/Servidor" visible={this.state.dialogo} style={{ width: '50vw' }} onHide={(e) => this.setState({dialogo:false})}>
                <em>Lista de erros para o trabalhador {this.props.rowData.nome}</em>
                {this.brandTemplate(this.props.rowData)}
            </Dialog>
        </div>
      );
    }
  }