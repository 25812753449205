import React, {Component} from 'react';

export class LegendaCor extends Component {
    render(){
        const style = {
            root:{
                'display':'inlineBlock',
                'float':'left',
                'clear':'right'
                
            },
            box:{
                'float': 'left',
                'idth': '20px',
                'height': '20px',
                'width':'20px',
                'margin': '5px',
                'border': '1px solid rgba(0, 0, 0, .2)',
                'backgroundColor': this.props.cor
            },
            text:{

            }
        }
        return(
            <div style={style.root}>
                <div style={style.box}></div>
                <span>{this.props.legenda}</span>
            </div>
        );

    }
}