
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect, useRef } from 'react';
import BtnExportCsv from '../../../../componentes/BtnExportCsv';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from 'primereact/badge';
import { Tooltip } from 'primereact/tooltip';
import Utils from '../../../../utils/Util';


const TblEvtIrrf = (props) => {

    const [infoCRMenDTOs, setInfoCRMenDTOs] = useState([])
    const [first2, setFirst2] = useState(0);
    const [rows2, setRows2] = useState(100);
  
    useEffect(() => {
      setInfoCRMenDTOs(props.relatorios.infoCRMenDTOs);
    }, [props])
  
  
    const [headerCsv, setHeaderCsv] = useState([
      { label: 'crmen', key: 'crmen' },
      { label: 'vrCRMen', key: 'vrCRMen' },
    ]);
  
    function geraNomeRelatorio(nome) {
        const timeElapsed = Date.now();
        const today = new Date(timeElapsed);
        const nomeRelatorio = nome+ '_' + today.toISOString() + '.csv';
        return nomeRelatorio;
      }

  
  
    function onPage(event, values, setter) {
      console.log(event)
      setFirst2(event.first)
      const endIndex = event.first + event.rows;
      const newArray = [];
      for (let i = 0; i < values.length; i++) {
        if (i >= event.first && i < endIndex) {
          newArray.push(values[i])
        }
      }
      setter(newArray);
    }
  
    function onChangePaginator(event, options, values, setter) {
      console.log(event);
      console.log(options.props);
      options.currentPage = event.value
      const startIndex = event.value * options.rows;
      const endIndex = startIndex + options.rows
      setFirst2(startIndex);
  
      const newArray = [];
      for (let i = 0; i < values.length; i++) {
        if (i >= startIndex && i < endIndex) {
          newArray.push(values[i])
        }
      }
      setter(newArray);
    }
    const templatePaginator = {
      layout: 'CurrentPageReport PrevPageLink NextPageLink',
      'CurrentPageReport': (options) => {
        const totalReg = options.totalRecords === null ? 0 : options.totalRecords
        const dropdownOptions = [];
        const totalPages = options.totalPages === 1 ? 0 : options.totalPages - 1
        for (let i = 0; i <= totalPages; i++) {
          const item = {
            label: i + 1,
            value: i
          };
  
          dropdownOptions.push(item);
        }
  
        return (
          <>
            <div className="p-grid">
              <div className="p-col">
                <Badge value={`Registros: ${options.first} até ${options.last} de ${totalReg}`} severity="info" className='p-badge-square ' />
                <Dropdown value={options.currentPage - 1} options={dropdownOptions} onChange={(e) => onChangePaginator(e, props.relatorios.infoCRMenDTOs, setInfoCRMenDTOs)} />
              </div>
            </div>
          </>
        )
      }
    };

    return (
        <div>
            <TabView>
                <TabPanel header="infoCRMen">
                    <h3>Totalizador do IRRF por CR mensal</h3>
                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                        Informações consolidadas do IRRF, por Código de Receita - CR mensal.
                    </p>
                    <div className="content-section implementation">
                        {props.relatorios.infoCRMenDTOs.length > 0 ? <BtnExportCsv data={props.relatorios.infoCRMenDTOs} header={headerCsv} fileName={geraNomeRelatorio('5012_'+ 'infoCRMen_' + props.mesPerApur + '_' + props.anoPerApur + '_' + props.indApuracao)} /> : ''}
                        <Tooltip target=".CRMen" />
                        <Tooltip target=".vrCRMen" />
                        <DataTable
                            value={infoCRMenDTOs}
                            emptyMessage="Nenhum registro encontrado."
                            lazy={true}
                            rows={50}
                            loading={false}
                            totalRecords={props.relatorios.infoCRMenDTOs.length}
                            className="p-datatable-sm"
                            paginator={true}
                            paginatorTemplate={templatePaginator}
                            first={first2}       
                            onPage={(e)=>onPage(e, props.relatorios.infoCRMenDTOs, setInfoCRMenDTOs)}
                            footer={
                              <>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <div style={{ textAlign: 'right', marginLeft: 'auto', paddingRight: '10px' }}>
                                    Total vrCRMen
                                    <br />
                                    {Utils.formatValue(Utils.calculateSum(infoCRMenDTOs, 'vrCRMen'))}
                                  </div>
                                </div>
                              </>
                            }
                        >
                            <Column field="crmen" style={{ width: '1px' }} header={<>CRMen <i className="CRMen pi pi-info-circle" data-pr-tooltip="Código de Receita - CR relativo ao Imposto de Renda Retido na Fonte sobre rendimentos do trabalho."/> </>}/>
                            <Column field="vrCRMen" style={{ width: '0.5em' }} body={(rowData) => Utils.formatValue(rowData.vrCRMen)} header={<>vrCRMen <i className="vrCRMen pi pi-info-circle" data-pr-tooltip="Valor correspondente ao Código de Receita - CR indicado em CRMen."/> </>}/>
                        </DataTable>
                    </div>
                </TabPanel>
            </TabView>

        </div>
    )
}

export default TblEvtIrrf;