import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import $ from 'jquery';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import { Toast } from 'primereact/toast';
import pt from '../../../pt.json';

export class TabEvtFechamento extends Component {
    constructor() {
        super();
        this.state = { idEventoFila: 0, bolAtivo: false, activeIndex: 0, evtValido: false, eventos:[], isTabelaLoading: true };

        this.confirmGeracao = this.confirmGeracao.bind(this);
        this.changeLocale = this.changeLocale.bind(this);
        this.buscaEventoFechamento = this.buscaEventoFechamento.bind(this);
        this.confirmaGeracaoFechamento = this.confirmaGeracaoFechamento.bind(this);
    }

    componentDidMount(){
        console.log(this.props.idFolha)
        this.buscaEventoFechamento(this.props.idFolha);
    }

    confirmGeracao() {
        confirmDialog({
            message: 'Deseja gerar evento de fechamento de folha?',
            header: 'Geração s1299',
            icon: 'pi pi-exclamation-triangle',
            accept: this.confirmaGeracaoFechamento,
            reject: this.reject
        });
    }

    confirmaGeracaoFechamento(){
        this.gerarEventoFechamento(this.props.idFolha);
        this.buscaEventoFechamento(this.props.idFolha);
    }
    buscaEventoFechamento(idFolha){
        this.setState({isTabelaLoading: true})
        console.log()
        $.ajax({
            url:"/EsocialAPI/folha/eventofechamento/"+idFolha,
            method:'GET',
            contentType: "application/json",
            dataType: 'json',
            success:function(resposta){
              this.setState({eventos: resposta})
              this.setState({isTabelaLoading: false})
            }.bind(this),
            beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
          });
    }

    gerarEventoFechamento(idFolha){
        $.ajax({
            url:"/EsocialAPI/folha/gerar/fechamento/"+idFolha,
            method:'GET',
            contentType: "application/json",
            dataType: 'json',
            success:function(resposta){
                this.showToast(resposta);
            }.bind(this),
            beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
          });
    }



    botaoGeracaoEvento() {
        return (
    
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                <div style={{ margin: '5px' }}>
                    <Button icon="pi pi-plus" className="p-button-rounded p-button-success" onClick={this.confirmGeracao} />
                </div>
                <div style={{ margin: '5px' }}>
                    Sem registro. Clique para gerar evento de fechamento {'(1299)'}.
                </div>
            </div>


        )
    }

    showToast(msgObj) {
        this.toast.show({...msgObj, life: 5000});
    }
    
    changeLocale() {
        console.log(pt)
        addLocale('pt', pt.pt);
        locale('pt');
    }

    render() {
        this.changeLocale();

        const footer = (
            <div>
                <Button label="Enviar" icon="pi pi-check" onClick={(e) => this.enviar()} disabled={this.state.evtValido} />
                <Button label="Cancelar" icon="pi pi-times" onClick={(e) => this.setState({ bolAtivo: false })} />
            </div>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <div>
                    <DataTable loading={this.state.isTabelaLoading} emptyMessage={this.botaoGeracaoEvento()} value={this.state.eventos}>
                        <Column field="id" header="IdEventoFila"></Column>
                        <Column field="evento" header="Desc. Evento"></Column>
                        <Column field="natureza" header="Ação"></Column>
                        <Column field="status" header="Status"></Column>
                        <Column field="objetoAlvoEvento" header="Detalhe"></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}