import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { Component } from 'react';
import Utils from '../../../utils/Util';

const TblEventosRecebidos = (props) => {

    function retornaData(rowData){
        return Utils.timestampToHumanDate(rowData.dataEnvio)
    }

    return (
        <div className="pure-u-5-5" style={{ marginBottom: '30px' }}>
            <DataTable value={props.eventos}  style={{ marginBottom: '30px' }} emptyMessage="Nenhuma inconsistencia encontrada" paginator={true} rows={50} >
                <Column field="idEventoFila" header="Id" style={{ width: '5%' }} />
                <Column field="empresa" header="Empresa" style={{ width: '8%' }} />
                <Column field="usuario" header="Usuário" style={{ width: '8%' }} />
                <Column field="dataEnvio" header="Data envio" style={{ width: '8%' }} />
                <Column field="acao" header="Ação" style={{ width: '8%' }} />
                <Column field="codigoEvento" header="Código Evento" style={{ width: '8%' }} />
                <Column field="idLote" header="ID Lote" style={{ width: '8%' }} />
                <Column field="objDetalhe" header="Detalhe" style={{ width: '8%' }} />
                <Column field="recibo" header="Recibo" style={{ width: '8%' }} />
                {/* <Column body={this.retornaBtnRelatorio} header="Rejeições" style={{ width: '10%' }} /> */}
            </DataTable>
        </div>
    )
}

export default TblEventosRecebidos;