
import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

export class EvtInfoEmpregador extends Component {
    constructor() {
        super();
        this.state = {
            home: { icon: 'pi pi-home' }
            , agendamentos: []
            , activeIndex: 0
            ,bsCalculo:[
                {codBaseCalc:11,descBaseCalc:'Base de cálculo da contribuição previdenciária',valor:22977001.86},
                {codBaseCalc:14,descBaseCalc:'Base de cálculo da contribuição previdenciária adicional para o financiamento dos benefícios de aposentadoria especial após 25 anos de contribuição',valor:1310177.77}
            ]
            ,contribSegurado:[
                {valorTotalContrib:'Valor total da contribuição',valor:934393.92}
            ]
            ,deducoes:[
                {descDeducao:'Valor total do salário-maternidade',valor:50885.29}
            ]
        };
        this.priceBodyTemplate = this.priceBodyTemplate.bind(this);
    }
    formatCurrency(value) {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    priceBodyTemplate(rowData) {
        return this.formatCurrency(rowData.valor);
    }
    render() {
        return (
            <div>
                <h5>Informações das contribuições sociais consolidadas por contribuinte</h5>
                <div style={{marginTop:'20px', backgroundColor:'#f7f8fa', padding:'8px 5px 8px 5px'}}> 
                    <Button icon="pi pi-file-excel" style={{marginLeft:'12px;'}} className="p-button-rounded p-button-secondary" tooltip="Exportar em Excell" />&nbsp;
                    <Button icon="pi pi-file-pdf"  style={{marginLeft:'12px;'}} className="p-button-rounded p-button-secondary"tooltip="Exportar em PDF" />&nbsp;
                    <Button icon="pi pi-print" style={{marginLeft:'12px;'}} className="p-button-rounded p-button-secondary"tooltip="Imprimir" />
                </div>
                <hr />
                <Accordion activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                    <AccordionTab header="Bases">
                        <DataTable value={this.state.bsCalculo}>
                            <Column field="codBaseCalc" header="#"></Column>
                            <Column field="descBaseCalc" header="Base de calculo"></Column>
                            <Column field="valor"  header="Valor" body={this.priceBodyTemplate} style={{textAlign:'right'}}></Column>
                        </DataTable>
                    </AccordionTab>
                    <AccordionTab header="Contribuições do Segurado">
                        <DataTable value={this.state.contribSegurado}>
                            <Column field="valorTotalContrib" header="Contribuições"></Column>
                            <Column field="valor" body={this.priceBodyTemplate} header="Valor Descontado" style={{textAlign:'right'}}></Column>
                        </DataTable>
                    </AccordionTab>
                    <AccordionTab header="Deduções">
                        <DataTable value={this.state.deducoes}>
                            <Column field="descDeducao" header="Descrição"></Column>
                            <Column field="valor" body={this.priceBodyTemplate} header="Valor" style={{textAlign:'right'}}></Column>
                        </DataTable>
                    </AccordionTab>
                </Accordion>
                
            </div>
        );
    }
}
