import $ from 'jquery';
import Utils from '../utils/Util';

const sessaoService = {

    listarSessao(callback) {
      $.ajax({
        url:"/EsocialAPI/sessao/listar",
        dataType: 'json',
        error: Utils.handleAjaxError,
        success: function (resposta) {
            if (callback) callback(resposta);
        },
        beforeSend: function (xhr, settings) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token'));
        }
      });
    },

}

export default sessaoService;