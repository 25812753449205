import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {InputMask} from 'primereact/inputmask';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faHandshake, faHandHoldingUsd, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons'
import {TabView,TabPanel} from 'primereact/tabview';
import {TabPreviewFechamento} from '../Folpag/TabPreviewFechamento'
import {Messages} from 'primereact/messages';
import $ from 'jquery';


export class FormEvtFechamento extends Component{
    constructor(){
      super();
      library.add(fab, faHandshake, faHandHoldingUsd, faMoneyCheckAlt);
      this.state={
          idEventoFila:0
          ,listaErrosForm:[]
          ,bolAtivo:false
          ,activeIndex:0
          ,evtValido:false
          ,nomeResp:''
          ,cpfResp:''
          ,emailResp:''
          ,telefoneResp:''
          ,frmPronto:true
          ,ano:0
          ,mes:0
          ,eventoFechamento:{idEventoFila:0,statusEvento:'PENDENTE'}
          ,hasNotretorno:true
          ,hasNotEvento:true
          ,tabActiveIndex:0
        };
    }
    componentDidMount(){
        this.carregaFechamento();
    }
    carregaFechamento(){
        $.ajax({
          url:"/EsocialAPI/folha/fechamento/",
          method:'POST',
          data: JSON.stringify ({ano : "20"+this.props.anoApuracao,mes:+this.props.mesApuracao }),
          contentType: "application/json",
          dataType: 'json',
          success:function(resposta){
            console.log(resposta);
            this.setState({
                 hasNotretorno:resposta.hasNotretorno
                ,hasNotEvento:resposta.hasNotEvento
            });
            if (resposta.responsavel!==null){
                this.setState({nomeResp:resposta.responsavel.nomeResponsavel
                    ,cpfResp:resposta.responsavel.cpfResponsavel
                    ,emailResp:resposta.responsavel.emailResponsavel
                    ,telefoneResp:resposta.responsavel.telefoneResponsavel})
            }
          }.bind(this),
          beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
        });
      }
      enviaResponsavelFolha(){
          var formValido = this.validaForm();
        if(formValido){
            var dados = JSON.stringify ({
                nomeResponsavel : this.state.nomeResp
               ,cpfResponsavel: this.state.cpfResp
               ,emailResponsavel:this.state.emailResp
               ,telefoneResponsavel:this.state.telefoneResp
               ,anoApuracao:this.props.anoApuracao
               ,mesApuracao:this.props.mesApuracao
               });
               $.ajax({
                url:"/EsocialAPI/folha/novo/responsavelfolha",
                type:"POST",
                contentType: "application/json; charset=utf-8",
                data : dados,
                dataType: 'json',
                success:function(resposta){
                  console.log(resposta);
                  this.messages.show(resposta);
                }.bind(this),
                beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
              });

        }else{
            this.showMultiple();
            this.setState({listaErrosForm:[]});
        }

      }
      showMultiple() {
          console.log(this.state.listaErrosForm);
        this.messages.show(
            this.state.listaErrosForm
        );
      }
      validaForm(){
        var erros = this.state.listaErrosForm;
        if(this.state.nomeResp===''){
            erros.push({severity:'error', summary:'Erro', detail:'Favor Preencher o Nome do Responsável.'});
        }
        if(this.state.cpfResp===''){
            erros.push({severity:'error', summary:'Erro', detail:'Favor Preencher o CPF do Responsável.'});
        }
        if(this.state.emailResp===''){
            erros.push({severity:'error', summary:'Erro', detail:'Favor Preencher o Email do Responsável.'});
        }
        if(this.state.telefoneResp===''){
            erros.push({severity:'error', summary:'Erro', detail:'Favor Preencher o Telefone do Responsável.'});
        }
        this.setState({listaErrosForm:erros});
        if(erros.length>0){
            return false;
        }else{
            return true;
        }

      }
    componentWillReceiveProps(){

    }
    render() {
/*        const footer = (
            <div>
                <Button label="Enviar" icon="pi pi-check" onClick={(e) => this.enviar()} disabled={this.state.evtValido} />
                <Button label="Cancelar" icon="pi pi-times" onClick={(e) => this.setState({bolAtivo:false})} />
            </div>
        );*/
      return(     
        <div style={{marginTop:'20px',textAlign:'left',border:'1 px solid #ccc'}}>
            <TabView activeIndex={this.state.tabActiveIndex} onTabChange={(e) => this.setState({tabActiveIndex: e.index})}>
                <TabPanel header="Registro Responsável">
                <Messages ref={(el) => this.messages = el}></Messages>
                    <div >
                        <em>Favor Preencher os dados referentes ao responsável técnico pelo envio da Folha de pagamento: <strong>{this.props.mesApuracao}/{this.props.anoApuracao}</strong>.</em>
                        <br />

                        <div className="pure-g">
                            <div className="pure-u-2-3" style={{textAlign:'justify'}}>
                                <label htmlFor="nmResp">Nome do Responsável: </label>
                                <InputText id="nmResp" value={this.state.nomeResp} onChange={(e) => this.setState({nomeResp: e.target.value,frmPronto:false})} style={{marginLeft:'18px',width:'50%'}} />
                                <br/>
                                <label htmlFor="cpfResp">CPF do Responsável: </label>
                                <InputMask mask="999.999.999-99" id="cpfResp" value={this.state.cpfResp} onChange={(e) => this.setState({cpfResp: e.target.value,frmPronto:false})} style={{marginLeft:'18px'}} />
                                <br/>
                                <label htmlFor="emailResp">E-mail do Responsável: </label>
                                <InputText id="emailResp" value={this.state.emailResp} onChange={(e) => this.setState({emailResp: e.target.value,frmPronto:false})} style={{marginLeft:'18px'}} />
                                <br/>
                                <label htmlFor="telResp">Telefone do Responsável:</label>
                                <InputMask mask="(99)9 9999-9999" id="telResp" value={this.state.telefoneResp} onChange={(e) => this.setState({telefoneResp: e.target.value,frmPronto:false})} style={{marginLeft:'18px'}} />
                                <br />
                                <Button label="Salvar" icon="pi pi-save" onClick={(e) => this.enviaResponsavelFolha()} disabled={this.state.frmPronto} />
                            </div>
                            <div className="pure-u-1-3" style={{textAlign:'right', marginTop:'40px',verticalAlign:'bottom'}}>
                                
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Resumo Evento" disabled={this.state.hasNotEvento}>
                    <TabPreviewFechamento anoApuracao={this.props.anoApuracao} mesApuracao={this.props.mesApuracao} />
                </TabPanel>
                <TabPanel header="Retorno do envio" disabled={this.state.hasNotretorno}>
                    Content III
                </TabPanel>
            </TabView>
          </div>
      );
    }
    }