import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Messages } from 'primereact/messages';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { MenuBar } from '../../componentes/MenuBar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { PermissionCheck } from '../../componentes/PermissionCheck';
import { Toolbar } from 'primereact/toolbar';
import {FiltroTarefasAutomatizadas} from './filtros/FiltroTarefasAutomatizadas'
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import usuarioService from '../../services/usuarioService';

export class ProcessamentoAutomatizado extends Component {
    constructor(props) {
        super(props);

        this.handleTarefaChange = this.handleTarefaChange.bind(this);
        this.handleGrupoEventoChange = this.handleGrupoEventoChange.bind(this);
        this.handleEventoChange = this.handleEventoChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleAcaoChange = this.handleAcaoChange.bind(this);
        this.handleMesChange = this.handleMesChange.bind(this);
        this.handleMesDescricaoChange = this.handleMesDescricaoChange.bind(this);
        this.handleAnoChange = this.handleAnoChange.bind(this);
        this.handleIndApuracaoChange = this.handleIndApuracaoChange.bind(this);
        this.handleObjDetalheChange = this.handleObjDetalheChange.bind(this);
        this.handleIdEventoFilaChange = this.handleIdEventoFilaChange.bind(this);
        this.handleIdLoteChange = this.handleIdLoteChange.bind(this);
        this.handleTipoServidorChange = this.handleTipoServidorChange.bind(this);
        this.handleGrupoEventoDescricaoChange = this.handleGrupoEventoDescricaoChange.bind(this);
        this.handleEventoDescricaoChange = this.handleEventoDescricaoChange.bind(this);
        this.handleIndApuracaoDescricaoChange = this.handleIndApuracaoDescricaoChange.bind(this);
        this.handleTipoServidorDescricaoChange = this.handleTipoServidorDescricaoChange.bind(this);

        this.state = {
            evento: null,
            grupoEvento: 0,
            idEventoFila: 0,
            idLote: 0,
            status: null,
            tarefa: null,
            acao: null,
            mes: null,
            ano: null,
            indApuracao: null,
            objDetalhe: '',
            tipoServidor: 0,
            usuario: {},
            displayBasic: false,
            filtros: {},
            grupoEventoDescricao: '',
            eventoDescricao: '',
            indApuracaoDescricao: '',
            tipoServidorDescricao: '',
            mesDescricao: '',
            statusExecucao: {},
            statusBotaoChecarExecucao: false,
        };
    }

    executarTarefa(jobAction) {
        this.setState({ displayBasic: false });

        if(this.state.tarefa === null) {
            this.showMessage('', 'Selecione uma tarefa.', 'info');
        } else {
            const filtros = {
                grupoEvento: this.state.grupoEvento ? parseInt(this.state.grupoEvento) : null,
                grupoEventoDescricao: this.state.grupoEventoDescricao,
                idUsuario: this.state.usuario.id ? parseInt(this.state.usuario.id) : null,
                idEventoFila: this.state.idEventoFila ? parseInt(this.state.idEventoFila) : null,
                idLote: this.state.idLote ? parseInt(this.state.idLote) : null,
                evento: this.state.evento ? parseInt(this.state.evento) : null,
                status: this.state.status,
                tarefa: this.state.tarefa,
                acao: this.state.acao,
                objDetalhe: this.state.objDetalhe,
                mes: this.state.mes ? parseInt(this.state.mes) : null,
                ano: this.state.ano ? parseInt(this.state.ano) : null,
                indApuracao: this.state.indApuracao ? parseInt(this.state.indApuracao) : null,
                tipoServidor: this.state.tipoServidor ? parseInt(this.state.tipoServidor) : null,
            };

            let arrMensagem = [];

            if(filtros.tarefa !== 'consulta' && (filtros.evento === null || filtros.evento === '')) {
                arrMensagem.push({severity:'info', detail:'Evento é campo obrigatório.'});
            }

            if(filtros.tarefa !== 'consulta' && (filtros.grupoEvento === null || filtros.grupoEvento === '')) {
                arrMensagem.push({severity:'info', detail:'Grupo é campo obrigatório.'});
            }

            if(filtros.grupoEventoDescricao === 'PERIÓDICO') {
                if((filtros.ano === null || filtros.ano === '')) {
                    arrMensagem.push({severity:'info', detail:'Ano apuração é campo obrigatório.'});
                }
    
                if((filtros.mes === null || filtros.mes === '')) {
                    arrMensagem.push({severity:'info', detail:'Mês apuração é campo obrigatório.'});
                }
    
                if((filtros.indApuracao === null || filtros.indApuracao === '')) {
                    arrMensagem.push({severity:'info', detail:'Indicativo de apuração é campo obrigatório.'});
                }
            }

            // Se exibir mensagem, não executa
            if(arrMensagem.length > 0) {
                this.showToast(arrMensagem);
            } else {
                let url = '/EsocialAPI/job/' + jobAction + '/' + this.state.tarefa

                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
                    },
                    body: JSON.stringify(filtros),
                })
                .then(response => response.text())
                .then(data => {
                    const mensagemDTO = JSON.parse(data);
                    this.setState({ statusExecucao: mensagemDTO });

                    if (jobAction === 'check-job') {
                        this.showToast([{ severity: mensagemDTO.tipoMensagem, detail: mensagemDTO.mensagem }]);
                    }

                    if (jobAction !== 'check-job' && this.state.statusExecucao.sucesso !== false) {
                        this.showToast([{ severity: 'info', detail: 'Processando operação. Por favor, aguarde.' }]);
                        this.executarTarefa('check-job');
                    } else if (jobAction === 'check-job' && this.state.statusExecucao.sucesso !== false) {
                        this.setState({ statusBotaoChecarExecucao: true });
                        setTimeout(() => {
                            this.executarTarefa('check-job');
                        }, 4000);
                    } else {
                        this.setState({ statusBotaoChecarExecucao: false });
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            }
        }
    }

    componentDidMount() {
        this.consultaUsuario();
    }

    showToast(arrMensagens) {
        this.toast.show(arrMensagens);
    }

    consultaUsuario() {
        usuarioService.consultaUsuario((usuario) => {
            this.setState({ usuario });
        });
    }

    verificaDados() {

        this.setState({ displayBasic: true });

        const filtros = this.state.tarefa === 'consulta' ? 
        {} 
        :
        {
            idUsuario: parseInt(this.state.usuario.id),
            idEventoFila: parseInt(this.state.idEventoFila),
            idLote: parseInt(this.state.idLote),
            evento: parseInt(this.state.evento),
            grupoEvento: parseInt(this.state.grupoEvento),
            status: this.state.status,
            tarefa: this.state.tarefa,
            acao: this.state.acao,
            objDetalhe: this.state.objDetalhe,
            mes: parseInt(this.state.mes),
            ano: parseInt(this.state.ano),
            indApuracao: parseInt(this.state.indApuracao),
            tipoServidor: parseInt(this.state.tipoServidor),
        };

        this.setState({ filtros: filtros });
    }

    limparFiltros = () => {
        this.setState({
          displayBasic: false ,
          ano: '',
          mesDescricao: '',
          mes: '',
          indApuracaoDescricao: '',
          indApuracao: '',
          idEvento: '',
          descricaoEvento: '',
          tipoServidorDescricao: '',
          idEventoFila: '',
          acao: '',
          status: '',
          eventoDescricao: '',
          grupoEventoDescricao: '',
          evento: '',
          tipoServidor: ''
        });
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Corrigir os dados" icon="pi pi-times" onClick={() => { this.limparFiltros() }}  className="p-button-danger" autoFocus />
                <Button label="Os dados estão corretos!" icon="pi pi-check" className="p-button-sucess" loading={this.state.loading} onClick={() => {this.executarTarefa('executar-job')}} />
            </div>
        );
    }

    handleTarefaChange(tarefa) {
        this.setState({ tarefa });
        if(tarefa === 'envio') {
            this.showToast([{ severity: 'warn', life: 6000, position:'right', detail: 'Atenção, a tarefa de envio utiliza apenas os eventos aptos para envio. Portanto, deve executar a tarefa "Validar Eventos" previamente.' }]);
        }
    }

    handleGrupoEventoChange(grupoEvento) {
        this.setState({ grupoEvento });
    }

    handleGrupoEventoDescricaoChange(grupoEventoDescricao) {
        this.setState({ grupoEventoDescricao });
    }

    handleEventoDescricaoChange(eventoDescricao) {
        this.setState({ eventoDescricao });
    }

    handleIndApuracaoDescricaoChange(indApuracaoDescricao) {
        this.setState({ indApuracaoDescricao });
    }

    handleStatusChange(status) {
        this.setState({ status });
    }

    handleEventoChange(evento) {
        this.setState({ evento });
    }

    handleAcaoChange(acao) {
        this.setState({ acao });
    }

    handleMesChange(mes) {
        this.setState({ mes });
    }

    handleMesDescricaoChange(mesDescricao) {
        this.setState({ mesDescricao });
    }

    handleAnoChange(ano) {
        this.setState({ ano });
    }

    handleIndApuracaoChange(indApuracao) {
        this.setState({ indApuracao });
    }

    handleObjDetalheChange(objDetalhe) {
        this.setState({ objDetalhe });
    }

    handleIdEventoFilaChange(idEventoFila) {
        this.setState({ idEventoFila });
    }

    handleIdLoteChange(idLote) {
        this.setState({ idLote });
    }

    handleTipoServidorChange(tipoServidor) {
        this.setState({ tipoServidor });
    }

    handleTipoServidorDescricaoChange(tipoServidorDescricao){
        this.setState({ tipoServidorDescricao })
    }

    showMessage(titulo, mensagem, tipoMensagem) {
        this.messages.show({ severity: tipoMensagem, summary: titulo, detail: mensagem });
    }

    render() {
        const style = {
            toolbar: {
                margin: 0,
                padding: 0,
            },
        }

        const items = [
            { label: 'Home', icon: 'pi pi-home', url: '/home' },
            { label: 'Processamento', icon: 'pi pi-home', url: '/home' },
            { label: 'Tarefas Automatizadas', icon: 'pi pi-home', url: '/processamento/automatizado' }
          ];

        const rightContents = (
            <React.Fragment>
                <Button style={{ margin: '4px' }} onClick={() => this.verificaDados()} tooltip="Executar" tooltipOptions={{ position: 'left' }} label="Executar" icon="pi pi-play" disabled={this.state.statusBotaoChecarExecucao}/>
                <Button style={{ margin: '4px' }} onClick={() => this.executarTarefa('check-job')} tooltip="Checar Execução" tooltipOptions={{ position: 'top' }} label="Checar Execução" className="p-button-info" icon="pi pi-info-circle" disabled={this.state.statusBotaoChecarExecucao}/>
            </React.Fragment>
        );

        const headerTemplate = (options) => {
            const className = `${options.className} justify-content-space-between`;

            return (
                <div className={className}>
                    <div>
                        <span className="p-panel-title">Tarefas Automatizadas</span>
                    </div>
                    <div>
                        <Button className="p-button-info" onClick={() => window.open('https://base-conhecimento.osm.com.br/artigo/form-view/?id=95' + '&cliente=' + localStorage.getItem('cliente') + '&login='+ localStorage.getItem('login'), '_blank')} tooltip="Tutorial" tooltipOptions={{ position: 'left' }} label="Tutorial" icon="pi pi-question-circle"/>
                    </div>
                </div>
            );
        };

        return (
            <div>
                <MenuBar usuario={this.state.usuario} />
                <div className="content" >
                    <div className="content align-center">
                        <BreadCrumb home="principal" model={items} />
                        <div>
                            <Panel header="Tarefas Automatizadas" headerTemplate={headerTemplate}>
                                <Toast ref={(el) => this.toast = el} position="bottom-right"></Toast>
                                <Messages ref={(el) => (this.messages = el)}></Messages>
                                <FiltroTarefasAutomatizadas
                                    usuario={this.state.usuario}
                                    evento={this.state.evento}
                                    idEventoFila={this.state.idEventoFila}
                                    idLote={this.state.idLote}
                                    status={this.state.status}
                                    tarefa={this.state.tarefa}
                                    acao={this.state.acao}
                                    mes={this.state.mes}
                                    mesDescricao={this.state.mesDescricao}
                                    ano={this.state.ano}
                                    grupoEventoDescricao={this.state.grupoEventoDescricao}
                                    eventoDescricao={this.state.eventoDescricao}
                                    indApuracaoDescricao={this.state.indApuracaoDescricao}
                                    indApuracao={this.state.indApuracao}
                                    objDetalhe={this.state.objDetalhe}
                                    tipoServidor={this.state.tipoServidor}
                                    tipoServidorDescricao={this.state.tipoServidorDescricao}
                                    handleTarefaChange={this.handleTarefaChange}
                                    handleGrupoEventoChange={this.handleGrupoEventoChange}
                                    handleGrupoEventoDescricaoChange={this.handleGrupoEventoDescricaoChange}
                                    handleEventoDescricaoChange={this.handleEventoDescricaoChange}
                                    handleIndApuracaoDescricaoChange={this.handleIndApuracaoDescricaoChange}
                                    handleStatusChange={this.handleStatusChange}
                                    handleEventoChange={this.handleEventoChange}
                                    handleAcaoChange={this.handleAcaoChange}
                                    handleMesChange={this.handleMesChange}
                                    handleMesDescricaoChange={this.handleMesDescricaoChange}
                                    handleAnoChange={this.handleAnoChange}
                                    handleIndApuracaoChange={this.handleIndApuracaoChange}
                                    handleObjDetalheChange={this.handleObjDetalheChange}
                                    handleIdEventoFilaChange={this.handleIdEventoFilaChange}
                                    handleIdLoteChange={this.handleIdLoteChange}
                                    handleTipoServidorChange={this.handleTipoServidorChange}
                                    handleTipoServidorDescricaoChange={this.handleTipoServidorDescricaoChange}

                                />
                            </Panel>
                            <Toolbar right={rightContents} style={style.toolbar}/>
                        </div>
                        <div>
                                <Dialog header="Confirmação de Execução da Tarefa" visible={this.state.displayBasic} style={{ width: '50vw' }} footer={this.renderFooter()}  onHide={() => { this.limparFiltros() }}>
                                    <b><p>Os dados estão corretos?</p></b>
                                    {this.state.grupoEventoDescricao ? <p><b>Grupo Evento: </b> {this.state.grupoEventoDescricao}</p>  : "" }
                                    {this.state.eventoDescricao ? <p> <b>Evento:</b> {this.state.eventoDescricao}</p> : ""}
                                    {this.state.filtros.status ? <p> <b>Status:</b> {this.state.filtros.status}</p> : ""}
                                    {this.state.filtros.acao ? <p> <b>Açao:</b> {this.state.filtros.acao}</p> : ""}
                                    {this.state.filtros.objDetalhe ? <p> <b>Detalhes:</b> {this.state.filtros.objDetalhe}</p> : ""}
                                    {this.state.filtros.idEventoFila ? <p> <b>ID Evento:</b> {this.state.filtros.idEventoFila}</p> : ""}
                                    {this.state.tipoServidorDescricao ? <p> <b>Tipo Servidor:</b> {this.state.tipoServidorDescricao}</p> : ""}
                                    {this.state.mesDescricao ? <p> <b>Mes: </b>{this.state.mesDescricao}</p> : ""}
                                    {this.state.filtros.ano ? <p> <b>Ano:</b> {this.state.filtros.ano}</p> : ""}
                                    {this.state.indApuracaoDescricao ? <p> <b>Indicação de Apuração:</b> {this.state.indApuracaoDescricao}</p> : ""}

                                </Dialog>
                            </div>
                    </div>
                </div>
                <div className="footer l-box is-center">
                    <PermissionCheck usuario={this.state.usuario}  />
                </div>
            </div>
        );
    }
}