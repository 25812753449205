import $ from 'jquery';

const testeEnvioService = {
  
  enviar(dados, callback, errorCallback) {
    fetch('/EsocialAPI/teste-envio/enviar', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
        },
        body: JSON.stringify(dados),
    })
    .then(response => response.text())
    .then(data => {
        const mensagemDTO = JSON.parse(data);
        if (callback) callback(mensagemDTO);
    })
    .catch(error => {
        if (errorCallback) errorCallback(error);
        console.error('Error:', error);
    });
}
}

export default testeEnvioService;