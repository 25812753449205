import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Panel } from 'primereact/panel';
import { Messages } from 'primereact/messages';
import { MenuBar } from '../../componentes/MenuBar'
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { PermissionCheck } from '../../componentes/PermissionCheck';
import usuarioService from '../../services/usuarioService';
import auditoriaService from '../../services/auditoriaService';

export class Auditoria extends Component {

  constructor() {
    super();
    this.state = {
      lista: [],
      home: { icon: 'pi pi-home' },
      agendamentos: [],
      tipoEvento: "tabela",
      auditaveis: [],
      pagina: 0,
      usuarios: null,
      usuario: { idUsuario: 0 },
      regPagina: 20
    };
    // this.atualizaUsuarios = this.atualizaUsuarios().bind(this);
    // this.atualizaAuditoria = this.atualizaAuditoria().bind(this);
  }


  componentDidMount() {
    this.consultaUsuario();
    this.atualizaUsuarios();
    this.atualizaAuditoria(false);
  }

  consultaUsuario() {
    usuarioService.consultaUsuario((usuario) => {
      this.setState({ usuario });
    });
  }

  atualizaUsuarios() {
    usuarioService.listarUsuarios((resposta) => {
      this.setState({ usuarios: resposta });
    });
  }

  atualizaAuditoria(bolOnpage) {
    this.setState({ loading: true });
    let pagina = 0;
    if (bolOnpage === false) {
      pagina = this.state.pagina - 1;
      if (pagina < 0) {
        pagina = 0;
      }
    } else {
      pagina = this.state.pagina;
    }
    var dados = { pagina: pagina, regPagina: this.state.regPagina, prdAcao: this.state.dates2, usuario: this.state.usuario.idUsuario };

    auditoriaService.listarAuditoria(dados, (resposta) => {
      this.setState({ auditaveis: resposta });
    });
  }

  dateTemplate(rowData, column) {
    var d = new Date(rowData.dataEvento);
    var retorno = d.toLocaleString("pt-BR");
    return <div>{retorno}</div>;
  }
  browserTemplate(rowData, column) {
    var pth = "";
    console.log(rowData);
    if (rowData.webBrowser !== null) {
      if (rowData.webBrowser.includes("Firefox")) {
        pth = "/img/icons/32/firefox.png";
      }
      if (rowData.webBrowser.includes("Chrome")) {
        pth = "/img/icons/32/chrome.png";
      }
      if (rowData.webBrowser.includes("Opera")) {
        pth = "/img/icons/32/Opera.png";
      }
      if (rowData.webBrowser.includes("Safari")) {
        pth = "/img/icons/32/safari.png";
      }
      return <div><img height="18" width="18" src={pth} alt="" /> {rowData.webBrowser}</div>;
    } else {
      return <div></div>;
    }
  }
  soTemplate(rowData, column) {
    var pth = "";
    if (rowData.soCliente !== null) {
      if (rowData.soCliente === "Ubuntu") {
        pth = "/img/icons/32/ubuntu.png";
      }
      if (rowData.soCliente === "Linux") {
        pth = "/img/icons/32/linux.png";
      }
      if (rowData.soCliente.includes("Windows")) {
        pth = "/img/icons/32/windows.png";
      }
      if (rowData.soCliente.includes("Android")) {
        pth = "/img/icons/32/android.png";
      }
      if (rowData.soCliente.includes("Ios")) {
        pth = "/img/icons/32/apple.png";
      }
      return <div><img height="18" width="18" src={pth} alt="" /> {rowData.soCliente}</div>;
    } else {
      return <div></div>;
    }
  }
  soStatus(rowData, column) {
    var pth = "";
    if (rowData.httpStatus === "200") {
      pth = "200 OK";
    }
    if (rowData.httpStatus === "400") {
      pth = "400 Bad Request";
    }
    if (rowData.httpStatus === "401") {
      pth = "401 Unauthorized";
    }
    if (rowData.httpStatus === "403") {
      pth = "403 Forbidden";
    }
    if (rowData.httpStatus === "404") {
      pth = "404 Not Found";
    }
    if (rowData.httpStatus === "500") {
      pth = "500 Internal Server Error";
    }

    return <div style={{ color: '#03b1fc' }}> {pth} </div>;
  }
  onPage(event) {
    console.log(event);
    //this.setState({loading: true});
    setTimeout(() => {
      const startIndex = event.first;
      this.setState({
        first: startIndex,
        pagina: event.page,
        loading: false
      });
      this.atualizaAuditoria(true);
    }, 250);
  }
  urlTemplate(rowData, column) {
    return <div><Button type="button" label="URL" icon="pi pi-globe" tooltip={rowData.url} tooltipOptions={{ position: 'top' }} /></div>
  }
  render() {
    const items = [
      { label: 'Home', icon: 'pi pi-home', url: '/home' },
      { label: 'Administração', icon: 'pi pi-home', url: '/home' },
      { label: 'Auditoria', icon: 'pi pi-home', url: '/administracao/auditoria' }
    ];
    const pt = {
      firstDayOfWeek: 1,
      dayNames: ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"],
      dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
      dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
      monthNamesShort: ["ja", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"]
    };
    return (
      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content">
          <div className="content align-center">
            <Messages ref={(el) => this.messages = el}></Messages>

          </div>
          <div className="pure-u-5-5">
            <Panel header="Auditoria">
              <BreadCrumb home="principal" model={items} />
              <br />
              <Panel header="Filtro" style={{ marginTop: '2em' }} toggleable={true}>
                <div>
                  <div style={{ display: 'inline', paddingRight: '30px' }}>
                    <label>Período de ação : </label>
                    <Calendar style={{ marginLeft: '10px' }} value={this.state.dates2} onChange={(e) => this.setState({ dates2: e.value })} selectionMode="range" readonlyInput={true} />
                  </div>
                  <div style={{ display: 'inline', paddingLeft: '30px' }}>
                    <label>Usuário :</label>
                    <Dropdown optionLabel="login" style={{ marginLeft: '10px' }} value={this.state.usuario} options={this.state.usuarios} onChange={(e) => { this.setState({ usuario: e.value }) }} placeholder="Selecione o Usuário" />
                  </div>
                  <div style={{ display: 'inline', paddingLeft: '30px' }}>
                    <Button label="Consultar" icon="pi pi-search" iconPos="left" onClick={(e) => this.atualizaAuditoria()} />

                  </div>
                </div>
              </Panel>
              <br />
              <DataTable value={this.state.auditaveis} paginator={true} rows={20} emptyMessage="Nenhuma ocorrência registrada na auditoria" onPage={this.onPage.bind(this)} >
                <Column header="#" style={{ width: '3%' }} />
                <Column header="Data Ação" body={this.dateTemplate} style={{ textAlign: 'center' }} />
                <Column field="acao" header="Ação" />
                <Column field="soCliente" header="Sist. Operacional" body={this.soTemplate} />
                <Column field="webBrowser" header="Navegador" body={this.browserTemplate} />
                <Column field="usuario.login" header="Usuario" />
                <Column field="url" header="URL" body={this.urlTemplate} style={{ textAlign: 'center' }} />
                <Column field="httpStatus" header="Http Status" style={{ textAlign: 'center' }} body={this.soStatus} />
              </DataTable>
            </Panel>
          </div>

        </div>
        <div className="footer l-box is-center">
          <PermissionCheck usuario={this.state.usuario} />
        </div>

      </div>
    );
  }
}
