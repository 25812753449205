import React, {Component} from 'react';
import $ from 'jquery';
import ReactHtmlParser  from 'react-html-parser';
export class Documentacao extends Component{
  constructor(){
    super();

    this.state={lista : [],eventos:[],usuario:{},xmljson:"",visiblePreview:false,sendPreview:false,xml:"ei",idEventoEsocial:0,documentacao:{}}
  }
  componentWillReceiveProps(props) {
    this.setState({idEventoEsocial: props.idEventoEsocial});
  }
  componentWillMount(){
    this.atualizaDialog();
  }

  atualizaDialog(){  
    $.ajax({
        url:"/EsocialAPI/documentacao/evento/"+this.props.idEventoEsocial,
        type:'GET',
          error:function(error){
        },
        success:function(retorno){
          console.log(retorno);
          this.setState({documentacao : retorno});
        }.bind(this),
        beforeSend: function(xhr, settings) { xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('auth-token')); }
      });
  }
  render(){
    return(
        <div style={{textAlign:'left',height:'300px',padding:'5px',overflowY:'scroll'}}>
            <p>
                <b>Conceito do evento: </b>
                { ReactHtmlParser(this.state.documentacao.conceito) }
            </p>
            <p>
                <b>Quem está obrigado: </b>
                {ReactHtmlParser(this.state.documentacao.obrigacao)}
            </p>
            <p>
                <b>Prazo de envio: </b>
                {ReactHtmlParser(this.state.documentacao.prazo)}
            </p>
            <p>
                <b>Pré requisitos: </b>
                {ReactHtmlParser(this.state.documentacao.preRequisito)}
            </p>
            <p>
                <b>Informações adicionais: </b>
                {ReactHtmlParser(this.state.documentacao.informacaoAdicional)}
            </p>
        </div>
    );
  }
}