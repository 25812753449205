import React, { useState, useEffect, useRef } from 'react';
import { validate, format, generate } from 'cnpj';
import Utils from '../../../../utils/Util';


const EvtRemunRPPS = (props) => {

    const [evtRemunRPPSObj, setEvtRemunRPPSObj] = useState({});

    useEffect(() => {
        console.log(props);
        setEvtRemunRPPSObj(props.evtRemunRPPS);
    }, [props])

    function renderEvtRemunRPPS(evtRemunRPPS) {
        console.log(evtRemunRPPS);
        console.log(evtRemunRPPS.ideEvento);
        const listaDmDev = evtRemunRPPS.dmDev;
        console.log(listaDmDev);
        if (Utils.isObjectHasValue(evtRemunRPPS)) {
            return (
                <div style={{ border: '1px solid #ccc', padding: '10px', borderRadius:'8px' }}>
                    <h4>Evento Remuneração de Servidor vinculado ao Regime Próprio de Previdência Social.</h4>
                    {renderIdeEvento(evtRemunRPPS.ideEvento)}
                    {renderIdeEmpregador(evtRemunRPPS.ideEmpregador)}
                    {renderIdeTrabalhador(evtRemunRPPS.ideTrabalhador)}
                    {listaDmDev.map((obj) => renderDmDev(obj))}
                </div>
            );
        }

    }
    function renderIndRetif(indRetif,nrRecibo){
        try{
            if(indRetif===1){
                return(
                    <div className="p-field" style={{paddingLeft:'8px'}}>
                        <label>Tipo de arquivo </label><br />
                        <em>1 - Original</em>
                    </div>
                );
            }else{
                return(
                    <div>
                        <div className="p-field" style={{paddingLeft:'8px'}}>
                            <label>Tipo de arquivo </label><br />
                            <em>2 - Retificação</em>
                        </div>
                        <div className="p-field" style={{paddingLeft:'8px'}}>
                            <label>Número do recibo do arquivo a ser retificado. </label><br />
                            <em>{nrRecibo}</em>
                        </div>                    
                    </div>
                );
            }
        }catch(error){
            console.log(error);
        }
    }
    function renderTpAmb(tpAmb){
        try{
            if(tpAmb===1){
                return(
                    <em><strong>1</strong> - Produção</em>
                );
            }
            if(tpAmb===2){
                return(
                    <em><strong>2</strong> - Produção restrita</em>
                );
            }
            if(tpAmb===7){
                return(
                    <em><strong>7</strong> - Validação (uso interno)</em>
                );
            }
            if(tpAmb===8){
                return(
                    <em><strong>8</strong> - Teste (uso interno)</em>
                );
            }
            if(tpAmb===9){
                return(
                    <em><strong>9</strong> - Desenvolvimento (uso interno)</em>
                );
            }
        }catch(error){
            console.log(error);
        }
    }
    function renderprocEmi(procEmi){
        try{
            if(procEmi===1){
                return(
                    <em><strong>1</strong> - Aplicativo do empregador</em>
                );
            }
            if(procEmi===3){
                return(
                    <em><strong>3</strong> - Aplicativo governamental - Web Geral</em>
                );
            }
            if(procEmi===4){
                return(
                    <em><strong>4</strong> - Aplicativo governamental - Simplificado Pessoa Jurídica</em>
                );
            }
        }catch(error){
            console.log(error);
        }
    }
    function renderIdeEvento(ideEventoObj) {
        console.log(ideEventoObj);
        if (Utils.isObjectHasValue(ideEventoObj)) {
            return (
                <div style={{ border: '1px solid #ccc', padding: '10px', margin: '5px', borderRadius:'8px' }}>
                    <h4>Informações de identificação do evento</h4>
                    {renderIndRetif(ideEventoObj.indRetif,ideEventoObj.nrRecibo)}
                    <div className="p-field" style={{paddingLeft:'8px'}}>
                        <label>Indicativo de período de apuração. </label><br />
                        <em>{ideEventoObj.indApuracao}</em>
                    </div>
                    <div className="p-field" style={{paddingLeft:'8px'}}>
                        <label>Ano/mês (formato AAAA-MM) de referência das informações: </label><br />
                        <em>{ideEventoObj.perApur}</em>
                    </div>
                    <div className="p-field" style={{paddingLeft:'8px'}}>
                        <label>Identificação do ambiente. </label><br />
                        {renderTpAmb(ideEventoObj.tpAmb)}
                    </div>
                    <div className="p-field" style={{paddingLeft:'8px'}}>
                        <label>Processo de emissão do evento. </label><br />
                        <em>{renderprocEmi(ideEventoObj.procEmi)}</em>
                    </div>
                    <div className="p-field" style={{paddingLeft:'8px'}}>
                        <label>Versão do processo de emissão do evento: </label><br />
                        <em>{ideEventoObj.verProc}</em>
                    </div>
                </div>
            );
        }

    }
    function renderIdeEmpregador(ideEmpregadorObj) {
        if (Utils.isObjectHasValue(ideEmpregadorObj)) {
            return (
                <div style={{ border: '1px solid #ccc', padding: '10px', margin: '5px', borderRadius:'8px' }}>
                    <h4>ideEmpregador</h4>
                    <div className="p-field">
                        <label>tpInsc: </label>{ideEmpregadorObj.tpInsc}
                    </div>
                    <div className="p-field">
                        <label>nrInsc: </label><strong>{formataCNPJ(ideEmpregadorObj.nrInsc)}</strong>
                    </div>
                </div>
            );
        }
    }
    function formataCPF(numCPF) {
        try {
            const cpf = require('node-cpf');
            return cpf.mask(numCPF);
        } catch (erro) {
            console.log(erro);
        }
    }
    function formataCNPJ(numCNPJ) {
        try {
            const cnpj = format(numCNPJ);
            return cnpj;
        } catch (erro) {
            console.log(erro);
        }
    }
    function renderIdeTrabalhador(ideTrabalhadorObj) {
        if (Utils.isObjectHasValue(ideTrabalhadorObj)) {
            return (
                <div style={{ border: '1px solid #ccc', padding: '10px', margin: '5px', borderRadius:'8px' }}>
                    <h4>ideTrabalhador</h4>
                    <div className="p-field">
                        <label>cpfTrab: </label>{formataCPF(ideTrabalhadorObj.cpfTrab)}
                    </div>
                </div>
            );
        }
    }
    function renderInfoComplem(infoComplemObj) {

    }
    function renderSucessaoVinc(sucessaoVincObj) {

    }

    function renderDmDev(dmDevObj) {
        if (Utils.isObjectHasValue(dmDevObj)) {
            return (
                <div key={dmDevObj.ideDmDev} style={{ borderBottom: '1px solid #ccc', padding: '10px', margin: '5px', borderRadius:'8px' }}>
                    <h4>dmDev</h4>
                    <div className="p-field">
                        <label>ideDmDev: </label>{dmDevObj.ideDmDev}
                    </div>
                    <div className="p-field">
                        <label>codCateg: </label>{dmDevObj.codCateg}
                    </div>
                    {renderInfoPerApur(dmDevObj.infoPerApur)}
                </div>
            );
        }
    }
    function renderInfoPerApur(infoPerApurObj) {
        const listaIdeEstab = infoPerApurObj.ideEstab;
        console.log(listaIdeEstab);

        if (Utils.isObjectHasValue(infoPerApurObj)) {
            return (
                <div style={{ borderBottom: '1px solid #ccc', padding: '10px', margin: '5px', borderRadius:'8px' }}>
                    <h4>infoPerApur</h4>
                    {listaIdeEstab.map((obj)=>renderIdeEstab(obj))}
                </div>
            );
        }
    }
    function renderIdeEstab(ideEstabObj) {
        const listaRemunPerApur = ideEstabObj.remunPerApur;
        console.log("cheguei aqui")
        if (Utils.isObjectHasValue(ideEstabObj)) {
            return (
                <div key={ideEstabObj.nrInsc} style={{ borderBottom: '1px solid #ccc', padding: '10px', margin: '5px', borderRadius:'8px' }}>
                    <h4>ideEstab</h4>
                    <div className="p-field">
                        <label>tpInsc: </label>{ideEstabObj.tpInsc}
                    </div>
                    <div className="p-field">
                        <label>nrInsc: </label>{ideEstabObj.nrInsc}
                    </div>
                    {listaRemunPerApur.map((obj)=>renderRemunPerApur(obj))}
                </div>
            );
        }
    }
    function renderRemunPerApur(remunPerApurObj) {
        const listaItensRemun = remunPerApurObj.itensRemun;
        if (Utils.isObjectHasValue(remunPerApurObj)) {
            return (
                <div key={remunPerApurObj.matricula} style={{ borderBottom: '1px solid #ccc', padding: '10px', margin: '5px', borderRadius:'8px' }}>
                    <h4>remunPerApur</h4>
                    <div className="p-field">
                        <label>matricula: </label>{remunPerApurObj.matricula}
                    </div>
                    {listaItensRemun.map((obj, index)=>renderItensRemun(obj, index))}
                </div>
            );
        }
    }
    function renderItensRemun(itensRemunObj, index) {
        if (Utils.isObjectHasValue(itensRemunObj)) {
            return (
                <div key={itensRemunObj.codRubr+index} style={{ borderBottom: '1px solid #ccc', padding: '10px', margin: '5px', borderRadius:'8px' }}>
                    <h4>itensRemun</h4>
                    <div className="p-field">
                        <label>codRubr: </label>{itensRemunObj.codRubr}
                    </div>
                    <div className="p-field">
                        <label>ideTabRubr: </label>{itensRemunObj.ideTabRubr}
                    </div>
                    <div className="p-field">
                        <label>qtdRubr: </label>{itensRemunObj.qtdRubr}
                    </div>
                    <div className="p-field">
                        <label>fatorRubr: </label>{itensRemunObj.fatorRubr}
                    </div>
                    <div className="p-field">
                        <label>vrRubr: </label>{itensRemunObj.vrRubr}
                    </div>
                    <div className="p-field">
                        <label>indApurIR: </label>{itensRemunObj.indApurIR}
                    </div>
                </div>
            );
        }
    }
    function renderInfoPerAnt(infoPerAntObj) {

    }
    function renderIdePeriodo(idePeriodoObj) {

    }
    function renderIdeEstabInfoPerAnt(ideEstabObj) {

    }
    function renderRemunPerAnt(remunPerAntObj) {

    }
    return (
        <div>
            {renderEvtRemunRPPS(evtRemunRPPSObj)}
        </div>
    );
}

export default EvtRemunRPPS;