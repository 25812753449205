import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Messages } from 'primereact/messages';
import { MenuBar } from '../../componentes/MenuBar';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import $ from 'jquery';
import { FiltroEventosEnviados } from './Filtro/FiltroEventosEnviados';
import { TblEventosInconsistentes } from './Tabela/TblEventosInconsistentes';
import BtnExportCsv from '../../componentes/BtnExportCsv';
import usuarioService from '../../services/usuarioService';

export class RelEventosInconsistentes extends Component {
  constructor() {
    super();
    this.state = {
      home: { icon: 'pi pi-home' },
      agendamentos: [],
      tipoEvento: "1",
      //Refazer com Controler preenchendo essas informações
      eventos: [],
      //Refazer com Controler preenchendo essas informações
      grupos: [
        { label: 'Todos', value: 0 },
        { label: 'Eventos de Tabela', value: 1 },
        { label: 'Eventos Não Periódicos', value: 2 },
        { label: 'Eventos Periódicos', value: 3 }
      ],
      menu: [],
      usuario: {},
      filtro: false,
      filtroPesquisa: { idGrupo: 0, idEvento: 0 },
      pgrbar: 'none',
      grupo: null,
      evento: '',
      mes: null,
      ano: null,
      indApuracao: false,
      resultado: [],
      isCsvVisible:false,
      headerCsv: [
        {label: 'Cod. Evento', key:'codEvento'},
        {label: 'Situação', key:'situacao'},
        {label: 'Detalhe', key:'detalhe'},
        {label: 'Inconsistência(s)', key:'erros'},
      ]
    };
    this.atualizagrupo = this.atualizagrupo.bind(this);
    this.handleEvento = this.handleEvento.bind(this);
    this.handleGrupo = this.handleGrupo.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  
  construirMenu() {
    var baseurl = window.location.protocol + "//" + window.location.host;
    console.log(this.state.usuario.login);

    $.ajax({
      url: "/EsocialAPI/usuario/menu/relatorios/" + this.state.usuario.login,
      dataType: 'json',
      error: function () {
        window.location.href = baseurl;
      },
      success: function (retorno) {
        this.setState({ menu: retorno, pgrbar: 'none' });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  componentWillMount() {
    $.ajax({
      url: "/EsocialAPI/usuario/logado/",
      dataType: 'json',
      success: function (resposta) {
        this.atualizagrupo();
        this.setState({ usuario: resposta });
        this.construirMenu();
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
    this.atualizaEventos();
  }
  alteraProgress = (valor) => {
    this.setState({ pgrbar: valor })
  }

  handleEvento(evento) {
    console.log(evento);
    var eventoTemp = Object.assign({}, this.state.evento);
    eventoTemp = evento;
    this.setState({ evento: eventoTemp });
    console.log(eventoTemp);
    console.log(this.state.evento);
  }

  handleGrupo(grupo) {

    var grupoTemp = Object.assign({}, this.state.grupo);
    grupoTemp = grupo;
    this.setState({ grupo: grupoTemp }, () => { console.log('The state has been updated.') });
    console.log(grupoTemp);
    //console.log(this.state.grupo);
    this.atualizaEventos(grupoTemp);
  }

  handleMes(mes) {
    this.setState({ mes });
  }

  handleAno(ano) {
    this.setState({ ano });
  }

  handleIndApuracao(indApuracao) {
    this.setState({ indApuracao });
  }

  handleClick() {
    console.log('entrou');
    this.setState({ pgrbar: 'block', isCsvVisible: false });
    console.log(JSON.stringify(this.montaFiltroEvtEnviados()));
    $.ajax({
      timeout: 0,
      url: '/EsocialAPI/relatorios/eventosinconsistentes/',
      type: 'post',
      dataType: 'json',
      data: JSON.stringify(this.montaFiltroEvtEnviados()),
      success: function (resposta) {
        console.log(resposta);
        this.setState({ resultado: resposta, pgrbar: 'none', isCsvVisible: true });

      }.bind(this),
      error: function (erro) {
        this.setState({ pgrbar: 'none' });
        console.log('A quantidade de eventos é muito grande');
      }.bind(this),
      contentType: 'application/json',
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  atualizaEventos(idGrupo) {
    if (idGrupo == null) {
      idGrupo = this.state.grupo;
    }
    if (idGrupo === 0) {

    }
    var urlEventos = '/EsocialAPI/evento/combo/' + idGrupo;
    console.log(urlEventos);
    $.ajax({
      url: urlEventos,
      type: 'get',
      dataType: 'json',
      success: function (resposta) {
        console.log(resposta);
        this.setState({ eventos: resposta });
      }.bind(this),
      error: function (erro) {
        console.log(erro);
      },
      contentType: 'application/json',
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  atualizagrupo() {
    var urlEventos = '/EsocialAPI/grupo/combo/';
    console.log(urlEventos);
    $.ajax({
      url: urlEventos,
      type: 'get',
      dataType: 'json',
      success: function (resposta) {
        const gruposExcecao = ['TOTALIZADOR', 'EXCLUSAO']
        const gruposFiltrados = resposta.filter(obj => !gruposExcecao.includes(obj.label))
        this.setState({ grupos: gruposFiltrados });
      }.bind(this),
      error: function (erro) {
        console.log(erro);
      },
      contentType: 'application/json',
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  montaFiltroEvtEnviados() {
    var filtroEvtEnviados = {
      idGrupo: this.state.grupo,
      idEvento: this.state.evento,
      ano: this.state.ano,
      mes: this.state.mes,
      isAnual: this.state.indApuracao
    }
    return filtroEvtEnviados;
  }
  componentDidMount() {
    this.consultaUsuario();
  }
  consultaUsuario() {
    usuarioService.consultaUsuario((usuario) => {
      this.setState({ usuario });
    });
  }

  geraNomeRelatorio(){
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const nomeRelatorio = 'relat_inconsistentes_'+today.toISOString()+'.csv';
    return nomeRelatorio;
  }
  render() {
    const items = [
      { label: 'Home', icon: 'pi pi-home', url: '/home' },
      { label: 'Relatórios', icon: 'pi pi-home', url: '/relatorio/inconsistencias' },
      { label: 'Relatório de Eventos inconsistentes', icon: 'pi-home', url: '/relatorio/inconsistencias' }
    ];

    return (
      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content">
          <div className="content align-center">
            <Messages ref={(el) => this.messages = el} />
          </div>
          <div className="pure-u-5-5" style={{ marginTop: '15px' }}>
            <Panel header="Eventos Inconsistentes">
              <BreadCrumb home="principal" model={items} />
              
              <div className="pure-u-1 content-section implementation" style={{ marginTop: '15px' }}>
                <Panel header="Filtro">
                  <br />

                  <FiltroEventosEnviados
                    filtroPesquisa={this.state.filtroPesquisa}
                    pgrbar={this.alteraProgress}
                    grupos={this.state.grupos}
                    eventos={this.state.eventos}
                    grupo={this.state.grupo}
                    evento={this.state.evento}
                    mes={this.state.mes}
                    ano={this.state.ano}
                    indApuracao={this.state.indApuracao}
                    onChangeEvento={(evento) => this.handleEvento(evento)}
                    onChangeGrupo={(grupo) => this.handleGrupo(grupo)}
                    onChangeMes={(mes) => this.handleMes(mes)}
                    onChangeAno={(ano) => this.handleAno(ano)}
                    onChangeIndApuracao={(indApuracao) => this.handleIndApuracao(indApuracao)}
                    onClick={() => this.handleClick()}
                  />
                </Panel>
                <br />
                <ProgressBar mode="indeterminate" style={{ height: '6px', display: this.state.pgrbar }} />
                <br />

                {this.state.isCsvVisible ? <BtnExportCsv data={this.state.resultado} header={this.state.headerCsv} fileName={this.geraNomeRelatorio()}/> : ''}
                
                {/* <Button label="PDF" icon="pi pi-check" className="p-button-sm" style={{marginBottom: '10px', marginRight: '10px'}} /> */}
            
                <TblEventosInconsistentes idGrupo={this.state.grupo} idEvento={this.state.evento} eventos={this.state.resultado} />
              </div>
            </Panel>
          </div>

        </div>

        <div className="footer l-box is-center">
          {/* <PermissionCheck usuario={this.state.usuario}  /> */}
        </div>
      </div>
    );
  }
}
