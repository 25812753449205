import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import usuarioService from '../../services/usuarioService';

export default class CargaRubrica extends Component {
  constructor() {
    super();
    this.state = {
      inputString: '',
      rubricas: [],
      usuario: {},
      filtros: {}
    };

    this.msgs = React.createRef();
    this.addMessages = this.addMessages.bind(this);
  }

  componentDidMount() {
    this.consultaUsuario();
  }

  addMessages = (msg) => {
    this.msgs.show([msg]);
  };

  showMessage(titulo, mensagem, tipoMensagem, sticky, life) {
    this.msgs.show({
      severity: tipoMensagem,
      summary: titulo,
      detail: mensagem,
      sticky: sticky,
      life: life
    });
  }

  handleInputTextChange = (e) => {
    this.setState({ inputString: e.target.value });
    this.handleInputTextString(e.target.value);
  };

  handleInputTextString = (string) => {
    const rubricaArray = string
      .split(',')
      .map((str) => parseInt(str.trim(), 10));
    this.setState({ rubricas: rubricaArray });
  };

  handleSubmit = () => {
    const filtros = {
      rubricas: this.state.rubricas
    };

    fetch('/EsocialAPI/api/importacao/rubrica/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + localStorage.getItem('auth-token')
      },
      body: JSON.stringify(filtros)
    })
      .then((response) => response.text())
      .then((data) => {
        console.log('data sent');
        const importacaoEventoDTO = JSON.parse(data);
        const mensagemDTO = importacaoEventoDTO.mensagemDTO;
        this.showMessage(
          '',
          mensagemDTO.detail,
          mensagemDTO.severity,
          mensagemDTO.sticky,
          7000
        );
        this.setState({ inputString: '' });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  consultaUsuario() {
    usuarioService.consultaUsuario((usuario) => {
      this.setState({ usuario });
    });
  }

  render() {
    const style = {
      label: {
        fontSize: 16,
        fontWeight: 'bold',
        display: 'block'
      },
      inputText: {
        width: '80%'
      },
      inputContainer: {
        paddingTop: '10px',
        paddingBottom: '10px',
        display: 'flex',
        alignItems: 'flex-end' // Align items to the bottom
      }
    };

    return (
      <div>
        <div className="content">
          <div className="content align-center">
            <div>
              <div>
                    <h3>Importação de rubricas invertidas para a fila da Mensageria</h3>
                    <p></p>
                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                        Esta funcionalidade possibilita a geração manual de rubricas com valores inversos aos cadastrados no MentoRH, a fim de se informar ao eSocial uma natureza diferente em situaçãoes especificas.
                    </p>
                    <p>
                        Para isto, digite o código da rubrica abaixo:
                    </p>
                <div style={style.inputContainer} className="pure-g">
                  <div className="pure-u-1-3">
                    <label style={style.label}>Código(s) Rubrica(s):</label>
                    <InputText
                      value={this.state.inputString}
                      onChange={this.handleInputTextChange}
                      style={style.inputText}
                    />
                  </div>
                  {this.state.inputString && (
                    <Button
                      label="Importar Rubricas"
                      icon="pi pi-play"
                      onClick={() => this.handleSubmit()}
                    />
                  )}
                </div>
              </div>
              <Messages ref={(el) => (this.msgs = el)} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
