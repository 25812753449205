import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Button} from 'primereact/button';
import { InputText } from 'primereact/inputtext';


export class FiltroEventoTrabalhador extends Component{
  
  render() {
    
    return(
      <div className="pure-g">

        <div className="pure-u-1-4">
            <InputText 
              optionLabel="name" 
              value={this.props.matricula} 
              onChange={(e) => {this.props.onChangeMatricula(e.target.value)}} 
              placeholder="Matricula do Trabalhador"/>
            
        </div>

        <div className="pure-u-1-4">
            <InputText 
              optionLabel="name" 
              value={this.props.nome} 
              onChange={(e) => {this.props.onChangeNome(e.target.value)}} 
              placeholder="Nome do Trabalhador"/>
            
        </div>

        <div className="pure-u-1-4">
            <Button label="Consultar" icon="pi pi-search" iconPos="left" onClick={()=>this.props.onClick()} />
        </div>
      </div>
    );
  }
}