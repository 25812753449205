import React, { Component } from 'react';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Messages } from 'primereact/messages';
import { MenuBar } from '../componentes/MenuBar';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FileUpload } from 'primereact/fileupload';
import { PermissionCheck } from '../componentes/PermissionCheck';
import { DialogErros } from '../sessoes/Qualificacao/DialogErros';
import { InputMask } from 'primereact/inputmask';
import { ProgressBar } from 'primereact/progressbar';
import { Checkbox } from 'primereact/checkbox';
import BtnExportCsv from '../componentes/BtnExportCsv';
import $ from 'jquery';


import { Toast } from 'primereact/toast';
import { TabPanel, TabView } from 'primereact/tabview';
import usuarioService from '../services/usuarioService';

export class Qualificacao extends Component {
  constructor() {
    super();
    this.state = { 
      home: { icon: 'pi pi-home' }
      , agendamentos: []
      , tipoEvento: "1"
      , cqc: []
      , regToExport: []
      , regToExportAux: []
      , panelCollapsed: true
      , usuario: {}
      , srvCPF: ''
      , progressBarAtivo: false
      , selectedFilters: []
      , isCollapsed: true
      , headerCsv: [
        {label: 'Arquivo', key:'nomeArquivo'},
        {label: 'Nome', key:'nome'},
        {label: 'CPF', key:'cpf'},
        {label: 'NIS', key:'nis'},
        {label: 'Data Importação', key:'dataImportacao'},
        {label: 'NIS inválido', key:'codNisInv'},
        {label: 'CPF inválido', key:'codCpfInv'},
        {label: 'NOME inválido', key:'codNomeInv'},
        {label: 'DN inválida', key:'codDnInv'},
        {label: 'NIS inconsistente', key:'codCnisNis'},
        {label: 'Data de Nascimento informada diverge da existente no CNIS', key:'codCnisDn'},
        {label: 'NIS com óbito no CNIS', key:'codCnisObito'},
        {label: 'CPF informado diverge do existente no CNIS', key:'codCnisCpf'},
        {label: 'CPF não preenchido no CNIS', key:'codCnisCpfNaoInf'},
        {label: 'CPF informado não consta no Cadastro CPF', key:'codCpfNaoConsta'},
        {label: 'CPF informado NULO no Cadastro CPF', key:'codCpfNulo'},
        {label: 'CPF informado CANCELADO no Cadastro CPF', key:'codCpfCancelado'},
        {label: 'CPF informado SUSPENSO no Cadastro CPF', key:'codCpfSuspenso'},
        {label: 'Data de Nascimento informada diverge da existente no Cadastro CPF', key:'codCpfDn'},
        {label: 'NOME informado diverge do existente no Cadastro CPF', key:'codCpfNome'},
        {label: 'Procurar Conveniadas da RFB( 1)', key:'codOrientacaoCpf'},
        {label: 'Atualizar NIS no INSS (1)', key:'codOrientacaoNis'},
        {label: 'Verifique os dados digitados. Se estiverem corretos, antes de realizar a atualização cadastral do PIS ou PASEP, é necessário verificar o vínculo empregatício atual: 1- se vinculado à empresa privada, a atualização cadastral deve ser solicitada na CAIXA; 2- se vinculado a órgão público, a atualização cadastral deve ser solicitada no Banco do Brasil. Obs: a atualização cadastral poderá ser realizada pelo interessado ou pela empresa/órgão público.(2)', key:'codOrientacaoNis2'},
      ]
    };
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.onBeforeSend = this.onBeforeSend.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onError = this.onError.bind(this);
  }
  brandTemplate(rowData, column) {
    return <DialogErros rowData={rowData} />
  }
  geraNomeRelatorio(){
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const nomeRelatorio = 'relat_qualificacao_cadastral_'+today.toISOString()+'.csv';
    return nomeRelatorio;
  }
  consultaUsuario() {
    usuarioService.consultaUsuario((usuario) => {
      this.setState({ usuario });
    });
  }
  dataImportacao(rowData, column) {

    return <label>{new Date(rowData.dataImportacao).toLocaleString()}</label>;
  }
  onBeforeSend(event) {
    this.setState({progressBarAtivo:true});
    event.xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem('auth-token'));
  }
  listaQualificacao(event) {
    $.ajax({
      url: "/EsocialAPI/qualificacao/lista/",
      dataType: 'json',
      success: function (rsp) {
        // Ajusta informação para exportar em CSV
        const cqcArray = rsp;
        const modifiedCqcArray = cqcArray.map(item => {
            if (item.codOrientacaoNis === "0") {
                return {
                    ...item,
                    codOrientacaoNis2: "0",
                };
            }
            if (item.codOrientacaoNis === "1") {
                return {
                    ...item,
                    codOrientacaoNis2: "0",
                };
            }
            else if (item.codOrientacaoNis === "2") {
              return {
                  ...item,
                  codOrientacaoNis: "0",
                  codOrientacaoNis2: "2",
              };
            }
            return item;
        });
        this.setState({ regToExport: modifiedCqcArray });
        this.setState({ regToExportAux: modifiedCqcArray });

        this.setState({ cqc: rsp });
        if (this.state.cqc.length > 0) {
          this.setState({ panelCollapsed: true });
        } else {
          this.setState({ panelCollapsed: false });
        }
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });

  }
  onUpload(event) {
    this.setState({progressBarAtivo:false});
    this.listaQualificacao();
    this.toast.show(JSON.parse(event.xhr.response));
  }
  onError(event) {
    this.setState({progressBarAtivo:false});
    console.log(event)
    this.toast.show(JSON.parse(event.xhr.response));
  }
  componentDidMount() {
    this.consultaUsuario();
    this.listaQualificacao();

    // Initialize all checkboxes as checked
    const allKeys = this.state.headerCsv.map(item => item.key);
    this.setState({ selectedFilters: allKeys });
  }
  renderCPF(rowData) {
    let numCPF = rowData.cpf;
    try {
      const cpf = require('node-cpf');
      return cpf.mask(numCPF);
    } catch (erro) {
      console.log(erro);
    }

  }
  filtraCQC(dadosCqc, tipo){
    return dadosCqc.filter((obj)=>{
      return obj.tipoArquivoCqc == tipo
    })
  }
  renderProgressBar(ativo){
    if(ativo){
      return <ProgressBar mode="indeterminate" />
    }
  }
  handleCheckboxChange(event, key) {
    let filteredRegToExport = null;

    if (event.target.checked) {
      this.setState(prevState => ({
        selectedFilters: [...prevState.selectedFilters, key]
      }));

      const filtrosSelecionados = this.state.selectedFilters
      filtrosSelecionados.push(key)
      filteredRegToExport = this.state.regToExportAux.filter(item => {
        for (const propKey of filtrosSelecionados) {
          if (propKey === 'id' || propKey === 'tipoArquivoCqc' || propKey === 'cpf' 
            || propKey === 'dataImportacao' || propKey === 'dn' || propKey === 'nis'
            || propKey === 'nome' || propKey === 'nomeArquivo' || propKey === 'regDesformatado'
            || propKey === 'separador')  {
            continue;
          }
          if (item[key] !== '0' || (propKey !== key && item[propKey] !== '0')) {
            return item
          }
        }
      });
      filtrosSelecionados.pop(key)
    } else {
      this.setState(prevState => ({
        selectedFilters: prevState.selectedFilters.filter(item => item !== key)
      }));

      const filtrosSelecionados = this.state.selectedFilters
      filteredRegToExport = this.state.regToExportAux.filter(item => {
        for (const propKey of filtrosSelecionados) {
          if (propKey === 'id' || propKey === 'tipoArquivoCqc' || propKey === 'cpf' 
          || propKey === 'dataImportacao' || propKey === 'dn' || propKey === 'nis'
          || propKey === 'nome' || propKey === 'nomeArquivo' || propKey === 'regDesformatado'
          || propKey === 'separador' || propKey === key) {
            continue;
          }
          if (propKey !== key && item[propKey] !== '0') {
            return item
          }
        }
      });
    }

    this.setState({ regToExport: filteredRegToExport });
  }
  handleIsCollapsed(isCollapsed) {
    this.setState({ isCollapsed });
  }
  render() {
    const filterCPf = <InputMask mask="999.999.999-99" value={this.state.srvCPF} onChange={(e) => this.setState({ srvCPF: e.value })}></InputMask>;
    const items = [
      { label: 'Home', icon: 'pi pi-home', url: '/home' },
      { label: 'Qualificação Cadastral', icon: 'pi pi-home', url: '/qualificacao' }
    ];
    return (
      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content">
          <div className="pure-u-5-5">
            <Panel header="Qualificação Cadastral">
              <BreadCrumb home="principal" model={items} />
              <Messages ref={(el) => this.messages = el} />
              <h5 style={{ margin: '10px', fontStyle: 'italic' }}>Qualificação cadastral das informações a serem enviadas.</h5>
              <p style={{ margin: '5px', fontStyle: 'italic' }}>A Qualificação Cadastral tem o intuito de realizar uma pré-validação dos CPF e NIS em lote visando identificar possíveis divergências entre os cadastros internos das empresas, o CNIS e o Cadastro CPF, a fim de não comprometer os processos do projeto eSocial no momento do cadastramento inicial.</p>
              <TabView>
                <TabPanel header="Arquivo PROCESSADO">
                  <div className="pure-u-5-5" style={{ marginTop: '15px' }}>
                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                      O arquivo PROCESSADO apontará as divergências entre os cadastros internos das empresas, o Cadastro
                      CPF e o Cadastro Nacional de Informações Sociais – CNIS; e orientará o procedimento a ser adotado
                      para ajuste das divergências encontradas. Faça o upload no botão abaixo.
                    </p>
                    {this.renderProgressBar(this.state.progressBarAtivo)}
                    <FileUpload name="file" url="/EsocialAPI/qualificacao/upload/" onUpload={(e) => this.onUpload(e)} uploadLabel="Enviar"
                        onError={(e) => this.onError(e)} onBeforeSend={this.onBeforeSend} multiple={false} accept="text/plain" maxFileSize={5000000} cancelLabel="Cancelar" chooseLabel="Arquivo PROCESSADO" />
                    <br />
                    <Panel header="Dados Cadastrais">
                      {
                        this.state.regToExportAux.length > 0 ? 
                        <>
                        <Panel header="Filtros exportação CSV" toggleable={true} collapsed={this.state.isCollapsed} onToggle={(e) => this.handleIsCollapsed(e.value)}>
                            <div className="pure-g">
                              <div className="pure-u-1-4">
                                <span>Total registros: {this.state.regToExport.length}</span>
                              </div>
                            </div>
                            <div className="pure-g">
                              {this.state.headerCsv.map(item => {
                                if(item.key !== 'nomeArquivo' && item.key !== 'nome' && 
                                item.key !== 'cpf' && 
                                item.key !== 'nis' && 
                                item.key !== 'dataImportacao') {
                                  return(
                                  <div className="pure-u-1-4" key={item.key}>
                                    <Checkbox
                                      inputId={item.key}
                                      value={item.key}
                                      onChange={(e) => this.handleCheckboxChange(e, item.key)}
                                      checked={this.state.selectedFilters.includes(item.key)}
                                    />
                                    <label htmlFor={item.key} className="p-checkbox-label">{item.key}</label>
                                  </div>
                                )
                              }})}
                            </div>
                          </Panel>
                          <BtnExportCsv 
                            data={this.filtraCQC(this.state.regToExport, 'PROCESSADO')} 
                            header={this.state.headerCsv.filter(item =>
                              this.state.selectedFilters.includes(item.key)
                            )}
                            fileName={this.geraNomeRelatorio()}/> 
                        </>
                        : 
                        ''
                      }
                      <DataTable virtualRowHeight={40} value={this.filtraCQC(this.state.cqc, 'PROCESSADO')} paginator={true} rows={20} emptyMessage="Nenhum arquivo de retorno da qualificação registrado" >
                        <Column field="nomeArquivo" header="Arquivo" />
                        <Column field="nome" filter header="Nome" />
                        <Column body={this.renderCPF} filter header="CPF" filterField="cpf" />
                        <Column field="nis" filter header="NIS" />
                        <Column field="dataImportacao" header="Data Importacao" body={this.dataImportacao} />
                        <Column field="color" header="Situação" body={this.brandTemplate} style={{ textAlign: 'center' }} />
                      </DataTable>
                    </Panel>
                    <Toast ref={(el) => { this.toast = el; }}></Toast >
                  </div>
                </TabPanel>
                <TabPanel header="Arquivo REJEITADO">
                  <div className="pure-u-5-5" style={{ marginTop: '15px' }}>
                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                    O arquivo REJEITADO apontará o motivo da inconsistência na formatação do arquivo e/ou campos de cada registro. Faça o upload no botão abaixo.
                    </p>
                    {this.renderProgressBar(this.state.progressBarAtivo)}
                    <FileUpload name="file" url="/EsocialAPI/qualificacao/upload/" onUpload={(e) => this.onUpload(e)} uploadLabel="Enviar" style={{marginBottom: '10px'}}
                        onError={(e) => this.onError(e)} onBeforeSend={this.onBeforeSend} multiple={false} accept="text/plain" maxFileSize={5000000} cancelLabel="Cancelar" chooseLabel="Arquivo REJEITADO" />
                    <Panel header="Dados Cadastrais">
                      <DataTable virtualRowHeight={40} value={this.filtraCQC(this.state.cqc, 'REJEITADO')} paginator={true} rows={20} emptyMessage="Nenhum arquivo de retorno da qualificação registrado" >
                        <Column field="nomeArquivo" header="Arquivo" />
                        <Column field="nome" filter header="Nome" />
                        <Column body={this.renderCPF} filter header="CPF" filterField="cpf" />
                        <Column field="nis" filter header="NIS" />
                        <Column field="dataImportacao" header="Data Importacao" body={this.dataImportacao} />
                        <Column field="color" header="Situação" body={this.brandTemplate} style={{ textAlign: 'center' }} />
                      </DataTable>
                    </Panel>
                    <Toast ref={(el) => { this.toast = el; }}></Toast >
                  </div>
                </TabPanel>
              </TabView>

            </Panel>
          </div>

        </div>


        <div className="footer l-box is-center">
          <PermissionCheck usuario={this.state.usuario} />
        </div>

      </div>
    );
  }
}