import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { SessoesGrupo } from '../Grupo/SessoesGrupo';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import apiAjax from '../../services/apiAjax';
import { Messages } from 'primereact/messages';
export class GrupoTabela extends Component {
  constructor() {
    super();
    this.state = { sessoes: [], visible: false, grupos: [], novoGrupo: '', roleGrupo: {} };
    this.listaGrupos = this.listaGrupos.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.cities = [
      { label: 'Administrador', value: 'ROLE_ADMIN' },
      { label: 'Usuário', value: 'ROLE_USER' },
      { label: 'Gerente', value: 'ROLE_MANAGER' },
    ];
  }
  componentWillMount() {
    this.listaGrupos();
  }
  listaGrupos() {
    $.ajax({
      url: "/EsocialAPI/api/grupo/listar",
      dataType: 'json',
      success: function (resposta) {
        console.log(resposta);
        this.setState({ grupos: resposta });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  actionTemplate(rowData) {
    var sessoesSlt = [];
    var selecionados = rowData.sessoes;
    if (selecionados !== null) {
    /*  selecionados.map((slt) =>
        sessoesSlt.push(slt.idSessao)
      );*/
    }
    return (
      <SessoesGrupo sessoes={this.state.sessoes} sessoesSlt={sessoesSlt} idGrupo={rowData.idGrupo} />
    );
  }

  async handleSaveClick(novoGrupo, roleGrupo) {
    console.log(novoGrupo);
    console.log(roleGrupo);
    const data = { descricao: novoGrupo, role: roleGrupo }
    const postCall = apiAjax.postRequestNew;

    try {
      const response = await postCall('/EsocialAPI/api/grupo/adicionar', data);
      console.log(response);
      this.messages.show(response);
    } catch (e) {
      console.log(e);
    }
  }
  render() {
    const style = {
      label: {
        fontSize: 16,
        fontWeight: 'bold',
        display: 'block',
      },
      dropDown: {
        width: '80%'
      },
      inputText: {
        width: '80%'
      },
      inputContainer: {
        paddingTop: '10px',
        paddingBottom: '10px',
        marginBotto: '50px'
      }
    }
    const footer = (
      <div>
        <Button label="Salvar" icon="pi pi-check" onClick={() => this.handleSaveClick(this.state.novoGrupo, this.state.roleGrupo)} />
        <Button label="Sair" icon="pi pi-times" onClick={() => this.setState({ visible: false })} />
      </div>
    )
    return (
      <div>
        <Panel header="Grupos">
          <div className="content-section introduction">
            <div className="feature-intro">
              <h2>Grupos </h2>
              <em>Grupos/Permissões de acesso ao sistemas :</em>
            </div>
            <div>
              <Button label="Novo" icon="pi pi-user-plus" iconPos="left" style={{ margin: '3px' }} onClick={(e) => this.setState({ visible: true })} />
            </div>
            <Dialog
              header="Novo Grupo"
              visible={this.state.visible}
              style={{ width: '50vw' }}
              modal={true}
              onHide={(e) => this.setState({ visible: false })}
              footer={footer}
            >
              <Messages ref={(el) => this.messages = el}></Messages>
              <div style={{ height: '200px' }}>
                <div className="pure-u-2-3" style={style.inputContainer}>
                  <label style={style.label}>Grupo</label>
                  <InputText value={this.state.novoGrupo} style={style.inputText} onChange={(e) => this.setState({ novoGrupo: e.target.value })} placeholder="Digite o nome do grupo" />

                </div>
                <div className="pure-u-1-3" style={style.inputContainer}>
                  <label style={style.label}>Role</label>
                  <Dropdown value={this.state.roleGrupo} options={this.cities} onChange={(e) => this.setState({ roleGrupo: e.value })} placeholder="Selecione uma role" />

                </div>
              </div>


            </Dialog>
          </div>
          <div className="content-section implementation">
            <DataTable value={this.state.grupos} paginator={true} rows={10}>
              <Column field="idGrupo" header="Id" sortable={true} style={{ width: '7%', textAlign: 'center' }} />
              <Column field="descricao" header="Nome" />
              <Column field="ativo" header="Ação" style={{ width: '8%' }} body={this.actionTemplate} />
            </DataTable>
          </div>
        </Panel>
      </div>
    );
  }
}
